import React from 'react';
import TTNormalProRegular from '../../styles/fonts/TTNormsPro-Regular.ttf';
import TTNormalProMedium from '../../styles/fonts/TTNormsPro-Medium.ttf';
import TTNormalProBold from '../../styles/fonts/TTNormsPro-Bold.ttf';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { fontFace } from '../../helpers/styles/fontFace';
import { fontName } from './index';

const fontStyles = `
  ${fontFace(fontName, TTNormalProRegular, FontWeight.REGULAR)}
  ${fontFace(fontName, TTNormalProMedium, FontWeight.MEDIUM)}
  ${fontFace(fontName, TTNormalProBold, FontWeight.BOLD)}
`;

export const GlobalFontStyle = () => (
    <style>{fontStyles}</style>
);
