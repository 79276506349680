import { request } from '../../helpers/api/request';
import { OrderCardContractParams } from '../../types/Endpoints/OrderCardContract';
import { UpdateOrder } from '../../types/Endpoints/CreateOrder';

export const updateOrders = async (params: OrderCardContractParams, orderData: UpdateOrder) => {
    const { data } = await request({
        url: `orders/${params.id}/update`,
        method: 'PATCH',
        data: orderData,
    });
    return data;
};
