import { createReducer } from '@reduxjs/toolkit';
import { addDrawerProducts, removeDrawerProducts } from '../../actions/drawerProducts';
import { DrawerProductsModal } from '../../types/Models/DrawerProductsModal';

interface IDrawerProducts {
    drawerProducts?: DrawerProductsModal | undefined;
}

export const drawerProducts = createReducer<IDrawerProducts>({}, builder => {
    builder.addCase(addDrawerProducts, (state, { payload }) => ({
        ...state,
        drawerProducts: payload,
    }));
    builder.addCase(removeDrawerProducts, (state, { payload }) => ({ }));
});
