import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useEffect } from 'react';
import { FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { units } from '../../../helpers/styles/units';
import { InformationWrapperBlock } from '../../../components/InformationWrapperBlock';
import { Form } from '../../../components/Form';
import { getFieldsIssuingOrders } from './fieldsIssuingOrders';
import { getFieldsSelectShop } from './getFieldsSelectShop';
import { getFieldsSelectTime } from './getFieldsSelectTime';
import { getAllShop } from '../../../actions/shop';
import { useTypeSelector } from '../../../store';
import { selectAllShop } from '../../../selectors/allShops';
import { getTimespans } from '../../../actions/timespans';
import { selectTimespans } from '../../../selectors/timespans';
import { colors } from '../../../constants/Colors';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${units(24)};
  margin-bottom: ${units(130)};
`;

interface Formik {
    DatePicker: string;
    selectOptionShop: string[];
    selectOptionTime: string[];
}

type FormValues = Partial<Formik>;

export const IssuingOrders = ({ formIssuingOrders }: { formIssuingOrders: FormikProps<FormValues> }) => {
    const { t } = useTranslation('client');
    const dispatch = useDispatch();
    const allShops = useTypeSelector(selectAllShop);
    const timespans = useTypeSelector(selectTimespans);
    const issuingOrdersValues = formIssuingOrders.values;

    useEffect(() => {
        dispatch(getAllShop());
    }, []);

    useEffect(() => {
        if (issuingOrdersValues.selectOptionShop
            && +issuingOrdersValues.selectOptionShop[3] > 0 && issuingOrdersValues.DatePicker) {
            dispatch(getTimespans({
                id: issuingOrdersValues.selectOptionShop ? +issuingOrdersValues.selectOptionShop[3] : 0,
                date: issuingOrdersValues.DatePicker,
            }));
        }
    }, [issuingOrdersValues]);

    return (
        <StyledWrapper>
            <InformationWrapperBlock color={colors.yellow} maxWidth={215} headerTitle="Выдача заказа">
                <Form<FormValues>
                    fields={
                        getFieldsIssuingOrders(
                            t,
                            getFieldsSelectShop(allShops || []),
                            getFieldsSelectTime(timespans || []),
                        )
                    }
                    form={formIssuingOrders}
                />
            </InformationWrapperBlock>
        </StyledWrapper>
    );
};
