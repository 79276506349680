import styled from 'styled-components';
import * as React from 'react';
import {
    ChangeEvent,
    ReactNode,
    useCallback,
    useRef,
    useState,
    FormEvent,
} from 'react';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { applyColor } from './applyColor';
import { InputState } from '../../constants/InputState';
import { useOutSideClick } from '../../hooks/useOutSideClick';
import { HtmlTooltip } from '../Tooltip/HtmlTooltip';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { CHARACTERS_IN_INPUT_PHONE } from '../../helpers/validation';

export interface InputProps {
    id?: string,
    name?: string,
    placeholder?: string,
    className?: string,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: FormEvent<HTMLInputElement>) => void;
    onBlur?: (e: string | ChangeEvent) => void;
    inputRestriction?: RegExp;
    onOpen?: () => void;
    value?: string | number
    typeInput?: string
    error?: string,
    postfix?: ReactNode;
    prefix?: ReactNode;
    onPostfixClick?:React.MouseEventHandler<HTMLDivElement>;
    maxLength?: number;
    min?: number;
    max?: number;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  
  :not(:last-child) {
    margin-bottom: ${units(4)};
  }

  @media screen and (max-width: ${BreakPoints.MD}) {
    flex-wrap: wrap;
  }
`;

const StyledFieldContainer = styled.label`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${BreakPoints.MD}) {
   margin-top: ${units(3)};
  }
`;

const StyledPrefix = styled.div<Pick<InputProps, 'error'> & { inputState: InputState | null }>`
  position: absolute;
  top: 47%;
  left: ${units(4)};
  transform: translate(0, -50%);
  z-index: 6;
  
  display: flex;

  ${applyColor};
`;

const StyledPostfix = styled.div<Pick<InputProps, 'error'> & { inputState: InputState | null }>`
  position: absolute;
  top: 50%;
  right: ${units(3)};
  transform: translate(0, -50%);
  z-index: 6;
  
  display: flex;

  ${applyColor};
`;

const StyledInput = styled.input<{error?: string, withPrefix?: ReactNode}>`
  padding: ${units(3)} ${units(6)};
  
  ${({ withPrefix }) => (withPrefix ? `padding-left: ${units(14)}` : '')};

  background-color: ${colors.white};
  border: 1px solid ${({ error }) => (error ? colors.red : colors.gray3)};
  border-radius: ${units(4)};
  outline: 0;
  color: ${colors.dark};
  transition: border-color 0.2s;
  
  z-index: 2;

  ${typography(5)};
  
  ::placeholder {
    color: ${colors.gray};
    
    font-family: 'TTNormsPro', sans-serif;
  }

  :placeholder-shown ~ .form__label {
    cursor: text;
    top: 20px;
    left: 10px;
  }

  :focus ~ .form__label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;

    transition: 0.2s;
    color: ${colors.gray};
    font-weight: ${FontWeight.BOLD};

    ${typography(5)}
  }

  :focus {
    border-color: ${colors.gray};
  }

  :required, :invalid {
    box-shadow: none;
  }
`;

const StyledErrorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledErrorText = styled.p`
  margin-left: ${units(4)};
  
  font-weight: ${FontWeight.REGULAR};
  
  ${applyColor};
  ${typography(5)}
`;

export const Input = ({
    id,
    name,
    placeholder,
    className,
    onChange: onChangeInput,
    onBlur,
    onOpen,
    onClick,
    value = '',
    typeInput = 'text',
    error,
    prefix,
    postfix,
    onPostfixClick,
    inputRestriction,
    ...props
}: InputProps) => {
    const [inputState, setInputState] = useState<InputState | null>(null);
    const hasValue = value ? InputState.IDLE : null;

    const ref = useRef<HTMLInputElement>(null);
    const refRoot = useRef<HTMLDivElement>(null);

    const handleBlur = useCallback((event: ChangeEvent) => {
        if (value) {
            setInputState(InputState.IDLE);

            if (onBlur) {
                onBlur(event);
            }
        }
    }, [onBlur, value]);

    const handleFocus = useCallback(() => {
        ref.current?.focus();

        setInputState(InputState.ACTIVE);
    }, []);

    const handleClick = useCallback((event: never) => {
        if (onOpen) {
            onOpen();
        }

        if (onClick) {
            onClick(event);
        }

        handleFocus();
    }, [handleFocus, onClick, onOpen]);

    const handleOutsideClick = useCallback((event: Event) => {
        if (!refRoot.current?.contains(event.target as Node) || value) {
            setInputState(null);
        }
    }, [value]);

    useOutSideClick(refRoot, handleOutsideClick);

    return (
        <HtmlTooltip
            open={!!error}
            title={(
                <StyledErrorWrapper>
                    <Icon
                        type={TypesIcon.ERROR_CIRCLE}
                        width="18"
                        height="18"
                        fill={colors.white}
                        color={colors.red}
                    />
                    <StyledErrorText error={error}>
                        {error}
                    </StyledErrorText>
                </StyledErrorWrapper>
            )}
        >
            <div>
                <StyledWrapper
                    ref={refRoot}
                    onClick={handleClick}
                >
                    <StyledFieldContainer>
                        {prefix && (
                            <StyledPrefix inputState={hasValue && inputState} error={error}>
                                {prefix}
                            </StyledPrefix>
                        )}
                        <StyledInput
                            id={id}
                            name={name}
                            placeholder={placeholder}
                            className={className}
                            onChange={event => {
                                if (inputRestriction) {
                                    if (event.target.value === '' || inputRestriction.test(event.target.value)) {
                                        return onChangeInput ? onChangeInput(event) : '';
                                    }
                                } else return onChangeInput ? onChangeInput(event) : '';
                            }}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            value={value}
                            type={typeInput}
                            error={error}
                            withPrefix={prefix}
                            {...props}
                        />
                        {postfix && (
                            <StyledPostfix inputState={hasValue && inputState} error={error} onClick={onPostfixClick}>
                                {postfix}
                            </StyledPostfix>
                        )}
                    </StyledFieldContainer>
                </StyledWrapper>
            </div>
        </HtmlTooltip>
    );
};
