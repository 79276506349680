import { TypesIcon } from '../../types/TypesIcon';
import { Icon } from '../Icon';

export const Logo = ({ className }: {className?: string}) => (
    <Icon
        className={className}
        type={TypesIcon.LOGO_TEXT}
        width="140px"
        height="14px"
        viewBox="0 0 140 14"
    />
);
