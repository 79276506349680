import { ProductModel } from '../../types/Models/ProductTableModel';

export const productPriceToString = (row: ProductModel) => {
    if (!row.price) {
        return '-';
    }
    // eslint-disable-next-line
    return typeof row.price === 'number'
        ? `${row.price} руб.`
        : row.price.min_price === row.price.max_price
            ? `${row.price.max_price} руб.`
            : `${row.price.min_price} - ${row.price.max_price} руб.`;
};
