import * as React from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled as MaterialStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import { colors } from '../../../constants/Colors';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { TableWrapper } from '../../../components/TableWrapper';
import { getTableHead } from './getTableHead';
import { useTypeSelector } from '../../../store';
import { selectProduct } from '../../../selectors/product';
import { getAdditionalProduct, getProduct } from '../../../actions/products';
import { selectDrawerProduct } from '../../../selectors/drawerProducts';
import { config } from '../../../config';
import { units } from '../../../helpers/styles/units';
import { AvailableCircle } from '../../../components/AvailableCircle';
import PlaceholderForImage from '../../../assets/placeholderForImage.png';
import { ProductContractParams } from '../../../types/Endpoints/ProductContract';
import { productPriceToString } from '../../../helpers/generators/productPriceGenerator';
import { selectUsersByPhone } from '../../../selectors/currentUser';
import { selectAllShop } from '../../../selectors/allShops';
import { getAllShop } from '../../../actions/shop';
import { IShopList } from '../../../types/Models/ShopListModel';

interface Formik {
    selectPrice: string[];
    selectAvailability: string[];
}

type FormValues = Partial<Formik>;

interface ITableCatalog{
    searchOrder: string,
    formMenu: FormikProps<FormValues>;
}

const StyledTableCell = MaterialStyled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        borderBottom: 0,
        fontFamily: '\'TTNormsPro\', sans-serif',
        fontSize: '14px',
        lineHeight: '20px',
        color: colors.dark,
        letterSpacing: '0.4px',
        '&::first-of-type': {
            position: 'relative',
            left: '16px',
        },
    },
}));

const WrapperBottomTable = styled.div`
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    & > svg {
      position: relative;
      bottom: -5px;
    }
`;

const StyledTableNotFound = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

const StyledImageGoods = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 100%;
  max-width: ${units(28)};
  margin-right: ${units(6)};
  
  img{
    height: 100%;
    max-height: ${units(28)};
    cursor: pointer;
  }
`;

const StyledNameGoods = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2; // количество строк
  -webkit-box-orient: vertical;
  overflow: hidden;
  border-bottom: 2px solid transparent;

  &:hover{
    color: ${colors.blue};
    border-bottom: 2px solid ${colors.blue}
  }
  
  &:active{
    color: ${colors.blue};
    border-bottom: 2px solid ${colors.blue}
  }
`;

export const TableCatalog = ({ searchOrder, formMenu } : ITableCatalog) => {
    const { t } = useTranslation('table');
    const history = useHistory();
    const products = useTypeSelector(selectProduct);
    const usersByPhone = useTypeSelector(selectUsersByPhone);
    const allShop = useTypeSelector(selectAllShop);
    const dispatch = useDispatch();
    const [limitTable, setLimitTable] = useState<number>(1);
    const selectPrice = formMenu?.values.selectPrice ? formMenu?.values.selectPrice[3] : '';
    const [sortByName, setSortByName] = useState<string>('');
    const selectAvailability = formMenu?.values.selectAvailability ? formMenu?.values.selectAvailability[3] : '';
    const selectFilterDrawer = useTypeSelector(selectDrawerProduct);
    const [cavistShop, setCavistShop] = useState<IShopList | undefined>(undefined);

    const onClickSortByDate = () => {
        if (sortByName === '') setSortByName('asc');
        if (sortByName === 'asc') setSortByName('desc');
        if (sortByName === 'desc') setSortByName('');
    };
    const formCategoryIds = () => {
        if (Object.keys(selectFilterDrawer?.category_id || {}).length !== 0) {
            const sub = Object.keys(selectFilterDrawer?.category_id || {});
            return [...sub];
        }
        return [selectFilterDrawer?.category?.slice(3)[0]];
    };

    const getPayload = (): Partial<ProductContractParams> => {
        const filters = {
            category_id: formCategoryIds() || undefined,
            price_range: (selectFilterDrawer?.price_range?.queryString !== '0-0') ? selectFilterDrawer?.price_range?.queryString : undefined,
            country: Object.keys(selectFilterDrawer?.country || {}) || undefined,
            volume: Object.keys(selectFilterDrawer?.volume || {}) || undefined,
            alcohol_content_range: (selectFilterDrawer?.alcohol_content_range?.queryString !== '0-0') ? selectFilterDrawer?.alcohol_content_range?.queryString : undefined,
            gift_box: selectFilterDrawer?.gift_box?.slice(3)[0] || undefined,
            wine_sugar_type: Object.keys(selectFilterDrawer?.wine_sugar_type || {}) || undefined,
            whiskey_type: Object.keys(selectFilterDrawer?.whiskey_type || {}) || undefined,
            champagne_type: Object.keys(selectFilterDrawer?.champagne_type || {}) || undefined,
            cognac_type: Object.keys(selectFilterDrawer?.cognac_type || {}) || undefined,
            shop_id: cavistShop?.id || undefined,
        };

        return filters as Partial<ProductContractParams>;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchOrder && usersByPhone) {
                setLimitTable(1);
                dispatch(getProduct({
                    page: 1,
                    limit: 15,
                    name_sort: sortByName || undefined,
                    search_string: searchOrder || undefined,
                    price_sort: selectPrice || undefined,
                    available: selectAvailability || undefined,
                    ...getPayload(),
                }));
            }
        }, 1000);

        if (!searchOrder && usersByPhone) {
            setLimitTable(1);
            dispatch(getProduct({
                page: 1,
                limit: 15,
                name_sort: sortByName || undefined,
                search_string: searchOrder || undefined,
                price_sort: selectPrice || undefined,
                available: selectAvailability || undefined,
                ...getPayload(),
            }));
        }

        return () => clearTimeout(timer);
    }, [searchOrder, formMenu, selectFilterDrawer, sortByName, cavistShop, usersByPhone]);

    const onClickAddMoreProduct = () => {
        setLimitTable(prevState => prevState + 1);
        dispatch(getAdditionalProduct({
            page: limitTable + 1,
            limit: 15,
            name_sort: sortByName || undefined,
            search_string: searchOrder || undefined,
            price_sort: selectPrice || undefined,
            available: selectAvailability || undefined,
            ...getPayload(),
        }));
    };

    useEffect(() => {
        setCavistShop(allShop?.find(item => item?.address === usersByPhone?.shop?.address));
    }, [allShop, usersByPhone]);

    useEffect(() => {
        dispatch(getAllShop());
    }, []);

    const productsTable = products?.products;
    const endProduct = products?.end;

    return (
        <TableWrapper
            onClickSort={onClickSortByDate}
            tableHeadRow={getTableHead(t)}
        >
            {(productsTable && productsTable.length)
                ? (
                    <>
                        {productsTable.map(row => (
                            <TableRow
                                key={row.product_id}
                                sx={{
                                    '&:hover': {
                                        boxShadow: `0px 0px ${units(6)} rgba(130, 141, 171, 0.16)`,
                                        borderRadius: `${units(15)}`,
                                    },
                                }}
                            >
                                <StyledTableCell align="center">
                                    {row.product_id}
                                </StyledTableCell>
                                <StyledTableCell
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    align="left"
                                    onClick={() => history.push(`/products-card/${row.product_id}`)}
                                >
                                    <StyledImageGoods>
                                        <img
                                            alt="изображение товара"
                                            src={row.images_links[0] ? `${config.commonUrl}/${row.images_links[0]}` : PlaceholderForImage}
                                        />
                                    </StyledImageGoods>
                                    <StyledNameGoods> {row.name} </StyledNameGoods>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    { row.volume ? `${row.volume}л` : `${row.weight}кг`}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {productPriceToString(row)}
                                </StyledTableCell>
                                <StyledTableCell
                                    sx={{
                                        color: !row.available ? 'red !important' : 'inherit',
                                    }}
                                    align="left"
                                >
                                    <AvailableCircle available={row.available} />
                                    {row.available ? 'В наличии' : 'Нет в наличии'}
                                </StyledTableCell>
                                <StyledTableCell align="left">{row.available_quantity}</StyledTableCell>
                                <StyledTableCell align="left">{row.shops_quantity}</StyledTableCell>
                            </TableRow>
                        ))}
                        {!endProduct
                            ? (
                                <StyledTableCell
                                    sx={{ borderTop: `1px solid ${colors.gray3}` }}
                                    colSpan={6}
                                    align="center"
                                >
                                    <WrapperBottomTable onClick={() => onClickAddMoreProduct()}>
                                        Больше товаров
                                        <Icon
                                            type={TypesIcon.ARROW_DROP_DOWN}
                                            width="20px"
                                            height="20px"
                                            fill={colors.dark}
                                        />
                                    </WrapperBottomTable>
                                </StyledTableCell>
                            ) : null}
                    </>
                )
                : (
                    <TableCell
                        sx={{
                            borderTop: `1px solid ${colors.gray3}`,
                            position: 'static',
                            padding: '48px 0',
                        }}
                        colSpan={6}
                        align="center"
                    >
                        <StyledTableNotFound>
                            Товары не найдены
                        </StyledTableNotFound>
                    </TableCell>
                )}
        </TableWrapper>
    );
};
