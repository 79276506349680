import React from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { TypesButton } from '../../constants/ButtonTypes';
import { units } from '../../helpers/styles/units';
import { Button } from '../Button';
import { colors } from '../../constants/Colors';

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: ${units(6)};
`;

const StyledMainButton = styled(Button)`
  width: 84px;

  :not(:last-child) {
    margin-right: ${units(4)};
  }
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${units(6)};
  width: 100%;
`;

const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;

const StyledDescriptionWrapper = styled.div`
  display: flex;
  padding: ${units(6)};
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    display: flex;
    flex-direction: column;
    min-width: ${units(162)};
    max-height: 90vh;
    width: 500px;

    background-color: ${colors.white};
    box-shadow: 0px 12px 50px rgba(130, 141, 171, 0.1);
    border-radius: ${units(8)};
    overflow: hidden;
  }
`;

const StyledContent = styled.div`
  width: 400px;
  margin-left: 56px;
  margin-right: 56px;
  margin-top: 48px;
  margin-bottom: 32px;
  overflow: hidden;
`;

const StyledBackgroundBlock = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const CustomPrompt = ({ cleanUp }: { cleanUp: (answer: any) => void }) => {
    const cancel = () => cleanUp(false);
    const ok = () => cleanUp(true);
    return (
        <>
            <StyledBackgroundBlock />
            <StyledDialog open onClose={cancel}>
                <StyledContent>
                    <StyledTitleWrapper>
                        <StyledTitle>
                            Данные не сохранены
                        </StyledTitle>
                    </StyledTitleWrapper>
                    <StyledDescriptionWrapper>
                        Вы изменили данные на странице. При переходе все несохраненные данные будут потеряны.
                    </StyledDescriptionWrapper>

                    <StyledButtonWrapper>
                        <StyledMainButton
                            typeButton={TypesButton.OUTLINE_BORDER_GREY}
                            onClick={cancel}
                        >
                            Отмена
                        </StyledMainButton>
                        <StyledMainButton
                            typeButton={TypesButton.FILL}
                            onClick={ok}
                        >
                            Да
                        </StyledMainButton>
                    </StyledButtonWrapper>
                </StyledContent>
            </StyledDialog>
        </>
    );
};

export default CustomPrompt;
