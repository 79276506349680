import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import { typography } from '../../helpers/styles/typography';
import { applyColor } from './applyColor';
import { applyBgColor } from './applyBgColor';
import { applyHoverBgColor } from './applyHoverBgColor';
import { applyHoverColor } from './applyHoverColor';
import { TypesButton } from '../../constants/ButtonTypes';
import { applyDisabledColor } from './applyDisabledColor';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { units } from '../../helpers/styles/units';
import { applyActiveColor } from './applyActiveColor';
import { applyActiveBgColor } from './applyActiveBgColor';
import { applyBorder } from './applyBorder';

export interface ButtonProps {
    onClick?: (event?: unknown) => void;
    typeButton: TypesButton;
    disabled?: boolean;
    className?: string;
}

const StyledButton = styled.button <Pick<ButtonProps, 'typeButton' | 'disabled'>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: max-content;

  padding: ${units(4)} ${units(8)};

  border-radius: ${units(4)};
  border: 0;

  font-weight: ${FontWeight.MEDIUM};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  transition: background .4s ease, color .4s ease;

  ${typography(5)};
  ${applyColor};
  ${applyBgColor};
  ${applyHoverBgColor};
  ${applyHoverColor};
  ${applyDisabledColor}
  ${applyActiveBgColor}
  ${applyActiveColor}
  ${applyBorder}
`;

export const Button = ({
    children,
    onClick,
    typeButton,
    disabled,
    ...props
}: PropsWithChildren<ButtonProps>) => (
    <StyledButton
        disabled={disabled}
        onClick={onClick}
        typeButton={typeButton}
        type="button"
        {...props}
    >
        {children}
    </StyledButton>
);
