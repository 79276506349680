import styled from 'styled-components';
import * as React from 'react';
import {
    ChangeEvent,
    useCallback,
    useRef,
    FormEvent,
} from 'react';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { BreakPoints } from '../../constants/Styles/breakPoints';

export interface TextareaProps {
    id?: string,
    name?: string,
    placeholder?: string,
    className?: string,
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onClick?: (event: FormEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: string | ChangeEvent) => void;
    onOpen?: () => void;
    value?: string | number
    onPostfixClick?:React.MouseEventHandler<HTMLDivElement>;
    maxLength?: number;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  
  :not(:last-child) {
    margin-bottom: ${units(4)};
  }

  @media screen and (max-width: ${BreakPoints.MD}) {
    flex-wrap: wrap;
  }
`;

const StyledFieldContainer = styled.label`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${BreakPoints.MD}) {
   margin-top: ${units(3)};
  }
`;

const StyledTextarea = styled.textarea`
  background-color: ${colors.white};
  width:100%;
  padding:15px;
  height:124px;
  border-radius: ${units(4)};
  outline: 0;
  color: ${colors.dark};
  border: 1px solid ${colors.gray3};
  resize: none;
  transition: border-color 0.2s;
  font-size: ${units(7)};
  :focus {
    border: 1px solid rgba(130, 141, 171, 1) /* Рамка при получении фокуса */
  }
`;

export const Textarea = ({
    id,
    name,
    placeholder,
    className,
    onChange,
    onBlur,
    value = '',
    ...props
}: TextareaProps) => {
    const ref = useRef<HTMLInputElement>(null);
    const refRoot = useRef<HTMLDivElement>(null);

    const handleBlur = useCallback((event: ChangeEvent) => {
        if (onBlur) {
            onBlur(event);
        }
    }, [onBlur]);

    return (
        <StyledWrapper
            ref={refRoot}
        >
            <StyledFieldContainer>
                <StyledTextarea
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    className={className}
                    onBlur={handleBlur}
                    value={value}
                    onChange={onChange}
                    {...props}
                />
            </StyledFieldContainer>
        </StyledWrapper>
    );
};
