import { createAction } from '@reduxjs/toolkit';
import { DrawerOrderModel } from '../../types/Models/DrawerOrderModel';

export const ADD_NEW_DRAWER_ORDER = 'ADD_NEW_DRAWER_ORDER';

export const addDrawerOrder = createAction(
    ADD_NEW_DRAWER_ORDER,
    ({
        idCart, dateOfIssue,
    }: DrawerOrderModel) => ({
        payload: {
            idCart, dateOfIssue,
        },
    }),
);
