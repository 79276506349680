import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as productApi from '../../../api/product';
import { ProductContractParams } from '../../../types/Endpoints/ProductContract';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addEmptyArray } from '../setEmtyArray';

export const GET_PRODUCT = 'products/getProducts';

const onFail = (): Thunk => async dispatch => {
    dispatch(addEmptyArray());
};
export const getProduct = createAsyncThunk(
    GET_PRODUCT,
    createRequest({
        type: GET_PRODUCT,
        loader: (params: ProductContractParams) => productApi.getProduct(params),
        onFail,
        showFailNotification: false,
    }),
);
