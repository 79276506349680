import { useTranslation } from 'react-i18next';
import * as React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TypesButton } from '../../../constants/ButtonTypes';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { colors } from '../../../constants/Colors';
import { createUser, updateUser } from '../../../actions/users';
import { units } from '../../../helpers/styles/units';
import { BELARUS_NUMBER } from '../../../helpers/validation';
import { addNotification } from '../../../actions/notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';

interface IConfirmeBlock{
    clientInformation: {
        inputName?: string,
        inputTelephone?: string
    }
    commentInformation: {
        textarea?: string;
    }
    createClient: boolean
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 110%;
  background: #FFFFFF;
  height: 60px;
  z-index: 998;
  overflow: hidden;
  box-shadow: 0px 0px 12px 0px rgba(130, 141, 171, 0.16);
`;

const StyledButtonDrawer = styled(Button)`
  width: 100%;
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  align-items: center;
  max-width: ${units(90)}; 
  max-height: ${units(18)};
  margin: 0 auto;

  svg {
    margin-right: ${units(2)};
  }
`;

const StyledButtonWrapper = styled.div`
  margin-left: ${units(20)};
`;

export const ConfirmeBlock = ({ createClient, clientInformation, commentInformation }: IConfirmeBlock) => {
    const { t } = useTranslation('orders');
    const { id } = useParams<{ id: string}>();
    const dispatch = useDispatch();

    const onClickCreateUser = () => {
        if (clientInformation.inputTelephone && !BELARUS_NUMBER.test(clientInformation.inputTelephone)) {
            dispatch(addNotification({
                type: TypesSnackbars.SUCCESS,
                message: {
                    isMistake: true,
                    description: 'Проверьте номер телефона. Правильный вид номера телефона 375 ** *** ** **',
                },
            }));
        } else if (createClient) {
            dispatch(createUser({
                parameters: {
                    fio: clientInformation.inputName || '',
                    phone: clientInformation.inputTelephone?.replace(/ /g, '') || '',
                    comment: commentInformation.textarea || '',
                },
            }));
        } else {
            dispatch(updateUser({
                params: {
                    id: +id,
                },
                userData: {
                    parameters: {
                        fio: clientInformation.inputName || '',
                        phone: clientInformation.inputTelephone?.replace(/ /g, '') || '',
                        comment: commentInformation.textarea || '',
                    },
                },
            }));
        }
    };

    const disableButton = ((clientInformation.inputName === '') || (clientInformation.inputTelephone === ''));

    return (
        <StyledWrapper>
            <StyledButtonWrapper>
                <StyledButtonDrawer
                    typeButton={TypesButton.FILL}
                    onClick={() => onClickCreateUser()}
                    disabled={disableButton}
                >
                    <Icon
                        type={TypesIcon.DONE}
                        fill={colors.white}
                        width="20px"
                        height="20px"
                    />
                    {createClient ? 'Добавить клиента' : 'Обновить'}
                </StyledButtonDrawer>
            </StyledButtonWrapper>
        </StyledWrapper>
    );
};
