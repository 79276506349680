import { request } from '../../helpers/api/request';
import { ShopContract } from '../../types/Endpoints/ShopContract';

export const getAllShops = async () => {
    const { data } = await request<ShopContract>({
        url: 'shops',
    });

    return data;
};
