import { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';
import { RangeControlProps } from '../../types/Form/RangeControlProps';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { Input } from '../Input';

export interface RangeValue {
    from?: string;
    to?: string;
}

interface RangeProps extends Omit<RangeControlProps, 'onChange' | 'label'> {
    onChange?: (field: string, value: RangeValue, shouldValidate?: boolean) => void;
    value?: RangeValue;
    className?: string;
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledField = styled.div`
  width: 100%;

  :first-of-type {
    margin-right: ${units(4)};
  }
`;

const StyledErrorMessage = styled.span`
  position: absolute;
  top: ${units(13)};

  color: ${colors.red};
  font-weight: 600;
  ${typography(0)};
`;

export const Range = ({
    name,
    value,
    error,
    onChange,
    className,
    ...props
}: RangeProps) => {
    const handleSuccessFrom = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(name, {
                ...value,
                from: event.target.value,
            }, false);
        }
    }, [name, onChange, value]);

    const handleSuccessTo = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(name, {
                ...value,
                to: event.target.value,
            }, false);
        }
    }, [name, onChange, value]);

    return (
        <StyledWrapper className={className}>
            <StyledField>
                <Input
                    name="from"
                    value={value?.from}
                    onChange={handleSuccessFrom}
                    error={error}
                    prefix="от"
                    placeholder="0"
                    typeInput="number"
                    min={0}
                    max={9999}
                    {...props}
                />
                {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
            </StyledField>
            <StyledField>
                <Input
                    name="to"
                    value={value?.to}
                    onChange={handleSuccessTo}
                    error={error}
                    prefix="до"
                    placeholder="9999"
                    typeInput="number"
                    min={0}
                    max={9999}
                    {...props}
                />
                {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
            </StyledField>
        </StyledWrapper>
    );
};
