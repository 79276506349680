import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';

interface ISearchInput {
    placeholder: string,
    searchOrder: string,
    setSearchOrder: (state: string) => void
    allSymbols?: boolean
}

const StyledInputMask = styled(InputMask)`
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  color: ${colors.dark};
  font-size: ${units(7)};
  line-height: ${units(10)};
  
  ::placeholder {
    font-size: ${units(7)};
    line-height: ${units(10)};
    color: ${colors.gray};
  }

  :focus + label, :not(:placeholder-shown) + label{
    top: -${units(4)};
    left: -${units(1)};

    padding: ${units(1)} ${units(2)};
    
    background-color: ${colors.white};
  }
  
`;

export const SearchInputWithMask = ({
    placeholder,
    searchOrder,
    setSearchOrder,
}: ISearchInput) => (
    <Paper
        component="form"
        sx={{
            p: '2px 4px', display: 'flex', alignItems: 'center', width: 400,
        }}
    >
        <IconButton sx={{ p: '10px' }} aria-label="search">
            <Icon
                type={TypesIcon.SEARCH}
                width="20px"
                height="20px"
                fill={searchOrder.length > 0 ? colors.dark : colors.gray}
            />
        </IconButton>
        <StyledInputMask
            mask="+375 (99) 999 99 99"
            placeholder={placeholder}
            onChange={event => setSearchOrder(event.target.value)}
            onKeyDown={e => {
                if (e.keyCode === 13) {
                    e.preventDefault();
                }
            }}
            value={searchOrder}
        />
        {searchOrder.length > 0
            ? (
                <IconButton
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                    onClick={() => { setSearchOrder(''); }}
                >
                    <Icon
                        type={TypesIcon.CANCEL_CIRCLE_GREY}
                        width="20px"
                        height="20px"
                        fill={colors.gray}
                        viewBox="0 0 20 20"
                    />
                </IconButton>
            ) : null}
    </Paper>
);
