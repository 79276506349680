import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { Logo } from '../Logo';
import { Divider } from '../Divider';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { Navigation } from '../Navigation';
import { UserItem } from '../UserItem';
import { removeSessionFromStorage } from '../../helpers/auth/session';
import { getCurrentUser } from '../../actions/сurrentUser';
import { logout } from '../../actions/auth';

const StyledRoot = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  width: ${units(115)};
  min-width: ${units(115)};
  height: 100vh;
  min-height: ${units(200)};
  z-index: 999;
  background: ${colors.white};
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${units(12)} ${units(6)};
`;

const StyledDivider = styled(Divider)`
  padding: 0 ${units(6)};
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: ${units(6)} ${units(9)};
  margin: ${units(4)} 0;
  width: 100%;
  
  font-weight: ${FontWeight.MEDIUM};
  color: ${colors.dark};
  
  background: ${colors.white};
  border-radius: ${units(4)};
  border: 0;
  
  cursor: pointer;
  
  ${typography(5)}
  
  :hover {
    color: ${colors.blue};

    background: ${colors.whiteBlue};
  }
  
  & svg {
    margin-right: ${units(6)};
  }
  
  &:hover svg {
    fill: ${colors.blue} !important;
  }
`;

const StyledDownWrapper = styled.div`
    padding: 0 ${units(6)};
`;

const StyledUserItem = styled(UserItem)`
    margin-bottom: ${units(11)};
`;

export const Sidebar = () => {
    const { t } = useTranslation('sidebar');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCurrentUser());
    }, []);

    const onClickLogout = () => {
        removeSessionFromStorage();
        dispatch(logout());
        window.location.href = '/';
    };

    return (
        <StyledRoot>
            <div>
                <StyledLogoWrapper>
                    <Logo />
                </StyledLogoWrapper>
                <StyledDivider />
                <Navigation />
            </div>
            <StyledDownWrapper>
                <StyledUserItem />
                <Divider />
                <StyledButton onClick={() => onClickLogout()}>
                    <Icon
                        type={TypesIcon.LOGOUT}
                        width="20px"
                        height="20px"
                    />
                    {t('buttons.logout')}
                </StyledButton>
            </StyledDownWrapper>
        </StyledRoot>
    );
};
