import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { typography } from '../../../helpers/styles/typography';
import { DrawerFilters } from './DrawerFilters';
import { CustomizedMenus } from './CustomizedMenusStatus';
import { SearchInput } from '../../../components/SearchInput';
import { comparingArrays } from '../../../helpers/arrayСomparison';

interface Formik {
    statusSelect: string[];
}

interface IWrapperButtons{
    isResetButton?: boolean;
}

interface IStyledButton{
    isSelectedFilter?: boolean
}

type FormValues = Partial<Formik>;

const StyledTableFiltersWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled.button<IStyledButton>`
  display: flex;
  align-items: center;
  padding: ${units(3)} ${units(6)};
  margin: ${units(4)} 0;
  gap: ${units(2)};
  max-width: ${units(47)};
  max-height: ${units(16)};

  font-weight: ${FontWeight.MEDIUM};
  color: ${({ isSelectedFilter }) => (!isSelectedFilter ? colors.blue : colors.dark)};

  svg {
    fill:  ${({ isSelectedFilter }) => (!isSelectedFilter ? colors.blue : colors.dark)} !important;
  }

  background: ${colors.white};
  border-radius: ${units(6)};
  border: 0;

  cursor: pointer;

  ${typography(5)}
  :hover {
    color: ${colors.blue};
    background: ${colors.whiteBlue};
  }

  &:hover svg {
    fill: ${colors.blue} !important;
  }
`;

const WrapperButtons = styled.div<IWrapperButtons>`
  display: flex;
  justify-content: space-between;
  width:  ${({ isResetButton }) => (!isResetButton ? units(170) : units(110))};
`;

const WrapperInput = styled.div`
  & > .MuiPaper-root {
    box-shadow: none;
    border-radius: ${units(6)};
    max-width: 20.3125rem;
    max-height: 2.75rem;
    font: revert;
  }

  & .MuiInputBase-input {
    color: ${colors.gray};
    font-size: 14px;
    line-height: 20px;
  }

  & .MuiInputBase-root {
    margin-left: 0;
  }
`;

const StyledResetButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: ${colors.gray};
  white-space: nowrap;
  margin-right: ${units(12)};
  margin-top: ${units(-6)};
  cursor: pointer;
  
  &:hover{
    color: ${colors.dark};
  }
`;

export const TableFilters = (
    {
        searchOrder,
        setSearchOrder,
        formSelectOrders,
    } : {
    searchOrder: string,
    setSearchOrder: (state: string) => void,
    formSelectOrders: FormikProps<FormValues>},
) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isSelectedFilter, setIsSelectedFilter] = useState<boolean>(false);
    const { t } = useTranslation('filters');

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickResetButton = () => {
        formSelectOrders.resetForm();
    };

    const isResetButton = comparingArrays(formSelectOrders.values.statusSelect, formSelectOrders.initialValues.statusSelect);

    return (
        <StyledTableFiltersWrapper>
            <WrapperInput>
                <SearchInput
                    placeholder={t('input.searchPhoneNumber')}
                    searchOrder={searchOrder}
                    setSearchOrder={setSearchOrder}
                />
            </WrapperInput>
            <WrapperButtons isResetButton={isResetButton}>
                { !isResetButton
                    ? (
                        <StyledResetButton onClick={() => onClickResetButton()}>
                            Сбросить статус
                        </StyledResetButton>
                    ) : null}
                <div>
                    <CustomizedMenus formSelectOrders={formSelectOrders} />
                </div>
                <StyledButton
                    onClick={handleClick}
                    isSelectedFilter={isSelectedFilter}
                >
                    <Icon
                        type={TypesIcon.TUNE}
                        width="20px"
                        height="20px"
                    />
                    {t('sentences.filter')}
                </StyledButton>
                <DrawerFilters
                    setIsSelectedFilter={setIsSelectedFilter}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                />
            </WrapperButtons>
        </StyledTableFiltersWrapper>
    );
};
