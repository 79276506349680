import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from '../../Button';
import { TypesButton } from '../../../constants/ButtonTypes';
import { units } from '../../../helpers/styles/units';

const StyledRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  
  > :not(:last-child) {
    margin-right: ${units(4)};
  }
`;

interface DefaultModalActionProps {
  onClose: () => void;
  onSuccess: () => void;
  onSuccessMessage?: ReactNode;
  withCloseAction?: boolean;
}

export const DefaultModalAction = ({
    onClose,
    onSuccess,
    onSuccessMessage,
    withCloseAction,
}: DefaultModalActionProps) => (
    <StyledRoot>
        {withCloseAction && (
            <Button
                typeButton={TypesButton.FILL}
                onClick={onClose}
            >
                Отменить
            </Button>
        )}
        <Button
            typeButton={TypesButton.OUTLINE_BORDER}
            onClick={onSuccess}
        >
            {onSuccessMessage}
        </Button>
    </StyledRoot>
);
