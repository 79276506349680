import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store/history';
import { GlobalFontStyle } from './components/GlobalStyle/GlobalFontStyle';
import { GlobalStyle } from './components/GlobalStyle';
import { Router } from './pages';
import './i18n';

console.log('process.env.COMMON_URL', process.env);

const App = () => (
    <ConnectedRouter history={history}>
        {/* шрифты приложения */}
        <GlobalFontStyle />
        {/* стили приложения */}
        <GlobalStyle />
        <Router />
    </ConnectedRouter>
);

export default App;
