import { createReducer } from '@reduxjs/toolkit';
import { getOrderCard, removeOrderCard, updateOrders } from '../../actions/orders';
import { OrderCardModel } from '../../types/Models/OrderCardModel';
import { OrderPricesForShopResponse } from '../../types/Endpoints/OrderPricesForShop';
import { getPriceProductsForShop } from '../../actions/orders/thunks/getPriceProductsForShop';

interface OrderCard {
    orderCard?: OrderCardModel | undefined;
    orderPrices?: OrderPricesForShopResponse[];
}

export const orderCard = createReducer<OrderCard>({}, builder => {
    builder.addCase(getOrderCard.fulfilled, (state, { payload }) => ({
        ...state,
        orderCard: payload,
    }));
    builder.addCase(removeOrderCard, (state, { payload }) => ({ }));
    builder.addCase(updateOrders.fulfilled, state => ({
        ...state,
    }));
    builder.addCase(getPriceProductsForShop.fulfilled, (state, { payload }) => ({
        ...state,
        orderPrices: payload,
    }));
});
