import { colors } from '../../../constants/Colors';
import { FiltersMenuProps } from '../../../types/Filters/FiltersMenuProps';

export const getFieldsSelectTime = (timespans : string[]) : FiltersMenuProps[] => ([
    ...timespans.map(timespan => (
        {
            key: `${timespan}`,
            name: timespan,
            color: colors.blue,
            background: colors.blue_30,
        }
    )),
]);
