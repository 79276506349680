import { createAction } from '@reduxjs/toolkit';
import { DrawerProductsModal } from '../../types/Models/DrawerProductsModal';

export const ADD_NEW_DRAWER_PRODUCTS = 'ADD_NEW_DRAWER_PRODUCTS';

export const addDrawerProducts = createAction(
    ADD_NEW_DRAWER_PRODUCTS,
    ({
        // eslint-disable-next-line camelcase
        category, category_id, price_range, country, volume, alcohol_content_range, gift_box, wine_sugar_type, whiskey_type, champagne_type, cognac_type,
    }: DrawerProductsModal) => ({
        payload: {
            // eslint-disable-next-line camelcase
            category, category_id, price_range, country, volume, alcohol_content_range, gift_box, wine_sugar_type, whiskey_type, champagne_type, cognac_type,
        },
    }),
);
