import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { units } from '../../helpers/styles/units';
import { Divider } from '../Divider';
import { colors } from '../../constants/Colors';

interface IDrawerFilters{
    headerTitle: string,
    maxWidth?: number,
    color?: string,
}

interface IRootStyleProps{
    maxWidth?: number
}

interface ICircle{
    color?: string
}

const RootStyle = styled.div<IRootStyleProps>`
  background: #FFFFFF;
  border-radius: ${units(8)};
  max-width:  ${({ maxWidth }) => (maxWidth ? units(maxWidth) : '100%')};
  height: max-content;
  width: 100%;
`;

const HeaderInformationBlock = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: ${units(7)};
  line-height: ${units(10)};
  margin: ${units(6)} ${units(12)};
`;

const InformationBlockContent = styled.div`
  margin: ${units(16)} ${units(12)};
  line-height: ${units(10)};
`;

const StyledCircle = styled.div<ICircle>`
  width: 8px;
  height: 8px;
  border: 2.4px solid  ${({ color }) => (color)};;
  border-radius: 100%;
  margin-right: 8px;
`;

export const InformationWrapperBlock = ({
    headerTitle, maxWidth,
    children,
    color = colors.blue,
}: PropsWithChildren<IDrawerFilters>) => {
    const { t } = useTranslation('filters');

    return (
        <RootStyle maxWidth={maxWidth}>
            <HeaderInformationBlock>
                <StyledCircle color={color} />
                <span>{headerTitle}</span>
            </HeaderInformationBlock>
            <Divider />
            <InformationBlockContent>
                {children}
            </InformationBlockContent>
        </RootStyle>
    );
};
