import { request } from '../../helpers/api/request';
import { OrderProductsPrice } from '../../types/Models/OrderProduct';
import { OrderPricesForShop } from '../../types/Endpoints/OrderPricesForShop';

export const getPriceProductsForSelectShop = async (shopId: number, productsList: OrderProductsPrice[]) => {
    const { data } = await request<OrderPricesForShop>({
        url: `orders/${shopId}/calculate`,
        method: 'POST',
        data: { products: productsList },
    });

    return data;
};
