import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as productApi from '../../../api/product';
import { ProductContractCardParams } from '../../../types/Endpoints/ProductContractCard';

export const GET_PRODUCT_CARD = 'products/getProductsCard';

export const getProductCard = createAsyncThunk(
    GET_PRODUCT_CARD,
    createRequest({
        type: GET_PRODUCT_CARD,
        loader: (params: ProductContractCardParams) => productApi.getProductCard(params),
        showFailNotification: false,
    }),
);
