import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as shopApi from '../../../api/shop';
import { IShopList } from '../../../types/Models/ShopListModel';

export const GET_ALL_SHOP = 'shop/getAllShop';

export const getAllShop = createAsyncThunk<IShopList[]>(
    GET_ALL_SHOP,
    createRequest({
        type: GET_ALL_SHOP,
        loader: () => shopApi.getAllShops(),
        showFailNotification: false,
    }),
);
