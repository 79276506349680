import { css, FlattenSimpleInterpolation } from 'styled-components';

const fontSize: Record<number, FlattenSimpleInterpolation> = {
    0: css`
      font-size: 12px;
      line-height: 16px;
    `,
    5: css`
      font-size: 14px;
      line-height: 20px;
    `,
    10: css`
      font-size: 16px;
      line-height: 24px;
    `,
    15: css`
      font-size: 22px;
      line-height: 28px;
    `,
    20: css`
      font-size: 24px;
      line-height: 32px;
    `,
    25: css`
      font-size: 32px;
      line-height: 40px;
    `,
};

export const typography = (step: number) => fontSize[step];
