import { request } from '../../helpers/api/request';
import { Login } from '../../types/Endpoints/LoginContract';

export const login = async (loginData: Login) => {
    const { data } = await request({
        url: 'auth',
        method: 'POST',
        data: loginData,
    });

    return data;
};
