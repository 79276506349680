import { request } from '../../helpers/api/request';
import { OrderCardContract, OrderCardContractParams } from '../../types/Endpoints/OrderCardContract';

export const getOrderCard = async (params: OrderCardContractParams) => {
    const { data } = await request<OrderCardContract>({
        url: `orders/${params.id}`,
    });

    return data;
};
