import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';

const StyledTitle = styled.p`
  font-weight: ${FontWeight.MEDIUM};
  
  ${typography(20)}
`;

export const PageTitle = ({ className, children }: PropsWithChildren<{className?: string}>) => (
    <StyledTitle className={className}>
        {children}
    </StyledTitle>
);
