import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { TypesButton } from '../../constants/ButtonTypes';
import { PageTitle } from '../PageTitle';
import { Button } from '../Button';

interface IDrawerFilters {
    anchorEl: null | HTMLElement,
    handleClose(): void,
    onClickFind?: () => void,
    onClickСancel?: () => void,
    disabledButton?: boolean;
    isForm?: boolean
    isConfirmationButtons?: boolean,
    title?: string,
    subTitle?: string
}

interface IStyledDriverBackground{
    open: boolean
}

const WrapperDrawerContent = styled.div`
  width: 100%;
  min-width: ${units(173)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrapperHeader = styled.div`
  padding: ${units(12)} ${units(10)} ${units(5)};

`;

const StyledIcon = styled.div`
  display: flex;
  cursor: pointer;

  svg {
    fill: ${colors.dark} !important;
  }

  &:hover svg {
    fill: ${colors.gray} !important;
  }
`;

const WrapperButtons = styled.div`
  width: 100%;
  padding: 0 ${units(10)};
`;

const StyledButtonDrawer = styled(Button)`
  width: 100%;

  :first-of-type{
    margin-bottom: ${units(6)};
  }
`;

const SubTitleDrawer = styled.div`
  font-weight: 400;
  font-size: ${units(7)};
  line-height: ${units(10)};
`;

const StyledDriverBackground = styled.div<IStyledDriverBackground>`
  display: ${({ open }) => (!open ? 'none' : 'inherit')};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 900;
  cursor: pointer;
  top: 0;
  right: 0;
`;

const StyledRoot = styled.div`
  .MuiPaper-elevation{
    border-left: 0;
    max-width: 390px;
  }
`;

export const DrawerWrapper = ({
    anchorEl,
    handleClose,
    children,
    title,
    subTitle,
    isConfirmationButtons = true,
    onClickFind = () => console.log(1),
    onClickСancel = () => console.log(1),
    disabledButton = true,
}: PropsWithChildren<IDrawerFilters>) => {
    const { t } = useTranslation('filters');

    return (
        <StyledRoot>
            <StyledDriverBackground
                onClick={() => handleClose()}
                open={Boolean(anchorEl)}
            />
            <Drawer
                id="simple-menu"
                anchor="right"
                open={Boolean(anchorEl)}
                variant="persistent"
            >
                <WrapperHeader>
                    <WrapperDrawerContent>
                        <PageTitle>
                            {title}
                        </PageTitle>
                        <StyledIcon onClick={() => handleClose()}>
                            <Icon
                                type={TypesIcon.CLOSE}
                                width="24px"
                                height="24px"
                                fill={colors.gray}
                            />
                        </StyledIcon>
                    </WrapperDrawerContent>
                    {subTitle
                        ? (
                            <SubTitleDrawer>
                                {subTitle}
                            </SubTitleDrawer>
                        ) : null}
                </WrapperHeader>
                {children}
                {isConfirmationButtons
                    ? (
                        <WrapperButtons>
                            <StyledButtonDrawer
                                typeButton={TypesButton.FILL}
                                onClick={() => onClickFind()}
                            >
                                {t('buttons.find')}

                            </StyledButtonDrawer>
                            <StyledButtonDrawer
                                disabled={disabledButton}
                                typeButton={TypesButton.OUTLINE}
                                onClick={() => onClickСancel()}
                            >
                                {t('buttons.reset')}
                            </StyledButtonDrawer>
                        </WrapperButtons>
                    ) : null}
            </Drawer>
        </StyledRoot>
    );
};
