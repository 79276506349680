import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { BreadcrumbsProps } from '../../types/Breadcrumbs/BreadcrumbsProps';

const RootStyle = styled.nav`
  display: flex;
  width: 100%;
  margin: ${units(12)} 0;
`;

const BreadcrumbItem = styled.div`
  font-weight: 400;
  font-size:  ${units(6)};
  line-height:  ${units(8)};
  cursor: pointer;
  border-bottom: 1px solid transparent;

  :hover{
    color: ${colors.blue};
    border-bottom: 1px solid ${colors.blue};

  }
`;

const BreadcrumbsLastItem = styled.div`
  font-weight: 400;
  font-size:  ${units(6)};
  line-height:  ${units(8)};
  color: ${colors.gray};
`;

const Slash = styled.span`
  font-size:  ${units(6)};
  line-height:  ${units(8)};
  margin-right: ${units(8)};
  margin-left: ${units(8)};
`;

interface Breadcrumbs{
    breadcrumbs: BreadcrumbsProps[]
}

export const Breadcrumbs = ({ breadcrumbs } : Breadcrumbs) => {
    const { t } = useTranslation('sidebar');
    const history = useHistory();

    return (
        <RootStyle>
            {
                breadcrumbs.map(breadcrumbItem => (
                    breadcrumbs.at(-1) !== breadcrumbItem
                        ? (
                            <>
                                <BreadcrumbItem key={breadcrumbItem.key} onClick={() => history.push(breadcrumbItem.url)}>
                                    {breadcrumbItem.value}
                                </BreadcrumbItem>
                                <Slash>/</Slash>
                            </>
                        ) : (
                            <BreadcrumbsLastItem key={breadcrumbItem.key}>
                                {breadcrumbItem.value}
                            </BreadcrumbsLastItem>
                        )
                ))
            }
        </RootStyle>
    );
};
