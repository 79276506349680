import { createReducer } from '@reduxjs/toolkit';
import { addStoringPhoneNumber, removeStoringPhoneNumber } from '../../actions/storingPhoneNumber';

interface StoringPhoneNumber {
    storingPhoneNumber?: string;
}

export const storingPhoneNumber = createReducer<StoringPhoneNumber>({}, builder => {
    builder.addCase(addStoringPhoneNumber, (state, { payload }) => ({
        ...state,
        storingPhoneNumber: payload.phone,
    }));
    builder.addCase(removeStoringPhoneNumber, state => ({
        storingPhoneNumber: undefined,
    }));
});
