import { request } from '../../helpers/api/request';
import { CreateUsersData } from '../../types/Endpoints/CreateUser';

export const createUser = async (userData: CreateUsersData) => {
    const { data } = await request({
        url: 'users/add',
        method: 'POST',
        data: userData,
    });

    return data;
};
