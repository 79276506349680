import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_NEW_ORDERS } from '../../actions/listNewOrders';

const selectRoot = ({ listNewOrders }: RootState) => listNewOrders;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectNewOrdersLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_NEW_ORDERS],
);

export const selectListNewOrders = createSelector(
    selectRoot,
    ({ listNewOrders }) => listNewOrders,
);
