import React, {
    PropsWithChildren, ReactNode, useState,
} from 'react';
import styled from 'styled-components';
import { CheckOption } from '../../types/Form/CheckOption';
import { CheckElement } from '../CheckElement';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';

interface CheckboxGroupProps {
    controls?: CheckOption[];
    value?: Record<string, boolean>;
    onChange?: (field: string, value?: boolean, shouldValidate?: boolean) => void;
    prefix?: ReactNode;
    isRow?: boolean;
    handleClick(state : boolean): void;
}

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  position: relative;
`;

const StyledCheckbox = styled(CheckElement)<Pick<CheckboxGroupProps, 'isRow'>>`
  height: auto;
  padding: ${units(2)};


  span {
    max-width: ${units(70)};
  }

  :not(:last-child) {
    margin-bottom: ${({ isRow }) => (!isRow ? '0' : units(2))};
  }
`;

const StyledCheckboxGroup = styled.div<Pick<CheckboxGroupProps, 'isRow'>>`
  //display: grid;
  //grid-template-columns: auto  auto auto;
  //grid-column-gap: 10px;
  //grid-row-gap: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 1em;
`;

const StyledPrefixWrap = styled.div`
  margin-right: ${units(6)};
`;

const StyledChooseAll = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray};
  cursor: pointer;
  letter-spacing: 0.001em;
  text-decoration-line: underline;
  margin-top: 28px;
  margin-bottom: 20px;
`;

const StyledNumberValues = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray};
  cursor: pointer;
  letter-spacing: 0.001em;
  text-decoration-line: underline;
  position: absolute;
  right: 0;
  top: -36px;
  display: flex;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const CheckboxGroup = ({
    controls,
    value,
    onChange,
    prefix,
    isRow = false,
    handleClick,
}: PropsWithChildren<CheckboxGroupProps>) => {
    const [stateChooseAll, setStateChooseAll] = useState<boolean>(true);
    const [stateLengthAll, setStateLengthAll] = useState<boolean>(false);

    const checkboxControl = controls?.map(({ title, key }: CheckOption, index) => {
        if (!stateLengthAll) {
            if (index < 4) {
                return (
                    <StyledCheckbox
                        key={key}
                        title={title}
                        name={key}
                        onChange={onChange}
                        checked={value ? value[key] : false}
                    />
                );
            }
        }
        if (stateLengthAll) {
            return (
                <StyledCheckbox
                    key={key}
                    title={title}
                    name={key}
                    onChange={onChange}
                    checked={value ? value[key] : false}
                />
            );
        }
        return null;
    });

    const onClickChooseAll = () => {
        handleClick(stateChooseAll);
        setStateChooseAll(prevState => !prevState);
    };

    return (
        <StyledRoot>
            {prefix && <StyledPrefixWrap>{prefix}</StyledPrefixWrap>}
            <StyledNumberValues onClick={() => setStateLengthAll(prevState => !prevState)}>
                {controls?.length} значений
                <div>
                    <Icon
                        type={!stateLengthAll ? TypesIcon.ARROW_DROP_DOWN : TypesIcon.ARROW_DROP_UP}
                        fill={colors.gray}
                        width="20px"
                        height="20px"
                    />
                </div>
            </StyledNumberValues>
            <StyledCheckboxGroup isRow={isRow}>
                {checkboxControl}
            </StyledCheckboxGroup>
            <StyledChooseAll onClick={() => onClickChooseAll()}>
                {stateChooseAll ? 'Выбрать все' : 'Сбросить'}
            </StyledChooseAll>
        </StyledRoot>
    );
};
