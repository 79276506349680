import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import loginBg from '../../styles/images/loginBg.png';
import { units } from '../../helpers/styles/units';
import { Logo } from '../../components/Logo';
import { colors } from '../../constants/Colors';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';

const StyledRoot = styled.div`
  height: 100vh;
  
  background: url("${loginBg}") no-repeat;
  background-size: 100% 100%;
`;

const StyledBlurWrapper = styled.div`
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  height: 100vh;
  
  background: linear-gradient(75.13deg, rgba(255, 255, 255, 0.65) 35.97%, rgba(225, 233, 255, 0.65) 77.91%);
  backdrop-filter: blur(${units(4)});
`;

const StyledLogo = styled(Logo)`
  position: absolute;
  top: ${units(16)};
  left: 50%;
  transform: translate(-50%, 0);
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding: ${units(24)} ${units(28)};
  
  border-radius: ${units(8)};
  box-shadow: 0 ${units(6)} ${units(25)} rgba(130, 141, 171, 0.1);
  
  background: ${colors.white};
`;

const StyledInfoTitle = styled.p`
  margin-bottom: ${units(6)};
  
  font-weight: ${FontWeight.MEDIUM};
  
  ${typography(20)}
`;

const StyledInfoDescription = styled.p`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.005em;
  
  ${typography(10)}
`;

export const TechnicalPage = () => {
    const { t } = useTranslation('login');

    return (
        <StyledRoot>
            <StyledBlurWrapper>
                <StyledLogo />
                <StyledInfoWrapper>
                    <StyledInfoTitle>
                        {t('sentences.technicalTitle')}
                    </StyledInfoTitle>
                    <StyledInfoDescription>
                        {t('sentences.technicalDescription')}
                    </StyledInfoDescription>
                    <StyledInfoDescription>
                        {t('sentences.technicalTryAgain')}
                    </StyledInfoDescription>
                </StyledInfoWrapper>
            </StyledBlurWrapper>
        </StyledRoot>
    );
};
