import { request } from '../../helpers/api/request';
import { TimespansContract, TimespansContractParams } from '../../types/Endpoints/TimespansContract';

export const getTimespans = async (params: TimespansContractParams) => {
    const { data } = await request<TimespansContract>({
        url: `shops/${params.id}/timespans`,
        params: {
            date: params.date,
        },
    });

    return data;
};
