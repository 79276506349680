import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Modal } from '../../types/ModalTypes/Modal';

export const selectRoot = ({ modals }: RootState) => modals;

export const selectModals = createSelector(
    selectRoot,
    modals => Object.values(modals),
);

export const selectHasModals = (modalId: Modal['id']) => createSelector(
    selectModals,
    modals => !!modals?.find(({ id }) => id === modalId),
);

export const selectModalByType = (modalType: Modal['type']) => createSelector(
    selectModals,
    modals => modals.find(({ type }) => type === modalType),
);
