import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as userApi from '../../../api/user';
import { Login } from '../../../types/Endpoints/LoginContract';
import { setSessionToStorage } from '../../../helpers/auth/session';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';

export const LOGIN = 'user/login';

const onSuccess = (result: any) => {
    setSessionToStorage(result);
    window.location.href = '/orders';
};

const onFail = (): Thunk => async dispatch => {
    await dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            isMistake: true,
            description: 'Проверьте введенные данные',
        },
    }));
};

export const login = createAsyncThunk(
    LOGIN,
    createRequest({
        type: LOGIN,
        loader: (loginData: Login) => userApi.login(loginData),
        onFail,
        onSuccess,
    }),
);
