import { request } from '../../helpers/api/request';
import { ShopContract, ShopContractParams } from '../../types/Endpoints/ShopContract';

export const getShop = async (params: ShopContractParams) => {
    const { data } = await request<ShopContract>({
        url: `products/${params.id}/shops`,
    });

    return data;
};
