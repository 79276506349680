import axios, { AxiosError } from 'axios';
import { config } from '../../config';
import { EndpointContract } from '../../types/Endpoints/EndpointContract';
import { ServerRequestError, throwNetworkError } from './errors';
import { StatusCode } from '../../types/Errors/StatusCode';
import { getSessionFromStorage } from '../auth/session';

export const axiosInstance = axios.create({
    withCredentials: true,
});

export const getApiPath = () => {
    const { baseUrl } = config;

    return `${baseUrl}`;
};

interface RequestOptions<T extends EndpointContract> {
  headers?: Record<string, string>;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  url: string;
  data?: T['requestBody'];
  params?: T['pathParams'] | null;
  responseType?: 'json' | 'blob';
  mode?: '';
}

export interface RequestResult<T extends EndpointContract> {
  data: T['responseBody'];
  headers: T['responseHeader'];
}

export const request = async <T extends EndpointContract>({
    headers = {},
    method = 'GET',
    url,
    data,
    params,
    responseType,
}: RequestOptions<T>): Promise<RequestResult<T>> => {
    const { token } = getSessionFromStorage() || {};

    headers = {
        // eslint-disable-next-line max-len
        Authorization: `Bearer ${`${token}`}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
    };

    const options = {
        headers,
        method,
        params,
        responseType,
        data,
        url: `${getApiPath()}/${url}`,
    };

    try {
        return await axiosInstance(options);
    } catch (error) {
        const typedError = <AxiosError>error;

        throwNetworkError(typedError);

        const { message, response: { status = StatusCode.INTERNAL_SERVER_ERROR } = {} } = typedError || {};

        throw new ServerRequestError(message, status);
    }
};
