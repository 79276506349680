import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { TypesLabel } from '../../constants/Label';
import { colors } from '../../constants/Colors';

interface LabelProps {
    color: TypesLabel;
}

const colorByType: Record<string, string> = {
    [TypesLabel.SENT]: colors.orange,
    [TypesLabel.COLLECTION]: colors.yellow,
    [TypesLabel.READY]: colors.green,
    [TypesLabel.RECEIVED]: colors.blue,
    [TypesLabel.CANCELLED]: colors.red,
};

const backgroundByType: Record<string, string> = {
    [TypesLabel.SENT]: 'rgba(255, 131, 42, 0.1)',
    [TypesLabel.COLLECTION]: 'rgba(255, 183, 42, 0.1)',
    [TypesLabel.READY]: 'rgba(47, 194, 61, 0.1)',
    [TypesLabel.RECEIVED]: 'rgba(42, 98, 255, 0.1)',
    [TypesLabel.CANCELLED]: 'rgba(255, 42, 42, 0.1)',
};

const RootStyle = styled.span <LabelProps>`
        height: 28px;
        max-width: 106px;
        line-height: 0;
        border-radius: 50px;
        padding: 16px;
        cursor: default;
        align-items: center;
        white-space: nowrap;
        display: inline-flex;
        justify-content: center;
        color: ${({ color }) => (colorByType[color])};
        background-color: ${({ color }) => (backgroundByType[color])};
`;

export const Label = ({ children, color } : PropsWithChildren<LabelProps>) => (
    <RootStyle color={color}>
        {children}
    </RootStyle>
);
