import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { units } from '../../../helpers/styles/units';
import { InformationWrapperBlock } from '../../../components/InformationWrapperBlock';
import { InformationBlockDescription } from '../../../components/InformationBlockDescription';
import { getClientFields } from './getFieldsClient';
import { ClientSchema } from './ClientSchema';
import { Form } from '../../../components/Form';
import { getFieldsClientComment } from './fieldsClientComment';
import { TypesButton } from '../../../constants/ButtonTypes';
import { Button } from '../../../components/Button';
import { UsersModal } from '../../../types/Models/UsersModal';
import { updateUser } from '../../../actions/users';

interface Formik {
    textarea: string;
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${units(17)};
`;

const StyledClientComment = styled.div`
  max-height: ${units(125)};
  margin-left: ${units(20)};
  background: #FFFFFF;
  border-radius: ${units(8)};
`;

const StyledTitleComment = styled.div`
  font-weight: 500;
  font-size: ${units(7)};
  line-height: ${units(10)};
  margin-top: ${units(6)};
  margin-left: ${units(12)};
`;

const StyledButtonDrawer = styled(Button)`
  width: 100%;
  max-width: ${units(83)};
  margin-left: ${units(8)};
`;

const StyledWrapperForm = styled.div`
  margin: ${units(6)} ${units(12)} ${units(12)};
`;

const StyledDescriptionWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledStatisticsBlock = styled.div`
  display: flex;
  margin-top: ${units(10)};
  margin-left: ${units(20)};
`;

const StyledStatistics = styled.div`
    margin-left: ${units(10)};
    margin-right: ${units(59)};
    margin-bottom: ${units(15)};
    span{
      font-weight: 500;
      font-size: ${units(8)};
      line-height: ${units(12)};
    }
`;

const StyledDescriptionStatisticsBlock = styled.div`
  max-width: ${units(50.5)};
  font-weight: 400;
  font-size: ${units(6)};
  line-height: ${units(8)};
`;

const StyledWrapperContent = styled.div`
    display: flex;
`;

type FormValues = Partial<Formik>;

interface IClientInformation{
    user: UsersModal | undefined
}

export const ClientInformation = ({ user }: IClientInformation) => {
    const { t } = useTranslation('client');
    const dispatch = useDispatch();

    const handleSubmit = (values: FormValues) => {
        console.log(values);
    };

    const form = useFormik<FormValues>({
        onSubmit: handleSubmit,
        initialValues: {
            textarea: user?.comment || '',
        },
        enableReinitialize: true,
        validationSchema: ClientSchema(),
    });

    const onClickUpdate = () => {
        dispatch(updateUser({
            params: {
                id: user?.id || 0,
            },
            userData: {
                parameters: {
                    comment: form.values.textarea || '',
                },
            },
        }));
    };

    return (
        <StyledWrapper>
            <InformationWrapperBlock maxWidth={215} headerTitle="Клиент">
                <InformationBlockDescription widthNameFields={63} clientFields={getClientFields(t, user)} />
            </InformationWrapperBlock>
            <StyledDescriptionWrapper>
                <StyledClientComment>
                    <StyledTitleComment>
                        Комментарии
                    </StyledTitleComment>
                    <StyledWrapperForm>
                        <Form<FormValues>
                            fields={getFieldsClientComment(t)}
                            form={form}
                        />
                        <StyledButtonDrawer
                            onClick={() => onClickUpdate()}
                            typeButton={TypesButton.OUTLINE_BORDER}
                        >
                            {t('buttons.save')}
                        </StyledButtonDrawer>
                    </StyledWrapperForm>
                </StyledClientComment>
                <StyledStatisticsBlock>
                    <InformationWrapperBlock headerTitle="Статистика">
                        <StyledWrapperContent>
                            <StyledStatistics>
                                <span>
                                    {user?.statistics.orders_sum.toFixed(2)} руб.
                                </span>
                                <StyledDescriptionStatisticsBlock>
                                    {t('sentences.totalOrders')}
                                </StyledDescriptionStatisticsBlock>
                            </StyledStatistics>
                            <StyledStatistics>
                                <span>
                                    {user?.statistics.average_bill.toFixed(2)} руб.
                                </span>
                                <StyledDescriptionStatisticsBlock>
                                    {t('sentences.averageCheck')}
                                </StyledDescriptionStatisticsBlock>
                            </StyledStatistics>
                            <StyledStatistics>
                                <span>
                                    {user?.statistics.orders_count}
                                </span>
                                <StyledDescriptionStatisticsBlock>
                                    {t('sentences.numberOfOrders')}
                                </StyledDescriptionStatisticsBlock>
                            </StyledStatistics>
                        </StyledWrapperContent>
                    </InformationWrapperBlock>
                </StyledStatisticsBlock>
            </StyledDescriptionWrapper>
        </StyledWrapper>
    );
};
