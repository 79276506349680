import { colors } from '../../../../constants/Colors';
import { FiltersMenuProps } from '../../../../types/Filters/FiltersMenuProps';
import { CategoriesModal } from '../../../../types/Models/CategoriesModal';

export const getFieldsSelectCategory = (allCategory: CategoriesModal[] | []) : FiltersMenuProps[] => ([
    ...allCategory.map(category => (
        {
            key: `${category.category_id}`,
            name: category.name,
            color: colors.blue,
            background: colors.blue_30,
        }
    )),
]);

export const getCheckboxValues = (filters: Record<number, string>, parameter:string) => {
    const values = Object.values(filters);
    const keys = Object.keys(filters);
    const isValue = parameter === 'wine_sugar_type'
        || parameter === 'country'
        || parameter === 'whiskey_type'
        || parameter === 'cognac_type'
        || parameter === 'champagne_type'
        || parameter === 'volume';
    return values.map((item, index) => ({
        key: isValue ? item : keys[index],
        title: item,
    }));
};

export const getSelectValues = (filters: Record<string, string>) => {
    const values = Object.values(filters);
    const keys = Object.keys(filters);
    return values.map((item, index) => ({
        key: keys[index],
        name: item,
        color: colors.blue,
        background: colors.blue_30,
    }));
};
