import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as React from 'react';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { units } from '../../../helpers/styles/units';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { colors } from '../../../constants/Colors';
import { TypesButton } from '../../../constants/ButtonTypes';
import { Button } from '../../../components/Button';
import { UsersModal } from '../../../types/Models/UsersModal';
import { addModal } from '../../../actions/modal';
import { ModalTypes } from '../../../types/ModalTypes/ModalTypes';
import { deleteUser } from '../../../actions/users';

interface IHeaderClientPage{
    user: UsersModal | undefined
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledClient = styled.div`
   display: flex;
`;

const ClientNumber = styled.h2`
  font-weight: 500;
  font-size: ${units(12)};
  line-height: ${units(16)};
`;

const StyledButtonDrawer = styled(Button)`
  width: 100%;
  max-width: ${units(83)};
  display: flex;
  flex-direction: row;
  margin-left: ${units(8)};
  
  svg{
    margin-right: ${units(4)};
  }
`;

const ButtonDeleteOrder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${units(16)};
    height: ${units(16)};
    background: ${colors.white};
    border: 1px solid ${colors.gray3};
    border-radius: ${units(2)};;
    cursor: pointer;
    margin-left: ${units(6)};
`;

const StyledEditIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderClientPage = ({ user } : IHeaderClientPage) => {
    const { t } = useTranslation('client');
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const onSuccess = () => {
        dispatch(deleteUser({
            id: +id,
        }));
    };

    const handleOpenModal = useCallback(() => {
        dispatch(addModal({
            id: 1,
            type: ModalTypes.EDITING_PERSONAL_DATA,
            title: 'Удаление клиента',
            onSuccess,
            description: 'Вы действительно хотите удалить клиента?',
        }));
    }, [dispatch]);

    return (
        <StyledWrapper>
            <StyledClient>
                <ClientNumber> {t('sentences.client')} №{user?.id}</ClientNumber>
                { user?.status !== 'mobile'
                    ? (
                        <ButtonDeleteOrder onClick={() => handleOpenModal()}>
                            <Icon
                                type={TypesIcon.DELETE_BASKET}
                                width="20px"
                                height="20px"
                            />
                        </ButtonDeleteOrder>
                    )
                    : null}
            </StyledClient>
            <StyledClient>
                <StyledButtonDrawer
                    typeButton={TypesButton.FILL}
                    onClick={() => history.push(`/new-order/${user?.id}`)}
                >
                    <Icon
                        type={TypesIcon.ADD}
                        width="20px"
                        height="20px"
                        fill={colors.white}
                    />
                    {t('buttons.newOrder')}

                </StyledButtonDrawer>
                { !(user?.status === 'mobile')
                    ? (
                        <StyledButtonDrawer
                            typeButton={TypesButton.OUTLINE_BORDER}
                            onClick={() => history.push(`/update-client/${user?.id}`)}
                        >
                            <StyledEditIcon>
                                <Icon
                                    type={TypesIcon.EDIT}
                                    width="20px"
                                    height="20px"
                                    fill={colors.blue}
                                />
                            </StyledEditIcon>
                            {t('buttons.Edit')}

                        </StyledButtonDrawer>
                    ) : null}
            </StyledClient>
        </StyledWrapper>
    );
};
