import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DisabledDates } from './DatePickerColumn';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { useDatePicker } from '../../hooks/useDatepicker';
import { useMonth } from '../../hooks/useMonth';
import { DatePickerHeader } from './DatePickerHeader';
import { DatePickerCalendar } from './DatePickerCalendar';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';

interface DatePickerProps {
  onClose: () => void;
  onSuccess: (value: string) => void;
  name: string;
  activeDate?: string;
  disabledDates?: DisabledDates;
  disableUntilСurrentDate?: boolean;
}

const StyledDatePicker = styled.div`
  background: ${colors.white};
  border: 2px solid ${colors.blue_30};
  border-radius: 8px;
  box-sizing: border-box;
`;

const StyledPickerWrapper = styled.div`
  padding: ${units(7)} ${units(7)} ${units(6)} ${units(7)};
`;

export const DatePicker = ({
    onClose,
    onSuccess,
    name,
    disabledDates,
    activeDate,
    disableUntilСurrentDate,
}: DatePickerProps) => {
    const { t } = useTranslation('datePicker');

    const { date: disabledDate } = disabledDates || {};

    const {
        calendarRows,
        selectedDate,
        formattedToday,
        daysShort,
        selectedDay,
        setSelectedDay,
        getNextMonth,
        getPrevMonth,
    } = useDatePicker({ currentDay: activeDate || disabledDate });

    const date = {
        month: selectedDate.month(),
        year: selectedDate.year(),
    };

    const { monthName } = useMonth({ activeDate: date });

    const handleSuccess = useCallback(() => {
        if (onSuccess) {
            onSuccess(selectedDay);
        }
    }, [name, onSuccess, selectedDay]);

    return (
        <StyledDatePicker>
            <StyledPickerWrapper>
                <DatePickerHeader
                    selectedDate={selectedDate}
                    monthName={monthName}
                    getNextMonth={getNextMonth}
                    getPrevMonth={getPrevMonth}
                />
                <DatePickerCalendar
                    formattedToday={formattedToday}
                    selectedDay={selectedDay}
                    daysShort={daysShort}
                    calendarRows={calendarRows}
                    setSelectedDay={setSelectedDay}
                    disabledDates={disabledDates}
                    disableUntilСurrentDate={disableUntilСurrentDate}
                    onSuccess={onSuccess}
                />
            </StyledPickerWrapper>
        </StyledDatePicker>
    );
};
