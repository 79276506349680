import { ControlProps } from '../../../../types/Form/ControlProps';
import { TypesFields } from '../../../../types/TypesFields';
import { FiltersMenuProps } from '../../../../types/Filters/FiltersMenuProps';

export const getFieldsOrderSelect = (optionPrice: FiltersMenuProps[]): ControlProps[] => ([
    {
        key: 'selectPrice',
        type: TypesFields.SELECT_MI,
        name: 'selectPrice',
        className: 'selectPrice',
        option: optionPrice,
        placeholder: 'Цена',
        IsIssuingOrders: true,
    },
/*    {
        key: 'selectAvailability',
        type: TypesFields.SELECT_MI,
        name: 'selectAvailability',
        className: 'selectAvailability',
        option: optionAvailability,
        placeholder: 'Наличие',
        IsIssuingOrders: true,
    }, */
]);
