import { InputState } from '../../constants/InputState';
import { colors } from '../../constants/Colors';

const colorByType: Record<string, string> = {
    [InputState.IDLE]: colors.dark,
    [InputState.ACTIVE]: colors.dark,
    [InputState.WARNING]: colors.red,
};

export const applyColor = ({ error }: { error?: string }) => (
    error ? `color: ${colorByType[InputState.WARNING]}` : `color: ${colorByType[InputState.IDLE]}`
);
