import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as categoryApi from '../../../api/category';
import { CategoriesModal } from '../../../types/Models/CategoriesModal';

export const GET_CATEGORY = 'category/getCategory';

export const getCategory = createAsyncThunk<CategoriesModal[]>(
    GET_CATEGORY,
    createRequest({
        type: GET_CATEGORY,
        loader: () => categoryApi.getCategory(),
        showFailNotification: false,
    }),
);
