import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as userApi from '../../../api/users';
import { UsersSearchByPhoneParams } from '../../../types/Endpoints/UsersSearchByPhone';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addEmptyArray } from '../setEmtyArray';

export const GET_USER_PHONE = 'user/getUserByPhone';

const onFail = (): Thunk => async dispatch => {
    dispatch(addEmptyArray());
};

export const getUserByPhone = createAsyncThunk(
    GET_USER_PHONE,
    createRequest({
        type: GET_USER_PHONE,
        loader: (params: UsersSearchByPhoneParams) => userApi.getUserByPhone(params),
        onFail,
        showFailNotification: false,
    }),
);
