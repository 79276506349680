import { createReducer } from '@reduxjs/toolkit';
import { addModal, removeModal } from '../../actions/modal';
import { Modal } from '../../types/ModalTypes/Modal';

type ModalsState = Record<string, Modal>;

export const modals = createReducer<ModalsState>({}, builder => {
    builder.addCase(addModal, (state, { payload }) => ({
        ...state,
        [payload.id]: { ...payload },
    }));
    builder.addCase(removeModal, () => ({ }));
});
