import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as userApi from '../../../api/user';
import { UsersModal } from '../../../types/Models/UserContract';

export const GET_USER = 'user/getCurrentUser';

export const getCurrentUser = createAsyncThunk<UsersModal>(
    GET_USER,
    createRequest({
        type: GET_USER,
        loader: () => userApi.getCurrentUser(),
        showFailNotification: false,
    }),
);
