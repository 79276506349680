import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { removeModal } from '../../actions/modal';
import { Button } from '../Button';
import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { Modal } from '../../types/ModalTypes/Modal';

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: ${units(6)};
`;

const StyledMainButton = styled(Button)`
  width: 84px;
  :not(:last-child) {
    margin-right: ${units(4)};
  }
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${units(6)};
  width: 100%;
`;

const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${units(162)};
  max-height: 90vh;
  width: 489px;

  background-color: ${colors.white};

  border: 1px solid ${colors.gray};
  border-radius: ${units(8)};
`;

const StyledDescriptionWrapper = styled.div`
  display: flex;
  padding: ${units(6)};
`;

const StyledContent = styled.div`
    width: 377px;
    margin-left: 56px;
    margin-right: 56px;
    margin-top: 48px;
    margin-bottom: 32px;
`;

export const RemoveDialog = ({
    data, id, onClose, title, description, onSuccess,
}: Modal) => {
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
        dispatch(removeModal(id));
    }, [dispatch, id, onClose]);

    const handleSuccess = useCallback(() => {
        if (onSuccess) {
            onSuccess();
        }
        if (onClose) {
            onClose();
        }
        dispatch(removeModal(id));
    }, [dispatch, id, onClose]);
    return (
        <StyledRoot>
            <StyledContent>
                <StyledTitleWrapper>
                    <StyledTitle>
                        {title}
                    </StyledTitle>
                </StyledTitleWrapper>
                <StyledDescriptionWrapper>
                    {description}
                </StyledDescriptionWrapper>
                <StyledButtonWrapper>
                    <StyledMainButton
                        typeButton={TypesButton.OUTLINE_BORDER_GREY}
                        onClick={handleClose}
                    >
                        Отмена
                    </StyledMainButton>
                    <StyledMainButton
                        typeButton={TypesButton.FILL}
                        onClick={handleSuccess}
                    >
                        Да
                    </StyledMainButton>
                </StyledButtonWrapper>
            </StyledContent>
        </StyledRoot>

    );
};
