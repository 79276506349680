import React, { useCallback } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { routes } from '../constants/RouterPath';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { Login } from './Login';
import { Orders } from './Orders';
import { Order } from './Order';
import { UserContent } from '../hocs/UserContent';
import { TechnicalPage } from './TechnicalPage';
import { Product } from './Product';
import { Client } from './Client';
import { Catalog } from './Catalog';
import { NewOrder } from './NewOrder';
import { NewClient } from './NewClient';
import { RootRouter } from './RootRouter';
import CustomPrompt from '../components/CustomPrompt';

export const Router = () => {
    useScrollToTop();

    const userConfirmation = useCallback((message: any, callback: (arg: any) => void) => {
        const node = document.getElementById('custom-prompt');
        const cleanUp = (answer: any) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ReactDOM.unmountComponentAtNode(node);
            callback(answer);
        };

        ReactDOM.render(<CustomPrompt cleanUp={cleanUp} />, node);
    }, []);

    return (
        <BrowserRouter getUserConfirmation={userConfirmation}>
            <Switch>
                <Route path={routes.orders}>
                    <UserContent isPadding>
                        <Orders />
                    </UserContent>
                </Route>

                <Route path={routes.order}>
                    <UserContent isPadding={false}>
                        <Order />
                    </UserContent>
                </Route>

                <Route path={routes.productCardId}>
                    <UserContent isPadding>
                        <Product />
                    </UserContent>
                </Route>

                <Route path={routes.productCard}>
                    <UserContent isPadding>
                        <Product />
                    </UserContent>
                </Route>

                <Route path={routes.clientCard}>
                    <UserContent isPadding>
                        <Client />
                    </UserContent>
                </Route>

                <Route path={routes.catalog}>
                    <UserContent isPadding>
                        <Catalog />
                    </UserContent>
                </Route>

                <Route path={routes.newOrderId}>
                    <UserContent isPadding={false}>
                        <Order />
                    </UserContent>
                </Route>

                <Route path={routes.newOrder}>
                    <UserContent isPadding>
                        <NewOrder />
                    </UserContent>
                </Route>

                <Route path={routes.newClientId}>
                    <UserContent isPadding={false}>
                        <NewClient />
                    </UserContent>
                </Route>

                <Route path={routes.newClient}>
                    <UserContent isPadding={false}>
                        <NewClient />
                    </UserContent>
                </Route>

                <Route path={routes.login}>
                    <Login />
                </Route>

                <Route exact path="/">
                    <Login />
                </Route>

                <Route path={routes.technicalPage}>
                    <TechnicalPage />
                </Route>

                <Route>
                    <RootRouter />
                </Route>
            </Switch>
        </BrowserRouter>
    );
};
