import { css } from 'styled-components';
import { colors } from '../../constants/Colors';
import { ButtonProps } from './index';

export const applyDisabledColor = ({ disabled }: Pick<ButtonProps, 'disabled'>) => (
    disabled && (css`
          color: ${colors.gray2};
          background: ${colors.background}
    `)
);
