import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as userApi from '../../../api/users';
import { Thunk } from '../../../helpers/redux/Thunk';
import { UsersCardContractParams } from '../../../types/Endpoints/UsersCardContract';

export const DELETE_USER = 'user/deleteUser';

const onSuccess = (): Thunk => async dispatch => {
    window.location.href = '/orders';
};

export const deleteUser = createAsyncThunk(
    DELETE_USER,
    createRequest({
        type: DELETE_USER,
        loader: (params: UsersCardContractParams) => userApi.deleteUser(params),
        onSuccess,
        showFailNotification: false,
    }),
);
