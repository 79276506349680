import { request } from '../../helpers/api/request';
import { OrderCardContractParams } from '../../types/Endpoints/OrderCardContract';

export const deleteOrder = async (params: OrderCardContractParams) => {
    const { data } = await request({
        url: `orders/${params.id}`,
        method: 'DELETE',
    });
    return data;
};
