import { request } from '../../helpers/api/request';
import { NewOrdersContract, NewOrdersContractParams } from '../../types/Endpoints/NewOrdersContract';

export const getNewOrders = async (params: NewOrdersContractParams) => {
    const { data } = await request<NewOrdersContract>({
        url: 'orders/fetch',
        params,
    });

    return data;
};
