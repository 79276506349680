import { createReducer } from '@reduxjs/toolkit';
import { UsersModal } from '../../types/Models/UsersModal';
import { getUser, removeUser, deleteUser } from '../../actions/users';

interface Users {
    userCard?: UsersModal | undefined;
}

export const userCard = createReducer<Users>({}, builder => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => ({
        ...state,
        userCard: payload,
    }));
    builder.addCase(removeUser, (state, { payload }) => ({ }));
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => ({
        ...state,
    }));
});
