import { createReducer, current } from '@reduxjs/toolkit';
import { getAdditionalProduct, getProduct } from '../../actions/products';
import { ProductTableModel } from '../../types/Models/ProductTableModel';
import { addEmptyArray } from '../../actions/products/setEmtyArray';

interface Product {
    product?: ProductTableModel;
}

export const product = createReducer<Product>({}, builder => {
    builder.addCase(getProduct.fulfilled, (state, { payload }) => ({
        ...state,
        product: payload,
    }));
    builder.addCase(getAdditionalProduct.fulfilled, (state, { payload }) => {
        const stateProducts = state.product?.products || [];
        return {
            ...state,
            product: {
                products: [
                    ...stateProducts,
                    ...payload.products,
                ],
                end: payload.end,
            },
        };
    });
    builder.addCase(addEmptyArray, (state, { payload }) => ({
        ...state,
        product: payload,
    }));
});
