import React, { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { applySnackbarColor } from './applyColorByType';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { applySnackbarAnimation } from './applyAnimationByType';
import { removeNotification } from '../../actions/notifications';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { formatTime } from '../../helpers/date';

export interface SnackbarProps {
    autoHideDuration?: number;
    snackbarTitle: ReactNode;
    snackbarDescription?: ReactNode;
    snackbarId: number;
    snackbarOrderId?: number;
    snackbarMistake?: boolean;
    snackbarType: TypesSnackbars;
    animationTime?: number | string;
    isUnclosed?: boolean;
}

const StyledSnackbarContainer = styled.div<Pick<SnackbarProps, 'animationTime' | 'isUnclosed' | 'snackbarType'>>`
  position: relative;

  display: flex;
  align-items: center;
  width: ${units(160)};
  padding: ${units(3)} ${units(9)} ${units(3)} ${units(6)};
  margin-bottom: ${units(3)};

  background: ${colors.dark};
  border-radius: ${units(4)};
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    flex-shrink: 0;
  }

  @media (max-width: ${BreakPoints.SM}) {
    width: 100%;
  }

  ${applySnackbarAnimation};
`;

const StyledButton = styled.button`
  position: absolute;
  top: ${units(3)};
  right: ${units(3)};
  padding: 0;

  text-align: center;

  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const StyledMessage = styled.div`
  padding-left: ${units(6)};
  flex-shrink: 1;
`;

const StyledMessageTitle = styled.p`
  color: ${colors.gray2};
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
`;

const StyledMessageDescription = styled.p`
  margin: ${units(1)} 0 ${units(8)};

  color: ${colors.white};
  line-height: 18px;
`;

const StyledHeaderMessage = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const Snackbar = ({
    autoHideDuration = 7000,
    snackbarTitle,
    snackbarDescription,
    snackbarOrderId,
    snackbarId,
    snackbarType,
    isUnclosed,
    snackbarMistake = false,
}: SnackbarProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const animationTime = `${(autoHideDuration - 450) / 1000}`;

    useEffect(() => {
        if (!isUnclosed) {
            setTimeout(() => dispatch(removeNotification(snackbarId)), autoHideDuration);
        }
    }, [autoHideDuration, dispatch, isUnclosed, snackbarId, snackbarType]);

    const handleClose = () => {
        dispatch(removeNotification(snackbarId));
    };

    return (
        <StyledSnackbarContainer
            animationTime={animationTime}
            snackbarType={snackbarType}
            isUnclosed={isUnclosed}
        >
            <StyledMessage>
                <StyledHeaderMessage>
                    {!snackbarMistake
                        ? (
                            <StyledMessageTitle onClick={() => history.push(`/order/${snackbarOrderId}`)}>
                                {formatTime(new Date())}&nbsp;&nbsp;&nbsp;&nbsp; Заказ №{snackbarOrderId}
                            </StyledMessageTitle>
                        )
                        : (
                            <StyledMessageTitle>
                                {formatTime(new Date())}&nbsp;&nbsp;&nbsp;&nbsp; Что-то пошло не так
                            </StyledMessageTitle>
                        )}
                    <StyledButton onClick={handleClose}>
                        <Icon
                            type={TypesIcon.CLOSE}
                            fill={colors.gray2}
                        />
                    </StyledButton>
                </StyledHeaderMessage>
                <StyledMessageDescription>
                    {snackbarDescription}
                </StyledMessageDescription>
            </StyledMessage>

        </StyledSnackbarContainer>
    );
};
