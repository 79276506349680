import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';

const colorBgByType: Record<string, string> = {
    [TypesButton.FILL]: colors.blue,
    [TypesButton.OUTLINE]: colors.white,
    [TypesButton.OUTLINE_BORDER]: colors.white,
    [TypesButton.OUTLINE_BORDER_GREY]: colors.white,
};

export const applyBgColor = ({ typeButton }: {typeButton: TypesButton}) => `background: ${colorBgByType[typeButton]}`;
