import { createReducer } from '@reduxjs/toolkit';
import { getTimespans } from '../../actions/timespans';
import { addEmptyTimespans } from '../../actions/timespans/setEmtyArray';

interface TimespansList {
    timespans?: string[] | undefined;
}

export const timespans = createReducer<TimespansList>({}, builder => {
    builder.addCase(getTimespans.fulfilled, (state, { payload }) => ({
        ...state,
        timespans: payload,
    }));
    builder.addCase(addEmptyTimespans, state => ({
        timespans: [],
    }));
});
