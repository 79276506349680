import { useLayoutEffect } from 'react';

export const useLockBodyScroll = () => {
    useLayoutEffect(() => {
        const overflowValue = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = overflowValue;
        };
    }, []);
};
