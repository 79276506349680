import login from './login.json';
import sidebar from './sidebar.json';
import orders from './orders.json';
import filters from './filter.json';
import table from './table.json';
import datePicker from './datePicker.json';
import client from './client.json';
import common from './common.json';
import products from './products.json';

export const RU = {
    login,
    sidebar,
    orders,
    filters,
    table,
    datePicker,
    client,
    common,
    products,
};
