import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as categoryApi from '../../../api/category';

export const GET_CATEGORY_FILTERS = 'category/getCategoryFilters';

export const getCategoryFilters = createAsyncThunk(
    GET_CATEGORY_FILTERS,
    createRequest({
        type: GET_CATEGORY_FILTERS,
        loader: ({ id, isModal }:{id: number, isModal?:boolean}) => categoryApi.getFilters(id, isModal),
        showFailNotification: false,
    }),
);
