import React, { ComponentType } from 'react';
import styled from 'styled-components';
import { ModalMessage } from '../../../types/ModalTypes/Modal';
import { units } from '../../../helpers/styles/units';
import { typography } from '../../../helpers/styles/typography';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  
  margin-bottom: ${units(10)};
`;

const StyledTitle = styled.div`
  ${typography(15)};
  font-weight: 600;

  padding-top: ${units(6)};

  margin-bottom: ${units(2)};
`;

const StyledDescription = styled.div`
  ${typography(5)};
  font-weight: 600;
  text-align: center;
  opacity: 0.7;
`;

interface DefaultModalContentProps {
  title?: ModalMessage['title'];
  description?: ModalMessage['description'];
  Icon?: ComponentType;
}

export const DefaultModalContent = ({
    title,
    description,
    Icon,
}: DefaultModalContentProps) => (
    <StyledRoot>
        {Icon && <Icon />}
        {title && <StyledTitle>{title}</StyledTitle>}
        {description && <StyledDescription>{description}</StyledDescription>}
    </StyledRoot>
);
