import { createReducer } from '@reduxjs/toolkit';
import {
    getOrders, createOrders, deleteOrder, getAdditionalOrders,
} from '../../actions/orders';
import { OrderTableModel } from '../../types/Models/OrderModel';
import { addEmptyArray } from '../../actions/orders/setEmtyArray';

interface Orders {
    orders?: OrderTableModel | undefined;
}

export const orders = createReducer<Orders>({}, builder => {
    builder.addCase(getOrders.fulfilled, (state, { payload }) => ({
        ...state,
        orders: payload,
    }));
    builder.addCase(getAdditionalOrders.fulfilled, (state, { payload }) => {
        const stateOrders = state.orders?.orders || [];
        return {
            ...state,
            orders: {
                orders: [
                    ...stateOrders,
                    ...payload.orders,
                ],
                end: payload.end,
            },
        };
    });
    builder.addCase(createOrders.fulfilled, state => ({
        ...state,
    }));
    builder.addCase(addEmptyArray, (state, { payload }) => ({
        ...state,
        orders: payload,
    }));
    builder.addCase(deleteOrder.fulfilled, state => ({
        ...state,
    }));
});
