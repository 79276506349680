import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as userApi from '../../../api/users';
import { UsersCardContractParams } from '../../../types/Endpoints/UsersCardContract';

export const GET_USER = 'user/getUser';

export const getUser = createAsyncThunk(
    GET_USER,
    createRequest({
        type: GET_USER,
        loader: (params: UsersCardContractParams) => userApi.getUserCard(params),
        showFailNotification: false,
    }),
);
