import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DrawerWrapper } from '../../../components/DrawerWrapper';
import { units } from '../../../helpers/styles/units';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { colors } from '../../../constants/Colors';
import { useTypeSelector } from '../../../store';
import { selectShop } from '../../../selectors/shop';
import { getShop } from '../../../actions/shop';
import { IShopList } from '../../../types/Models/ShopListModel';
import { addEmptyArrayShopList } from '../../../actions/shop/setEmtyArray';

interface IDrawerFilters{
    anchorEl: null | HTMLElement,
    handleClose(): void,
    isForm?: boolean,
    productId: number;
}

const FormWrapper = styled.div`
  padding: ${units(10)} ${units(10)} 0;
  max-height: 90vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 6px;               /* ширина всей полосы прокрутки */
  }

  &::-webkit-scrollbar-track {
    opacity: 0;        /* цвет зоны отслеживания */
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray2};
    border-radius: 10px;     /* округлось бегунка */
  }
`;

const StyledStoreInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: ${units(165.5)};
  background: #FFFFFF;
  box-shadow: 0 0 ${units(4)} #EEF0F9;
  border-radius: ${units(6)};
  margin-bottom: ${units(4)};
  font-size: ${units(7)};
`;

const StyledLocation = styled.div`
   display: flex;
  
   svg{
     width: ${units(18.5)};
     height: ${units(13)};
   }
`;

const WrapperStyled = styled.div`
    display: flex;
    justify-content: space-between;
    margin: ${units(7)} ${units(5.5)} ${units(1.5)} ${units(5)};
    font-size: ${units(7)};
`;

const StyledPhoneNumber = styled.div`
  margin-left: ${units(22.5)};
  font-size: ${units(7)};
`;

const StyledAmount = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${units(5)} ${units(6)} ${units(6)} ${units(22.5)};
  font-size: ${units(7)};
  
  & :first-child {
    font-weight: 500;
  }
`;

export const DrawerProduct: React.FC<IDrawerFilters> = ({
    productId, anchorEl, handleClose, isForm,
}) => {
    const { t } = useTranslation('products');
    const shops = useTypeSelector(selectShop);
    const dispatch = useDispatch();

    const [shopsTable, setShopsTable] = useState<IShopList[]>([]);
    const [quantity, setQuantity] = useState<number>(0);

    useEffect(() => {
        dispatch(getShop({ id: productId }));

        return () => {
            dispatch(addEmptyArrayShopList());
        };
    }, [productId]);

    useEffect(() => {
        if (shops?.shops) {
            let quantityCounter = 0;
            const filteredDoubleShops = shops.shops.filter((shop, index, array) => {
                if (index === 0 || shop.id !== array[index - 1].id) {
                    quantityCounter += shop.quantity;
                    return true;
                }

                return false;
            });

            setQuantity(quantityCounter);
            setShopsTable(filteredDoubleShops);
        }
    }, [shops]);

    return (
        <DrawerWrapper
            title={t('drawer.title')}
            subTitle={`В наличии ${quantity} товаров в ${shopsTable.length} магазинах`}
            isConfirmationButtons={false}
            isForm={isForm}
            anchorEl={anchorEl}
            handleClose={handleClose}
        >
            <FormWrapper>
                { shopsTable.map(shop => (
                    <StyledStoreInformation key={shop.id}>
                        <WrapperStyled>
                            <StyledLocation>
                                <Icon
                                    type={TypesIcon.LOCATION}
                                    width="20px"
                                    height="20px"
                                    fill={colors.dark}
                                />
                                <span>г.{shop.city}, {shop.address}</span>
                            </StyledLocation>
                        </WrapperStyled>
                        <StyledPhoneNumber>
                            {shop?.phones[0] || ''}
                        </StyledPhoneNumber>
                        <StyledAmount>
                            <span> {shop.price} руб.</span>
                            <span> {shop.available} шт.</span>
                        </StyledAmount>
                    </StyledStoreInformation>
                ))}
            </FormWrapper>
        </DrawerWrapper>
    );
};
