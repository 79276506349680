import { createReducer } from '@reduxjs/toolkit';
import { getAllShop } from '../../actions/shop';
import { IShopList } from '../../types/Models/ShopListModel';

interface AllShopsList {
    allShopsList?: IShopList[] | undefined;
}

export const allShops = createReducer<AllShopsList>({}, builder => {
    builder.addCase(getAllShop.fulfilled, (state, { payload }) => ({
        ...state,
        allShopsList: payload,
    }));
});
