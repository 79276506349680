import React, { useCallback } from 'react';
import styled from 'styled-components';
import { typography } from '../../../../helpers/styles/typography';
import { colors } from '../../../../constants/Colors';
import { CheckGroupControlProps } from '../../../../types/Form/CheckGroupControlProps';
import { CheckboxGroup } from '../../../CheckElementGroup';
import { CheckOption } from '../../../../types/Form/CheckOption';

const StyledField = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const StyledErrorMessage = styled.span`
  position: absolute;
  
  font-weight: 600;
  color: ${colors.red};

  ${typography(0)};
`;

export const CheckGroupControl = ({
    checkOptions,
    setValue,
    value,
    error,
    prefix,
    name,
    isRowControl,
}: CheckGroupControlProps) => {
    const handleChange = useCallback((field: string, fieldValue?: boolean, shouldValidate?: boolean) => {
        if (setValue) {
            if (!fieldValue && value) {
                const { [field]: fieldName, ...newValue } = value;
                setValue(name, {
                    ...newValue,
                }, shouldValidate);
            } else {
                setValue(name, {
                    ...value,
                    [field]: fieldValue,
                }, shouldValidate);
            }
        }
    }, [name, setValue, value]);
    let array: any;

    const handleClick = (state: boolean) => {
        checkOptions?.forEach((({ key }: CheckOption) => {
            array = {
                ...array,
                [key]: true,
            };
        }));
        if (setValue && state) {
            setValue(name, {
                ...value,
                ...array,
            }, false);
        }
        if (setValue && !state) {
            setValue(name, {}, false);
        }
    };

    return (
        <StyledField>
            <CheckboxGroup
                value={value}
                controls={checkOptions}
                onChange={handleChange}
                prefix={prefix}
                isRow={isRowControl}
                handleClick={handleClick}
            />
            {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
        </StyledField>
    );
};
