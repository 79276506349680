import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const selectRoot = ({ category }: RootState) => category;

export const selectCategory = createSelector(
    selectRoot,
    ({ category }) => category,
);

export const selectCategoryFilters = createSelector(
    selectRoot,
    ({ filters }) => filters,
);
