import i18n from '../../i18n';

const t = i18n.getFixedT(null, 'common', 'calendar');

export const DAYS_SHORT = () => (
    [
        t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday'),
    ]);

export const DAYS_IN_WEEK = [0, 1, 2, 3, 4, 5, 6];

export const FORMAT_DATE = 'YYYY-MM-DD';

export const FORMAT_MONTH_DATE = 'YYYY-MM';

export const HOLIDAYS = ['01.01', '02.01', '07.01', '08.03', '01.05', '09.05', '03.07', '07.10', '25.12'];
