import * as React from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled as MaterialStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { colors } from '../../../constants/Colors';
import { Label } from '../../../components/Label';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { TableWrapper } from '../../../components/TableWrapper';
import { getTableHead } from './getTableHead';
import { useTypeSelector } from '../../../store';
import { selectOrders } from '../../../selectors/orders';
import { formatOrderDay } from '../../../helpers/date';
import { getDayWeek } from '../../../helpers/date/formatDate';
import { units } from '../../../helpers/styles/units';

const StyledTableCell = MaterialStyled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        borderBottom: 0,
        fontFamily: '\'TTNormsPro\', sans-serif',
        fontSize: '14px',
        lineHeight: '20px',
        color: colors.dark,
        letterSpacing: '0.4px',
        '&:first-of-type': {
            position: 'relative',
            left: '16px',
        },
    },
}));

const WrapperBottomTable = styled.div`
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    & > svg {
      position: relative;
      bottom: -5px;
    }
`;

const StyledTableNotFound = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

export const OrderTable = ({
    setLimitTable,
    limitTable,
    sortByDate,
    setSortByDate,
    onClickAddMoreOrders,
}: {
    sortByDate: string,
    setSortByDate: (state: string) => void,
    setLimitTable: (state: number) => void,
    limitTable: number,
    onClickAddMoreOrders: () => void,
}) => {
    const { t } = useTranslation('table');
    const { t: tOrder } = useTranslation('orders');
    const history = useHistory();
    const orders = useTypeSelector(selectOrders);
    const dispatch = useDispatch();

    const ordersTable = orders?.orders || [];
    const ordersEnd = orders?.end;

    const onClickLabel = () => {
        setLimitTable(limitTable + 5);
    };

    const onClickSortByDate = () => {
        if (sortByDate === '') setSortByDate('asc');
        if (sortByDate === 'asc') setSortByDate('desc');
        if (sortByDate === 'desc') setSortByDate('');
    };

    return (
        <TableWrapper onClickSort={onClickSortByDate} tableHeadRow={getTableHead(t)}>
            {ordersTable.length > 0
                ? (
                    <>
                        {ordersTable.map(order => (
                            <TableRow
                                key={order.order_id}
                                onClick={() => history.push(`/order/${order.order_id}`)}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        boxShadow: `0px 0px ${units(6)} rgba(130, 141, 171, 0.16)`,
                                        borderRadius: `${units(15)}`,
                                    },
                                }}
                            >
                                <StyledTableCell align="left">{order.order_id} </StyledTableCell>
                                <StyledTableCell align="left">
                                    { order.date_pickup
                                        ? `${formatOrderDay(order.date_pickup)} (${getDayWeek(order.date_pickup)})`
                                        : ''}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {order.phone?.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3 $4 $5')}
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: 500 }} align="left">{Number(order.price).toFixed(2) || 0} руб.</StyledTableCell>
                                <StyledTableCell align="left">
                                    <Label color={order.status}>{ tOrder(`status.${order.status}`)}</Label>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                        { !ordersEnd
                            ? (
                                <StyledTableCell
                                    sx={{ borderTop: `1px solid ${colors.gray3}` }}
                                    colSpan={6}
                                    align="center"
                                    onClick={() => onClickAddMoreOrders()}
                                >
                                    <WrapperBottomTable>
                                        Больше заказов
                                        <Icon
                                            type={TypesIcon.ARROW_DROP_DOWN}
                                            width="20px"
                                            height="20px"
                                            fill={colors.dark}
                                        />
                                    </WrapperBottomTable>
                                </StyledTableCell>
                            ) : null}
                    </>
                )
                : (
                    <TableCell
                        sx={{
                            borderTop: `1px solid ${colors.gray3}`,
                            position: 'static',
                            padding: '48px 0',
                        }}
                        colSpan={6}
                        align="center"
                    >
                        <StyledTableNotFound>
                            {t('sentences.ordersNotFound')}
                        </StyledTableNotFound>
                    </TableCell>
                )}
        </TableWrapper>
    );
};
