import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import * as React from 'react';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { CHARACTERS_IN_INPUT_PHONE } from '../../helpers/validation';

interface ISearchInput {
    placeholder: string,
    searchOrder: string,
    setSearchOrder: (state: string) => void
    allSymbols?: boolean
}

export const SearchInput = ({
    placeholder,
    searchOrder,
    setSearchOrder,
    allSymbols = false,
}: ISearchInput) => (
    <Paper
        component="form"
        sx={{
            p: '2px 4px', display: 'flex', alignItems: 'center', width: 400,
        }}
    >
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <Icon
                type={TypesIcon.SEARCH}
                width="20px"
                height="20px"
                fill={searchOrder.length > 0 ? colors.dark : colors.gray}
            />
        </IconButton>
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={placeholder}
            inputProps={{ 'aria-label': 'Search' }}
            onChange={event => {
                if (!allSymbols) {
                    if (event.target.value === '' || CHARACTERS_IN_INPUT_PHONE.test(event.target.value)) {
                        return setSearchOrder(event.target.value);
                    }
                } else return setSearchOrder(event.target.value);
            }}
            onKeyDown={e => {
                if (e.keyCode === 13) {
                    e.preventDefault();
                }
            }}
            value={searchOrder}
        />
        {searchOrder.length > 0
            ? (
                <IconButton
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                    onClick={() => { setSearchOrder(''); }}
                >
                    <Icon
                        type={TypesIcon.CANCEL_CIRCLE_GREY}
                        width="20px"
                        height="20px"
                        fill={colors.gray}
                        viewBox="0 0 20 20"
                    />
                </IconButton>
            ) : null}
    </Paper>
);
