import { useEffect, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled as MaterialStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { colors } from '../../../constants/Colors';
import { Label } from '../../../components/Label';
import { TableWrapper } from '../../../components/TableWrapper';
import { getTableHead } from './getTableHead';
import { units } from '../../../helpers/styles/units';
import { getUserOrders } from '../../../actions/orders';
import { selectUserOrders } from '../../../selectors/userOrders';
import { useTypeSelector } from '../../../store';
import { formatOrderDay } from '../../../helpers/date';
import { getDayWeek } from '../../../helpers/date/formatDate';

const StyledTableCell = MaterialStyled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        borderBottom: 0,
        fontFamily: '\'TTNormsPro\', sans-serif',
        fontSize: '14px',
        lineHeight: '20px',
        color: colors.dark,
        letterSpacing: '0.4px',
        '&:first-of-type': {
            position: 'relative',
            left: '16px',
        },
    },
}));

const WrapperBottomTable = styled.div`
    font-weight: 500;
    line-height: ${units(8)};
    cursor: pointer;
    & > svg {
      position: relative;
      bottom: -5px;
    }
`;

const StyledTableNotFound = styled.div`
  font-weight: 400;
  font-size: ${units(7)};
  line-height: ${units(12)};
`;

const StyledTableTitle = styled.div`
  font-weight: 500;
  font-size: ${units(10)};
  line-height: ${units(14)};
  margin-top: ${units(12)};
  margin-bottom: ${units(12)};
`;

export const TableLastOrdersOfCustomer = () => {
    const { t } = useTranslation('table');
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string}>();
    const orders = useTypeSelector(selectUserOrders);
    const { t: tOrder } = useTranslation('orders');
    const [sortByDate, setSortByDate] = useState<string>('');
    const history = useHistory();

    useEffect(() => {
        dispatch(getUserOrders({
            id: +id,
            date_pickup_sort: sortByDate || undefined,
        }));
    }, [sortByDate]);

    const onClickSortByDate = () => {
        if (sortByDate === '') setSortByDate('asc');
        if (sortByDate === 'asc') setSortByDate('desc');
        if (sortByDate === 'desc') setSortByDate('');
    };

    return (
        <>
            <StyledTableTitle>{t('sentences.historyOfOrders')}</StyledTableTitle>
            <TableWrapper onClickSort={onClickSortByDate} tableHeadRow={getTableHead(t)}>
                {orders && orders.length > 0
                    ? (
                        <>
                            {orders.map(order => (
                                <TableRow
                                    key={order.order_id}
                                    onClick={() => history.push(`/order/${order.order_id}`)}
                                    sx={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <StyledTableCell align="left">{order.order_id} </StyledTableCell>
                                    <StyledTableCell align="left">{
                                        order.date_pickup
                                            ? `${formatOrderDay(order.date_pickup)} (${getDayWeek(order.date_pickup)})`
                                            : ''
                                    }
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {order.user?.phone?.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3 $4 $5')}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ fontWeight: 500 }} align="left">
                                        {
                                            order.price ? Number(order.price).toFixed(2) : 0
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{order.shop?.address}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Label color={order.status}>{ tOrder(`status.${order.status}`)}</Label>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </>
                    )
                    : (
                        <TableCell
                            sx={{
                                borderTop: `1px solid ${colors.gray3}`,
                                position: 'static',
                                padding: '48px 0',
                            }}
                            colSpan={6}
                            align="center"
                        >
                            <StyledTableNotFound>
                                {t('sentences.ordersNotFound')}
                            </StyledTableNotFound>
                        </TableCell>
                    )}
            </TableWrapper>
        </>
    );
};
