import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as ordersApi from '../../../api/orders';
import { NewOrdersContractParams } from '../../../types/Endpoints/NewOrdersContract';

export const GET_NEW_ORDERS = 'orders/getNewOrders';

export const getNewOrders = createAsyncThunk(
    GET_NEW_ORDERS,
    createRequest({
        type: GET_NEW_ORDERS,
        loader: (params: NewOrdersContractParams) => ordersApi.getNewOrders(params),
        showFailNotification: false,
    }),
);
