import React from 'react';
import { routes } from '../constants/RouterPath';
import { RedirectWithQuery } from '../components/RedirectWithQuery';

export const RootRouter = () => {
    switch (true) {
        default:
            return <RedirectWithQuery to={routes.orders} />;
    }
};
