import { TFunction } from 'i18next';
import { ControlProps } from '../../../../types/Form/ControlProps';
import { TypesFields } from '../../../../types/TypesFields';
import { FiltersMenuProps } from '../../../../types/Filters/FiltersMenuProps';
import { CategoryFilters } from '../../../../types/Models/CategoryFilters';
import { getCheckboxValues, getFieldsSelectCategory, getSelectValues } from './getFilters';

export const getFieldsDrawer = (
    t: TFunction,
    optionCategory: FiltersMenuProps[],
    filters?: CategoryFilters[],

): ControlProps[] => {
    const restFields = filters?.map(item => {
        if (item.parameter === 'gift_box') {
            return {
                key: item.parameter,
                type: TypesFields.SELECT_MI,
                name: item.parameter,
                option: getSelectValues(item.data),
                sizeLimitation: false,
                title: item.name,
                IsIssuingOrders: true,
            };
        }

        if (item.type === 'range') {
            return {
                key: item.parameter,
                type: TypesFields.RANGE,
                title: item.name,
                name: item.parameter,
            };
        }

        return {
            type: TypesFields.CHECK_GROUP,
            name: item.parameter,
            checkOptions: getCheckboxValues(item.data, item.parameter),
            title: item.name,
            key: item.name,
        };
    }) || [];

    return ([
        {
            key: 'selectOptionCategory',
            type: TypesFields.SELECT_MI,
            name: 'selectOptionCategory',
            className: 'selectOptionCategory',
            option: optionCategory,
            sizeLimitation: false,
            title: 'Категории',
            IsIssuingOrders: true,
        },
        ...restFields,
        {
            key: 'divider',
            type: TypesFields.DIVIDER,
            name: 'divider',
        },

    ]);
};
