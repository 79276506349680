import { createAction } from '@reduxjs/toolkit';
import { SuccessOrderModel } from '../../types/Models/SuccessOrder';

export const ADD_SUCCESS_ORDER = 'ADD_SUCCESS_ORDER';

export const addNewSuccessOrder = createAction(
    ADD_SUCCESS_ORDER,
    ({
        id, active,
    }: SuccessOrderModel) => ({
        payload: {
            id, active,
        },
    }),
);
