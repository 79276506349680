import React, { useCallback } from 'react';
import styled from 'styled-components';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { Rows } from '../../../hooks/useDatepicker';
import { DisabledDates } from '../DatePickerColumn';
import { DatePickerRow } from '../DatePickerRow';

const StyledCalendar = styled.div`
  margin: 0 auto;
`;

const StyledDaysWeek = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${units(6)};
`;

const StyledNameWeekDay = styled.div`
  color: ${colors.gray};
  font-weight: 500;
  text-align: center;

  ${typography(5)}
`;

export interface DatePickerCalendar {
  calendarRows: { [key: string]: Rows[] };
  formattedToday: string;
  daysShort: string[];
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
  disabledDates?: DisabledDates;
  disableUntilСurrentDate?: boolean;
  onSuccess?: (value: string) => void;
}

export const DatePickerCalendar = ({
    calendarRows,
    formattedToday,
    daysShort,
    selectedDay,
    setSelectedDay,
    disabledDates,
    disableUntilСurrentDate,
    onSuccess,
}: DatePickerCalendar) => {
    const onDateClick = useCallback((value: string) => setSelectedDay(value), [setSelectedDay]);

    return (
        <StyledCalendar>
            <StyledDaysWeek>
                {daysShort.map(day => <StyledNameWeekDay key={day}>{day}</StyledNameWeekDay>)}
            </StyledDaysWeek>
            <div>
                {Object.values(calendarRows).map(cols => (
                    <DatePickerRow
                        cols={cols}
                        selectedDay={selectedDay}
                        formattedToday={formattedToday}
                        onClick={onDateClick}
                        key={Math.random()}
                        disabledDates={disabledDates}
                        disableUntilСurrentDate={disableUntilСurrentDate}
                        onSuccess={onSuccess}

                    />
                ))}
            </div>
        </StyledCalendar>
    );
};
