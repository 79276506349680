import { TFunction } from 'i18next';
import { ClientInformationProps } from '../../../types/Client/ClientInformationProps';
import { ProductModel } from '../../../types/Models/ProductTableModel';

export const getInformationFields = (t: TFunction, product: ProductModel | undefined): ClientInformationProps[] => ([
    {
        key: 'name',
        title: t('sentences.name'),
        value: product?.name ?? '',
    },
    {
        key: 'price',
        title: t('sentences.price'),
        // eslint-disable-next-line no-nested-ternary
        value: product?.price
            // eslint-disable-next-line no-nested-ternary
            ? typeof product.price === 'number'
                ? `${product.price} руб.`
                : product.price.min_price === product.price.max_price
                    ? `${product.price.max_price} руб.`
                    : `${product.price.min_price} - ${product.price.max_price} руб.`
            : '',
    },
    {
        key: 'iD',
        title: t('sentences.iD'),
        value: product?.product_id ? `${product?.product_id}` : '',
    },
    {
        key: 'category',
        title: t('sentences.category'),
        value: product?.category ? `${product?.category}` : '',
    },
    {
        key: 'sugar',
        title: t('sentences.sugar'),
        value: product?.wine_sugar_type ? `${product?.wine_sugar_type}` : '',
    },
    {
        key: 'alcoholContent',
        title: t('sentences.alcoholContent'),
        value: product?.alcohol_content ? `${product?.alcohol_content}%` : '',
    },
    {
        key: 'country',
        title: t('sentences.country'),
        value: product?.country ? `${product?.country}` : '',
    },
    {
        key: 'volume',
        title: t('sentences.volume'),
        value: product?.volume ? `${product?.volume}л` : '',
    },
    {
        key: 'manufacturer',
        title: t('sentences.manufacturer'),
        value: '',
    },
    {
        key: 'region',
        title: t('sentences.region'),
        value: '',
    },
    {
        key: 'grapeSort',
        title: t('sentences.grapeSort'),
        value: '',
    },
    {
        key: 'color',
        title: t('sentences.color'),
        value: '',
    },
    {
        key: 'aroma',
        title: t('sentences.aroma'),
        value: '',
    },
    {
        key: 'taste',
        title: t('sentences.taste'),
        value: '',
    }, {
        key: 'gastronomicCombination',
        title: t('sentences.gastronomicCombination'),
        value: '',
    }, {
        key: 'wayToDrink',
        title: t('sentences.wayToDrink'),
        value: '',
    }, {
        key: 'storageMethod',
        title: t('sentences.storageMethod'),
        value: '',
    }, {
        key: 'extendedDescription',
        title: t('sentences.extendedDescription'),
        value: '',
    },

]);
