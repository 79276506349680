import { TFunction } from 'i18next';
import { HeaderTableProps } from '../../../types/Table/HeaderTableProps';

export const getTableHead = (t: TFunction) : HeaderTableProps[] => ([
    {
        key: 'id',
        name: t('sentences.productId'),
        width: 50,
    },
    {
        key: 'goods',
        name: t('sentences.goods'),
        width: 240,
    },
    {
        key: 'availability',
        name: t('sentences.availability'),
        width: 69,
    },
    {
        key: 'price',
        name: t('sentences.price'),
        width: 90,
    },
    {
        key: 'number',
        name: t('sentences.number'),
        width: 40,
    },
    {
        key: 'quantity',
        name: t('sentences.productQuantity'),
        width: 65,
    },
    {
        key: 'positionCost',
        name: t('sentences.positionCost'),
        width: 65,
    },
    {
        key: 'deleteButton',
        name: '',
        width: 30,
    },
]);
