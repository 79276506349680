import { createReducer, current } from '@reduxjs/toolkit';
import {
    addNewOrderProduct,
    changeOrderProduct,
    removeOrderProduct,
    removeAllOrderProduct,
} from '../../actions/orderProduct';
import { OrderProduct } from '../../types/Models/OrderProduct';

type OrderProductState = Record<string, OrderProduct>;

export const orderProducts = createReducer<OrderProductState>({}, builder => {
    builder.addCase(addNewOrderProduct, (state, { payload }) => ({
        ...state,
        [payload.id]: {
            ...payload,
        },
    }));
    builder.addCase(changeOrderProduct, (state, { payload }) => {
        state[payload.id].count = payload.count;
        return state;
    });
    builder.addCase(removeOrderProduct, (state, { payload }) => {
        const { [payload]: removeProduct, ...OrderProductState } = state;
        return OrderProductState;
    });
    builder.addCase(removeAllOrderProduct, (state, { payload }) => ({ }));
});
