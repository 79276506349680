import { createReducer } from '@reduxjs/toolkit';
import { addNewSuccessOrder, removeSuccessOrder } from '../../actions/successOrder';
import { SuccessOrderModel } from '../../types/Models/SuccessOrder';

interface ISuccessOrder {
    successOrder?: SuccessOrderModel | undefined;
}

export const successOrder = createReducer<ISuccessOrder>({}, builder => {
    builder.addCase(addNewSuccessOrder, (state, { payload }) => ({
        ...state,
        successOrder: payload,
    }));
    builder.addCase(removeSuccessOrder, (state, { payload }) => ({ }));
});
