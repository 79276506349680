import { request } from '../../helpers/api/request';
import { RefreshTokenContract } from '../../types/Endpoints/RefreshTokenContract';

export const refreshToken = async (refreshTokenData: RefreshTokenContract) => {
    const { data } = await request({
        url: 'auth/refresh-token',
        method: 'POST',
        data: refreshTokenData,
    });

    return data;
};
