import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as ordersApi from '../../../api/orders';
import { OrderCardContractParams } from '../../../types/Endpoints/OrderCardContract';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addEmptyArrayUserOrders } from '../setEmptyArrayUserOrders';

export const GET_ORDER_CARD = 'order/getOrderCard';

const onFail = (): Thunk => async dispatch => {
    dispatch(addEmptyArrayUserOrders());
};

export const getOrderCard = createAsyncThunk(
    GET_ORDER_CARD,
    createRequest({
        type: GET_ORDER_CARD,
        loader: (params: OrderCardContractParams) => ordersApi.getOrderCard(params),
        onFail,
        showFailNotification: false,
    }),
);
