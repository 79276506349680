import { createReducer } from '@reduxjs/toolkit';
import { addDrawerOrder, removeDrawerOrder } from '../../actions/drawerOrder';
import { DrawerOrderModel } from '../../types/Models/DrawerOrderModel';

interface IDrawerOrder {
    drawerOrder?: DrawerOrderModel | undefined;
}

export const drawerOrder = createReducer<IDrawerOrder>({}, builder => {
    builder.addCase(addDrawerOrder, (state, { payload }) => ({
        ...state,
        drawerOrder: payload,
    }));
    builder.addCase(removeDrawerOrder, (state, { payload }) => ({ }));
});
