import React, { PropsWithChildren, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Sidebar } from '../components/Sidebar';
import { units } from '../helpers/styles/units';
import { Modals } from '../components/Modals';
import { Snackbars } from '../components/Snackbars';
import { getNewOrders } from '../actions/listNewOrders';
import { useTypeSelector } from '../store';
import { selectListNewOrders, selectNewOrdersLoader } from '../selectors/listNewOrders';
import { addNotification } from '../actions/notifications';
import { TypesSnackbars } from '../types/TypesSnackbars';
import { LoaderState } from '../types/Loaders/LoaderState';

interface IUserContext{
    isPadding?: boolean
}

const StyledRoot = styled.div`
  display: flex;
  width: 100%;
`;

const StyledHero = styled.div<IUserContext>`
  flex-grow: 1;
  padding: ${({ isPadding }) => (isPadding ? `0 ${units(20)} ${units(24)}` : '0')};
`;

export const UserContent = ({ isPadding, children }: PropsWithChildren<IUserContext>) => {
    const dispatch = useDispatch();
    const listNewOrders = useTypeSelector(selectListNewOrders);
    const loader = useTypeSelector(selectNewOrdersLoader);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getNewOrders({
                offset: 30,
            }));
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (loader?.state === LoaderState.FULFILLED) {
            listNewOrders?.forEach(newOrder => {
                dispatch(addNotification({
                    type: TypesSnackbars.SUCCESS,
                    message: {
                        id: +newOrder,
                        title: 'Новый заказ',
                        description: 'Поступил новый заказ',
                    },
                    isUnclosed: true,
                }));
            });
        }
    }, [listNewOrders]);

    return (
        <StyledRoot>
            <Sidebar />
            <StyledHero isPadding={isPadding}>
                {children}
            </StyledHero>
            <Modals />
            <Snackbars />
        </StyledRoot>
    );
};
