import { createAction } from '@reduxjs/toolkit';
import { OrderProduct } from '../../types/Models/OrderProduct';

export const ADD_NEW_ORDER_PRODUCT = 'ADD_NEW_ORDER_PRODUCT';

export const addNewOrderProduct = createAction(
    ADD_NEW_ORDER_PRODUCT,
    ({
        id, count, orderProducts,
    }: OrderProduct) => ({
        payload: {
            id, count, orderProducts,
        },
    }),
);
