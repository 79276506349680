import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';

const hoverBgByType: Record<string, string> = {
    [TypesButton.FILL]: colors.blue3,
    [TypesButton.OUTLINE]: colors.blue_20,
    [TypesButton.OUTLINE]: colors.blue_20,
    [TypesButton.OUTLINE_BORDER_GREY]: colors.gray2,
};

export const applyActiveBgColor = ({ typeButton, disabled }: { typeButton: TypesButton, disabled?: boolean }) => (
    !disabled
        ? `
          &:active {
            background: ${hoverBgByType[typeButton]}};
          }
        `
        : '');
