export const colors = {
    background: '#F7F8FA',

    dark: '#26314F',

    gray: '#828DAB',
    gray2: '#C1CAE3',
    gray3: '#DFE4F2',
    gray10: '#828DAB',
    gray20: '#26314F',

    white: '#FFFFFF',
    whiteBlue: '#F2F5FF',
    white_10: '#F7F8FAFF',

    blue: '#2A62FF',
    blue_10: '#2A62FF19',
    blue_20: '#2A62FF33',
    blue2: '#224ECC',
    blue3: '#1B40A6',
    blue_30: '#2A62FF1A',
    blue_16: '#828DAB28',

    green: '#2FC23D',
    green_10: '#EAF9EC',
    red: '#FF2A2A',
    red_10: '#FFEAEA',
    yellow: '#FFB72A',
    yellow_10: '#FFF8EA',
    orange: '#FF832A',
    orange_10: '#FFF3EA',
};
