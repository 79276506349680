import { createReducer } from '@reduxjs/toolkit';
import { getCategory } from '../../actions/category';
import { CategoriesModal } from '../../types/Models/CategoriesModal';
import { CategoryFilters } from '../../types/Models/CategoryFilters';
import { getCategoryFilters } from '../../actions/category/thunks/getCategoryFilters';

interface Category {
    category?: CategoriesModal[] | undefined;
    filters?: CategoryFilters[] | undefined;
}

export const category = createReducer<Category>({}, builder => {
    builder.addCase(getCategory.fulfilled, (state, { payload }) => ({
        ...state,
        category: payload,
    }));
    builder.addCase(getCategoryFilters.fulfilled, (state, { payload }) => ({
        ...state,
        filters: payload,
    }));
});
