import React from 'react';
import styled from 'styled-components';
import { PasswordControlProps } from '../../../../types/Form/PasswordControlProps';
import { Input } from '../../../Input';

const StyledField = styled.div`
  position: relative;

  width: 100%;
`;

export const PasswordControl = ({
    name,
    prefix,
    onChange,
    error,
    value,
    ...props
}: PasswordControlProps) => (
    <StyledField>
        <Input
            name={name}
            prefix={prefix}
            onChange={onChange}
            error={error}
            value={value || ''}
            type="password"
            {...props}
        />
    </StyledField>
);
