import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as orderApi from '../../../api/orders';
import { Thunk } from '../../../helpers/redux/Thunk';
import { OrderProductsPrice } from '../../../types/Models/OrderProduct';

export const GET_PRICE_PRODUCT_FOR_SHOP = 'order/getPriceProductsForShop';

export const getPriceProductsForShop = createAsyncThunk(
    GET_PRICE_PRODUCT_FOR_SHOP,
    createRequest({
        type: GET_PRICE_PRODUCT_FOR_SHOP,
        loader: (payload : {shopId: number, productsList: OrderProductsPrice[]}) => orderApi.getPriceProductsForSelectShop(payload.shopId, payload.productsList),
        showFailNotification: false,
    }),
);
