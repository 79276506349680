import styled from 'styled-components';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import loginBg from '../../../styles/images/loginBg.png';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { typography } from '../../../helpers/styles/typography';
import { getFieldsLogin } from './fieldsLogin';
import { Form } from '../../../components/Form';
import { KeyboardEvents } from '../../../components/KeyboardEvents';
import { getNativeEvent } from '../../../helpers/event';
import { KeyCodes } from '../../../constants/KeyCodes';
import { TypesButton } from '../../../constants/ButtonTypes';
import { Button } from '../../../components/Button';
import { Logo } from '../../../components/Logo';
import { login } from '../../../actions/auth';
import { Snackbars } from '../../../components/Snackbars';
import { getSessionFromStorage } from '../../../helpers/auth/session';
import { routes } from '../../../constants/RouterPath';

interface Formik {
    login: string;
    password: string;
}

const StyledRoot = styled.div`
  height: 100vh;
  
  background: url("${loginBg}") no-repeat;
  background-size: 100% 100%;
`;

const StyledBlurWrapper = styled.div`
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  height: 100vh;
  
  background: linear-gradient(75.13deg, rgba(255, 255, 255, 0.65) 35.97%, rgba(225, 233, 255, 0.65) 77.91%);
  backdrop-filter: blur(${units(4)});
`;

const StyledLogo = styled(Logo)`
  position: absolute;
  top: ${units(16)};
  left: 50%;
  transform: translate(-50%, 0);
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding: ${units(24)} ${units(28)};
  
  border-radius: ${units(8)};
  box-shadow: 0 ${units(6)} ${units(25)} rgba(130, 141, 171, 0.1);
  
  background: ${colors.white};
`;

const StyledFormTitle = styled.p`
  margin-bottom: ${units(16)};
  
  font-weight: ${FontWeight.MEDIUM};
  
  ${typography(15)}
`;

const StyledDialog = styled.div`
  display: flex;
  flex-direction: column;

  width: ${units(120)};
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${units(14)};
`;

type FormValues = Partial<Formik>;

export const LoginDialog = () => {
    const dispatch = useDispatch();
    const { token } = getSessionFromStorage() || {};
    const { t } = useTranslation('login');

    const handleSubmit = (values: FormValues) => {
        dispatch(login({
            email: values.login,
            password: values.password,
        }));
        // history.push(routes.orders);
    };

    const form = useFormik<FormValues>({
        onSubmit: handleSubmit,
        initialValues: {
            login: '',
            password: '',
        },
        // validationSchema: SignupSchema(),
    });

    const handleKeyDown = useCallback((event: any) => {
        const { values, isValid } = form;
        if (isValid && getNativeEvent(event).code === KeyCodes.ENTER) {
            handleSubmit(values);
        }
    }, [form, handleSubmit]);

    const onClickLogin = (event: any) => {
        event.preventDefault();
        dispatch(login({
            email: form.values.login,
            password: form.values.password,
        }));
    };

    if (token) {
        window.location.href = routes.orders;
    }

    return (
        <StyledRoot>
            <StyledBlurWrapper>
                <StyledLogo />
                <StyledFormWrapper>
                    <StyledFormTitle>
                        {t('sentences.loginInfo')}
                    </StyledFormTitle>
                    <StyledDialog>
                        <Form<FormValues>
                            fields={getFieldsLogin(t)}
                            form={form}
                        />
                        <StyledButton
                            typeButton={TypesButton.FILL}
                            onClick={event => onClickLogin(event)}
                        >
                            {t('buttons.signIn')}
                        </StyledButton>
                        <KeyboardEvents onKeyDown={handleKeyDown} />
                    </StyledDialog>
                </StyledFormWrapper>
            </StyledBlurWrapper>
            <Snackbars />
        </StyledRoot>
    );
};
