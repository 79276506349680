import { createReducer } from '@reduxjs/toolkit';
import { UsersModal } from '../../types/Models/UsersModal';
import {
    getUserByPhone, createUser, updateUser, removeUserByPhone,
} from '../../actions/users';
import { addEmptyArray } from '../../actions/users/setEmtyArray';

interface UsersByPhone {
    userByPhone?: UsersModal[] | undefined;
}

export const userByPhone = createReducer<UsersByPhone>({}, builder => {
    builder.addCase(getUserByPhone.fulfilled, (state, { payload }) => ({
        ...state,
        userByPhone: payload,
    }));
    builder.addCase(createUser.fulfilled, state => ({
        ...state,
    }));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    builder.addCase(addEmptyArray, (state, { payload }) => ({
        ...state,
        userByPhone: payload,
    }));
    builder.addCase(updateUser.fulfilled, state => ({
        ...state,
    }));
    builder.addCase(removeUserByPhone, (state, { payload }) => ({ }));
});
