import { TFunction } from 'i18next';
import { BreadcrumbsProps } from '../../types/Breadcrumbs/BreadcrumbsProps';

export const getBreadcrumbs = (t: TFunction) : BreadcrumbsProps[] => ([
    {
        key: '1',
        value: t('breadcrumbs.catalog'),
        url: '/catalog',
    },
    {
        key: '2',
        value: t('breadcrumbs.cardProduct'),
        url: 'ter',
    },
]);
