import { createReducer } from '@reduxjs/toolkit';
import { UsersModal } from '../../types/Models/UserContract';
import { getCurrentUser } from '../../actions/сurrentUser';

interface ICurrentUser {
    currentUser?: UsersModal | undefined;
}

export const currentUser = createReducer<ICurrentUser>({}, builder => {
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => ({
        ...state,
        currentUser: payload,
    }));
});
