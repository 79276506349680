import React from 'react';
import styled from 'styled-components';
import { units } from '../../../../helpers/styles/units';
import { InputControlProps } from '../../../../types/Form/InputControlProps';
import { Input } from '../../../Input';

const StyledField = styled.div`
  position: relative;
  width: 100%;
  
  margin-bottom: ${units(12)};
`;

export const InputControl = ({
    name,
    value,
    prefix,
    onChange,
    error,
    inputRestriction,
    ...props
}: InputControlProps) => (
    <StyledField>
        <Input
            name={name}
            value={value || ''}
            prefix={prefix}
            onChange={onChange}
            inputRestriction={inputRestriction}
            error={error}
            {...props}
        />
    </StyledField>
);
