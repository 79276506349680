import dayjs, { ConfigType } from 'dayjs';

// Дефолтная локаль для dayjs
dayjs.locale('ru');

export const formatTime = (date?: ConfigType): string => {
    if (!date) {
        return '—';
    }

    return dayjs(date).format('HH:mm');
};

export const formatOrderDay = (orderDay: string) => dayjs(orderDay).format('DD.MM.YYYY');

export const getDayWeek = (orderDay: string) => {
    const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

    return days[dayjs(orderDay).day()];
};

export const getFullDate = (orderDay: string) => {
    const months = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
        'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];

    return `${dayjs(orderDay).date()} ${months[dayjs(orderDay).month()]} ${dayjs(orderDay).year()} г. ${dayjs(orderDay).format('HH:mm:ss')}`;
};

export const getFormattedDateWithDash = (date: Date) => dayjs(date).format('YYYY-MM-DD');
