import styled from 'styled-components';
import React from 'react';
import { units } from '../../../../helpers/styles/units';
import { DatePickerRange } from '../../../DatePickerRange';
import { DatePickerControlProps } from '../../../../types/Form/DatePickerControlProps';

const StyledDatePickerRange = styled(DatePickerRange)`
  margin-bottom: ${units(12)};
`;

export const DatePickerControl = ({
    name,
    value,
    postfix,
    error,
    touched,
    isValid,
    setValue,
    positionTop = false,
    disableUntilСurrentDate = false,
}: DatePickerControlProps) => (
    <StyledDatePickerRange
        key={name}
        name={name}
        postfix={postfix}
        value={value}
        onChange={setValue}
        error={error}
        touched={touched}
        isValid={isValid}
        positionTop={positionTop}
        disableUntilСurrentDate={disableUntilСurrentDate}
    />
);
