import { TFunction } from 'i18next';
import { BreadcrumbsProps } from '../../types/Breadcrumbs/BreadcrumbsProps';

export const getBreadcrumbs = (t: TFunction) : BreadcrumbsProps[] => ([
    {
        key: '1',
        value: t('breadcrumbs.orderList'),
        url: '/orders',
    },
    {
        key: '2',
        value: t('breadcrumbs.orderCard'),
        url: '/order',
    },
    {
        key: '3',
        value: t('breadcrumbs.clientCard'),
        url: 'ptp',
    },
]);
