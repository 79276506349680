import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { HeaderOrderPage } from './HeaderOrderPage';
import { CustomerInformation } from './ClientInformation';
import { OrderContentsTable } from './OrderContentsTable';
import { IssuingOrders } from './IssuingOrders';
import { SaveBlock } from './SaveBlock';
import { units } from '../../helpers/styles/units';
import { getBreadcrumbs } from './getBreadcrumbs';
import { useTypeSelector } from '../../store';
import { getOrderCard, removeOrderCard } from '../../actions/orders';
import { selectOrderProductArray } from '../../selectors/orderProduct';
import { getUser } from '../../actions/users';
import { getAllShop } from '../../actions/shop';
import { selectOrderCard } from '../../selectors/orderCard';
import { addNewOrderProduct, removeAllOrderProduct } from '../../actions/orderProduct';
import { ClientSchema } from './ClientInformation/ClientSchema';
import { colors } from '../../constants/Colors';
import { OrderSelectSchema } from './HeaderOrderPage/OrderSelectSchema';
import { TypesButton } from '../../constants/ButtonTypes';
import { TypesOrder } from '../../types/TypesOrder';
import { selectUserCard } from '../../selectors/userCard';
import { selectUsersByPhone } from '../../selectors/currentUser';
import { selectAllShop } from '../../selectors/allShops';
import { IShopList } from '../../types/Models/ShopListModel';

interface FormikIssuingOrders {
    DatePicker: string;
    selectOptionShop: string[];
    selectOptionTime: string[];
}

interface FormikStatusOrder {
    selectOrder: string[];
}

interface FormikСomment {
    textarea: string;
}

const StyledContentPage = styled.div`
  padding: 0 ${units(20)} ${units(24)};
`;

type FormValuesStatusOrder = Partial<FormikStatusOrder>;
type FormValuesIssuingOrders = Partial<FormikIssuingOrders>;
type FormValuesСomment = Partial<FormikСomment>;

export const Order = () => {
    const { t } = useTranslation('orders');
    const orderProductArray = useTypeSelector(selectOrderProductArray);
    const orderCard = useTypeSelector(selectOrderCard);
    const user = useTypeSelector(selectUserCard);
    const usersByPhone = useTypeSelector(selectUsersByPhone);
    const allShop = useTypeSelector(selectAllShop);
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t: TFilters } = useTranslation('filters');
    const [cavistShop, setCavistShop] = useState<IShopList | undefined>(allShop?.find(item => item?.address === usersByPhone?.shop?.address));

    const orderGoods = orderCard?.products || [];
    const isNewOrder = location.pathname.includes('new-order');
    const userOrderComment = isNewOrder ? user?.comment : orderCard?.comment;

    const statusOrder: Record<string, string[]> = {
        sent: [`${TFilters('sentences.sent')}`, colors.orange, colors.orange_10, 'sent'],
        collection: [`${TFilters('sentences.collection')}`, colors.yellow, colors.yellow_10, 'collection'],
        ready: [`${TFilters('sentences.ready')}`, colors.green, colors.green_10, 'ready'],
        received: [`${TFilters('sentences.received')}`, colors.blue, colors.blue_10, 'received'],
        cancelled: [`${TFilters('sentences.cancelled')}`, colors.red, colors.red_10, 'cancelled'],
    };

    const handleSubmit = (values: FormValuesIssuingOrders) => {
        console.log(values);
    };

    const handleSubmitValues = (values: FormValuesStatusOrder) => {
        console.log(values);
    };

    const formStatusOrder = useFormik<FormValuesStatusOrder>({
        onSubmit: handleSubmitValues,
        initialValues: {
            selectOrder: isNewOrder ? [`${TFilters('sentences.sent')}`, colors.orange, colors.orange_10, 'sent'] : statusOrder[`${orderCard?.status}`],
        },
        enableReinitialize: true,
        validationSchema: OrderSelectSchema(),
    });

    const formIssuingOrders = useFormik<FormValuesIssuingOrders>({
        onSubmit: handleSubmit,
        initialValues: {
            DatePicker: isNewOrder ? '' : orderCard?.date_pickup,
            // eslint-disable-next-line
            selectOptionShop: isNewOrder
                ? ['', '', '', '']
                : [`${orderCard?.shop?.address}`, colors.blue, colors.blue_30, `${orderCard?.shop?.id}`],
            selectOptionTime: isNewOrder ? ['', '', '', ''] : [`${orderCard?.hours_pickup}`, colors.blue, colors.blue_30, '25'],
        },
        enableReinitialize: true,
        validationSchema: ClientSchema(),
    });

    const handleSubmitComment = (values: FormValuesСomment) => {
        console.log(values);
    };

    const formComment = useFormik<FormValuesСomment>({
        onSubmit: handleSubmitComment,
        initialValues: {
            textarea: userOrderComment || '',
        },
        enableReinitialize: true,
        validationSchema: ClientSchema(),
    });

    const idShop = formIssuingOrders?.values.selectOptionShop ? formIssuingOrders?.values.selectOptionShop[3] : '';
    const selectTime = formIssuingOrders?.values.selectOptionTime ? formIssuingOrders?.values.selectOptionTime[0] : '';
    const selectStatusOrder = formStatusOrder?.values.selectOrder ? formStatusOrder?.values.selectOrder[3] : '';
    const commentUser = formComment?.values.textarea ? formComment?.values.textarea : '';

    useEffect(() => {
        if (isNewOrder) {
            dispatch(removeAllOrderProduct());
            dispatch((removeOrderCard()));
            dispatch(getUser({ id: +id }));
        } else {
            dispatch(getOrderCard({ id: +id }));
        }
    }, []);

    useEffect(() => {
        setCavistShop(allShop?.find(item => item?.address === usersByPhone?.shop?.address));
    }, [allShop, usersByPhone]);

    useEffect(() => {
        if (cavistShop && isNewOrder) {
            formIssuingOrders.setFieldValue('selectOptionShop', [`${cavistShop.address}`, colors.blue, colors.blue_30, `${cavistShop.id}`]);
        }
    }, [cavistShop]);

    useEffect(() => {
        orderCard?.products?.forEach(product => dispatch(addNewOrderProduct({
            id: product.product_id,
            count: product?.added_to_order ? +product.added_to_order : 0,
            orderProducts: product,
        })));
        if (isNewOrder) {
            dispatch(removeAllOrderProduct());
        }
        return () => {
            dispatch(removeAllOrderProduct());
        };
    }, [orderCard?.products]);

    return (
        <>
            <StyledContentPage>
                <Breadcrumbs breadcrumbs={getBreadcrumbs(t)} />
                <HeaderOrderPage formStatusOrder={formStatusOrder} isNewOrder={isNewOrder} />
                <CustomerInformation formComment={formComment} isNewOrder={isNewOrder} />
                <OrderContentsTable orderProductArray={orderProductArray} order={orderCard} idShop={idShop} />
                <IssuingOrders formIssuingOrders={formIssuingOrders} />
            </StyledContentPage>
            <SaveBlock
                idShop={idShop}
                date={formIssuingOrders.values.DatePicker}
                orderTime={formIssuingOrders.values.selectOptionTime}
                status={selectStatusOrder}
                selectTime={selectTime}
                isNewOrder={isNewOrder}
                commentUser={commentUser}
            />
        </>
    );
};
