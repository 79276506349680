import { createReducer } from '@reduxjs/toolkit';
import { LoyaltyInformationModal } from '../../types/Models/LoyaltyInformationModal';
import { getLoyaltyInformation } from '../../actions/loyaltyInformation';

interface LoyaltyInformation {
    loyaltyInformation?: LoyaltyInformationModal;
}

export const loyaltyInformation = createReducer<LoyaltyInformation>({}, builder => {
    builder.addCase(getLoyaltyInformation.fulfilled, (state, { payload }) => ({
        ...state,
        loyaltyInformation: payload,
    }));
});
