import { request } from '../../helpers/api/request';
import { CreateOrder } from '../../types/Endpoints/CreateOrder';

export const createOrder = async (orderData: CreateOrder) => {
    const { data } = await request({
        url: 'orders/add',
        method: 'POST',
        data: orderData,
    });

    return data;
};
