import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

export const useScrollToTop = () => {
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        window.scroll({ top: 0, left: 0 });
    }, [pathname]);
};
