import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import { OrdersContractParams } from '../../../types/Endpoints/OrdersContract';
import * as ordersApi from '../../../api/orders';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addEmptyArray } from '../setEmtyArray';

export const GET_ADDITIONAL_ORDERS = 'orders/getAdditionalOrders';

const onFail = (): Thunk => async dispatch => {
    dispatch(addEmptyArray());
};

export const getAdditionalOrders = createAsyncThunk(
    GET_ADDITIONAL_ORDERS,
    createRequest({
        type: GET_ADDITIONAL_ORDERS,
        loader: (params: OrdersContractParams) => ordersApi.getOrders(params),
        onFail,
        showFailNotification: false,
    }),
);
