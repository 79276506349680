import { createReducer } from '@reduxjs/toolkit';
import { OrderModel } from '../../types/Models/OrderModel';
import { getUserOrders } from '../../actions/orders';
import { addEmptyArrayUserOrders } from '../../actions/orders/setEmptyArrayUserOrders';

interface IUserOrders {
    userOrders?: OrderModel[] | undefined;
}

export const userOrders = createReducer<IUserOrders>({}, builder => {
    builder.addCase(getUserOrders.fulfilled, (state, { payload }) => ({
        ...state,
        userOrders: payload,
    }));
    builder.addCase(addEmptyArrayUserOrders, (state, { payload }) => ({
        ...state,
        orders: payload,
    }));
});
