import { createReducer } from '@reduxjs/toolkit';
import { addNotification, removeNotification, removeAllNotification } from '../../actions/notifications';
import { getId } from '../../helpers/generateId';
import { NotificationMessage } from '../../types/Models/Notification';
import { addEmptyArray } from '../../actions/products/setEmtyArray';

interface SnackbarsValues {
    message: NotificationMessage;
    type: string;
    id: number;
    autoHideDuration?: number;
    isUnclosed?: boolean;
}

type SnackbarsState = Record<string, SnackbarsValues>;

const notificationId = getId();

export const snackbars = createReducer<SnackbarsState>({}, builder => {
    builder.addCase(addNotification, (state, { payload }) => {
        const id = notificationId();
        return {
            ...state,
            [id]: {
                ...payload,
                id,
            },
        };
    });
    builder.addCase(removeNotification, (state, { payload }) => {
        const { [payload]: closedNotification, ...notifications } = state;
        return notifications;
    });
    builder.addCase(removeAllNotification, (state, { payload }) => ({ }));
});
