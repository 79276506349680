import { createAction } from '@reduxjs/toolkit';
import { Notification } from '../../types/Models/Notification';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export const addNotification = createAction(
    ADD_NOTIFICATION,
    ({
        message, type, autoHideDuration, isUnclosed,
    }: Notification) => ({
        payload: {
            message, type, autoHideDuration, isUnclosed,
        },
    }),
);
