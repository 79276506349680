import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as userApi from '../../../api/user';

export const LOGOUT = 'user/logout';

export const logout = createAsyncThunk<null>(
    LOGOUT,
    createRequest({
        type: LOGOUT,
        loader: () => userApi.logout(),
    }),
);
