import React from 'react';
import { FormProps } from '../index';
import { FormField } from './Controls/FormField';

export const Fields = <V extends Record<string, unknown> = Record<string, unknown>, >({
    fields,
    form,
    isRow,
    // eslint-disable-next-line
}: FormProps<V>) => {
    return (
        <>
            {fields.map(field => (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <FormField<typeof field.type>
                    field={field}
                    form={form}
                    key={field.name}
                    isRow={isRow}
                />
            ))}
        </>
    );
};
