export enum BreakPoints {
  XS = '320px',
  SM = '576px',
  M = '680px',
  MD = '768px',
  LG = '992px',
  L = '1100px',
  XL = '1350px',
  XLL = '1440px',
  XXL = '1600px',
  XXXL = '1900px',
}
