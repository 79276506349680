import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled as MaterialStyled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as React from 'react';
import { units } from '../../../../helpers/styles/units';
import { TableWrapper } from '../../../../components/TableWrapper';
import { colors } from '../../../../constants/Colors';
import { Icon } from '../../../../components/Icon';
import { TypesIcon } from '../../../../types/TypesIcon';
import { SearchInput } from '../../../../components/SearchInput';
import { getModalTableHead } from './getModalTableHead';
import { getFieldsOrderSelect } from './FieldsOrderSelect';
import { Form } from '../../../../components/Form';
import { OrderSelectSchema } from './OrderSelectSchema';
import { DrawerModalFilters } from '../../../Catalog/TableFilters/DrawerFilters';
import { getFieldsSelectAvailability } from '../../../../helpers/getSelectFields/getFieldsSelectAvailability';
import { getFieldsSelectPrice } from '../../../../helpers/getSelectFields/getFieldsSelectPrice';
import { typography } from '../../../../helpers/styles/typography';
import { FontWeight } from '../../../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../../../store';
import { selectOrderProduct } from '../../../../selectors/orderProduct';
import { addNewOrderProduct, changeOrderProduct, removeOrderProduct } from '../../../../actions/orderProduct';
import { getAdditionalProduct, getProduct } from '../../../../actions/products';
import { selectProduct } from '../../../../selectors/product';
import { ProductModel } from '../../../../types/Models/ProductTableModel';
import { selectDrawerProduct } from '../../../../selectors/drawerProducts';
import { comparingArrays } from '../../../../helpers/arrayСomparison';
import { config } from '../../../../config';
import { AvailableCircle } from '../../../../components/AvailableCircle';
import PlaceholderForImage from '../../../../assets/placeholderForImage.png';
import { ProductContractParams } from '../../../../types/Endpoints/ProductContract';
import { productPriceToString } from '../../../../helpers/generators/productPriceGenerator';

const StyledTableCell = MaterialStyled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        borderBottom: 0,
        fontFamily: '\'TTNormsPro\', sans-serif',
        fontSize: '14px',
        lineHeight: '20px',
        color: colors.dark,
        letterSpacing: '0.4px',
        '&:first-of-type': {
            position: 'relative',
            left: '16px',
        },
    },
}));

interface IStyledButton{
    isSelectedFilter?: boolean
}

const StyledImageGoods = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: ${units(28)};
  margin-right: ${units(6)};

  img {
    height: 100%;
    max-height: ${units(28)};
    cursor: pointer;
  }
`;

const StyledNameGoods = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2; // количество строк
  -webkit-box-orient: vertical;
  overflow: hidden;
  border: 2px solid transparent;

  &:hover{
    color: ${colors.blue};
    border-bottom: 2px solid ${colors.blue};
  }
  
  &:active{
    color: ${colors.blue};
    border-bottom: 2px solid ${colors.blue};
  }
`;

const StyledButton = styled.button<IStyledButton>`
  display: flex;
  align-items: center;
  padding: ${units(3)} ${units(6)};
  margin: ${units(4)} 0;
  gap: ${units(2)};
  max-height: ${units(16)};

  font-weight: ${FontWeight.MEDIUM};
  color: ${({ isSelectedFilter }) => (!isSelectedFilter ? colors.blue : colors.dark)};

  svg {
    fill:  ${({ isSelectedFilter }) => (!isSelectedFilter ? colors.blue : colors.dark)} !important;
  }


  background: ${colors.white};
  border-radius: ${units(6)};
  border: ${units(0.5)} solid ${colors.gray3};

  cursor: pointer;

  ${typography(5)};
  
  :hover {
    color: ${colors.blue};
    background: ${colors.whiteBlue};
  }

  &:hover svg {
    fill: ${colors.blue} !important;
  }
`;

const WrapperBottomTable = styled.div`
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    & > svg {
      position: relative;
      bottom: -5px;
    }
`;

const StyledHeaderModel = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  
    .select_form{
      width: max-content;
    }
`;

const StyledWrapperTextField = styled.div`
    .MuiOutlinedInput-root{
      border-radius: ${units(7)};
      height: ${units(18)};
      width: ${units(36)};
    }
`;

const WrapperButtons = styled.div`
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  
  .MuiDrawer-paper{
    border-radius:  0 ${units(8)} ${units(8)} 0;
  }
`;

const StyleWrapperFilter = styled.div`
    display: flex;
`;

interface Formik {
    selectPrice: string[];
    selectAvailability: string[];
}

type FormValues = Partial<Formik>;

const StyledTableNotFound = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

const StyledResetButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: ${colors.gray};
  white-space: nowrap;
  margin-right: ${units(12)};
  margin-top: ${units(-6)};
  cursor: pointer;
  
  &:hover{
    color: ${colors.dark};
  }
`;

const WrapperInput = styled.div`
  & > .MuiPaper-root {
    box-shadow: none;
    border-radius: ${units(6)};
    max-width: 20.3125rem;
    max-height: 2.75rem;
    font: revert;
  }

  & .MuiInputBase-input {
    color: ${colors.gray};
    font-size: 14px;
    line-height: 20px;
  }

  & .MuiInputBase-root {
    margin-left: 0;
  }
`;

export const ModalProductTable = ({ idShop }: {idShop?: string}) => {
    const { t } = useTranslation('table');
    const [searchOrder, setSearchOrder] = useState<string>('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const products = useTypeSelector(selectProduct);
    const orderProduct = useTypeSelector(selectOrderProduct);
    const [sortByName, setSortByName] = useState<string>('');
    const dispatch = useDispatch();
    const history = useHistory();
    const productsTable = products?.products;
    const endProduct = products?.end;
    const selectFilterDrawer = useTypeSelector(selectDrawerProduct);
    const selectOptionCategory = selectFilterDrawer?.category ? selectFilterDrawer?.category[3] : undefined;
    const [isSelectedFilter, setIsSelectedFilter] = useState<boolean>(false);
    const [pageTable, setPageTable] = useState<number>(1);

    const onClickSortByDate = () => {
        if (sortByName === '') setSortByName('asc');
        if (sortByName === 'asc') setSortByName('desc');
        if (sortByName === 'desc') setSortByName('');
    };

    const handleSubmit = (values: FormValues) => {
        console.log(values);
    };

    const form = useFormik<FormValues>({
        onSubmit: handleSubmit,
        initialValues: {
            selectPrice: ['', '', ''],
            selectAvailability: ['', '', ''],
        },
        validationSchema: OrderSelectSchema(),
    });

    const selectAvailability = form?.values.selectAvailability ? form?.values.selectAvailability[3] : '';
    const selectPrice = form?.values.selectPrice ? form?.values.selectPrice[3] : '';

    const formCategoryIds = () => {
        if (Object.keys(selectFilterDrawer?.category_id || {}).length !== 0) {
            const sub = Object.keys(selectFilterDrawer?.category_id || {});
            return [...sub];
        }
        return [selectFilterDrawer?.category?.slice(3)[0]];
    };

    const getPayload = (): Partial<ProductContractParams> => {
        const filters = {
            category_id: formCategoryIds() || undefined,
            price_range: (selectFilterDrawer?.price_range?.queryString !== '0-0') ? selectFilterDrawer?.price_range?.queryString : undefined,
            country: Object.keys(selectFilterDrawer?.country || {}) || undefined,
            volume: Object.keys(selectFilterDrawer?.volume || {}) || undefined,
            alcohol_content_range: (selectFilterDrawer?.alcohol_content_range?.queryString !== '0-0') ? selectFilterDrawer?.alcohol_content_range?.queryString : undefined,
            gift_box: selectFilterDrawer?.gift_box?.slice(3)[0] || undefined,
            wine_sugar_type: Object.keys(selectFilterDrawer?.wine_sugar_type || {}) || undefined,
            whiskey_type: Object.keys(selectFilterDrawer?.whiskey_type || {}) || undefined,
            champagne_type: Object.keys(selectFilterDrawer?.champagne_type || {}) || undefined,
            cognac_type: Object.keys(selectFilterDrawer?.cognac_type || {}) || undefined,
            shop_id: idShop || undefined,
        };

        return filters as Partial<ProductContractParams>;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchOrder) {
                setPageTable(1);
                dispatch(getProduct({
                    page: 1,
                    limit: 15,
                    name_sort: sortByName || undefined,
                    search_string: searchOrder,
                    price_sort: selectPrice,
                    available: selectAvailability || undefined,
                    ...getPayload(),
                }));
            }
        }, 1000);

        if (!searchOrder) {
            setPageTable(1);
            dispatch(getProduct({
                page: 1,
                limit: 15,
                name_sort: sortByName || undefined,
                search_string: searchOrder,
                price_sort: selectPrice,
                available: selectAvailability || undefined,
                ...getPayload(),
            }));
        }

        return () => clearTimeout(timer);
    }, [searchOrder, selectFilterDrawer, form.values, sortByName, selectPrice]);

    const onClickAddMoreProduct = () => {
        setPageTable(prevState => prevState + 1);
        dispatch(getAdditionalProduct({
            page: pageTable + 1,
            limit: 15,
            name_sort: sortByName || undefined,
            search_string: searchOrder || undefined,
            price_sort: selectPrice || undefined,
            available: selectAvailability || undefined,
            ...getPayload(),
        }));
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickResetButton = () => {
        form.resetForm();
    };

    const isResetButton = comparingArrays(form.initialValues.selectPrice, form.values.selectPrice)
        && comparingArrays(form.initialValues.selectAvailability, form.values.selectAvailability);

    const onChangeProductCount = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        id: number,
        product: ProductModel,
        max: string,
    ) => {
        if ((+e.target.value === 1) && !orderProduct[id]) dispatch(addNewOrderProduct({ id, count: +e.target.value, orderProducts: product }));
        else if (+e.target.value === 0) dispatch(removeOrderProduct(id));
        else dispatch(changeOrderProduct({ id, count: +e.target.value, orderProducts: product }));
    };

    return (
        <>
            <StyledHeaderModel>
                <WrapperInput>
                    <SearchInput
                        placeholder="Поиск товаров"
                        searchOrder={searchOrder}
                        setSearchOrder={setSearchOrder}
                        allSymbols
                    />
                </WrapperInput>
                <StyleWrapperFilter>
                    {!isResetButton
                        ? (
                            <StyledResetButton onClick={() => onClickResetButton()}>
                                Сбросить все
                            </StyledResetButton>
                        ) : null}
                    <Form<FormValues>
                        fields={getFieldsOrderSelect(getFieldsSelectPrice())}
                        form={form}
                        className="select_form"
                        isRow
                    />
                    <WrapperButtons>
                        <StyledButton
                            isSelectedFilter={isSelectedFilter}
                            onClick={handleClick}
                        >
                            <Icon
                                type={TypesIcon.TUNE}
                                width="20px"
                                height="20px"
                            />
                            Фильтр
                        </StyledButton>
                        <DrawerModalFilters
                            isModal
                            setIsSelectedFilter={setIsSelectedFilter}
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                        />
                    </WrapperButtons>
                </StyleWrapperFilter>
            </StyledHeaderModel>
            <TableWrapper
                onClickSort={onClickSortByDate}
                tableHeadRow={getModalTableHead(t)}
            >
                {(productsTable && productsTable.length)
                    ? (
                        <>
                            {productsTable.map(row => (
                                <TableRow
                                    sx={{
                                        '&:hover': {
                                            boxShadow: `0px 0px ${units(6)} rgba(130, 141, 171, 0.16)`,
                                            borderRadius: `${units(15)}`,
                                        },
                                    }}
                                    key={row.product_id}
                                >
                                    <StyledTableCell align="center">
                                        {row.product_id}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        align="left"
                                        onClick={() => history.push(`/products-card/${row.product_id}`)}
                                    >
                                        <StyledImageGoods>
                                            <img
                                                alt="изображение товара"
                                                src={row?.images_links[0] ? `${config.commonUrl}/${row?.images_links[0]}` : PlaceholderForImage}
                                            />
                                        </StyledImageGoods>
                                        <StyledNameGoods> {row.name}</StyledNameGoods>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{ row.volume ? `${row.volume}л` : `${row.weight}кг`}</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ fontWeight: 500 }}>
                                        {productPriceToString(row)}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        sx={{
                                            color: !row.available ? 'red !important' : 'inherit',
                                        }}
                                    >
                                        <AvailableCircle available={row.available} />
                                        {row.available ? 'В наличии' : 'Нет в наличии'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <StyledWrapperTextField>
                                            <TextField
                                                onChange={event => onChangeProductCount(
                                                    event,
                                                    row.product_id,
                                                    row,
                                                    (orderProduct[row.product_id]?.orderProducts?.added_to_order
                                                        ? row.available_quantity + orderProduct[row.product_id].orderProducts.added_to_order
                                                        : row.available_quantity),
                                                )}
                                                value={orderProduct[row.product_id] ? orderProduct[row.product_id].count : 0}
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0,
                                                    },
                                                }}
                                                type="number"
                                            />
                                        </StyledWrapperTextField>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{row.available_quantity}</StyledTableCell>
                                </TableRow>
                            ))}
                            {!endProduct
                                ? (
                                    <StyledTableCell
                                        sx={{ borderTop: '1px solid rgba(223, 228, 242, 1)' }}
                                        colSpan={6}
                                        align="center"
                                    >
                                        <WrapperBottomTable onClick={() => onClickAddMoreProduct()}>
                                            Больше товаров
                                            <Icon
                                                type={TypesIcon.ARROW_DROP_DOWN}
                                                width="20px"
                                                height="20px"
                                                fill={colors.dark}
                                            />
                                        </WrapperBottomTable>
                                    </StyledTableCell>
                                ) : null}
                        </>
                    )
                    : (
                        <TableCell
                            sx={{
                                borderTop: `1px solid ${colors.gray3}`,
                                position: 'static',
                                padding: '48px 0',
                            }}
                            colSpan={6}
                            align="center"
                        >
                            <StyledTableNotFound>
                                Товары не найдены
                            </StyledTableNotFound>
                        </TableCell>
                    )}
            </TableWrapper>
        </>
    );
};
