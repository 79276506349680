import React from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import { TypesFields } from '../../../../types/TypesFields';
import { applyRowStyle } from './applyRowStyle';
import { PasswordControl } from './PasswordControl';
import { InputControl } from './InputControl';
import { FieldProps, FieldValues } from '../../../../types/Form/FieldProps';
import { units } from '../../../../helpers/styles/units';
import { TextareaControl } from './TextareaControl';
import { SelectControl } from '../../../Select';
import { DatePickerControl } from './DatePickerControl';
import { DividerControl } from './DividerControl';
import { RangeControl } from './RangeControl';
import { CheckGroupControl } from './CheckGroupControl';

const StyledField = styled.div<{isRow: boolean, titleLeft: boolean}>`
  position: relative;

  width: 100%;
  height: 100%;

  display: ${({ titleLeft }) => (titleLeft ? 'flex' : 'contents')};
  align-items:  ${({ titleLeft }) => (titleLeft ? 'center' : 'inherit')};
  
  & > div:last-child{
    width: ${({ titleLeft }) => (titleLeft ? '60%' : 'inherit')};
  }
  ${applyRowStyle};
`;

const StyledInputTitle = styled.div`
  font-weight: 500;
  font-size: ${units(7)};
  line-height: ${units(10)};
  padding-bottom: ${units(8)};
`;

const StyledInputLeftTitle = styled.div`
  height: 33px;
  align-items: center;
  font-weight: 400;
  font-size: ${units(7)};
  line-height: ${units(10)};
  width: ${units(77)};
  margin-bottom: ${units(3.7)};
`;

const controls = {
    [TypesFields.INPUT]: InputControl,
    [TypesFields.PASSWORD]: PasswordControl,
    [TypesFields.TEXTAREA]: TextareaControl,
    [TypesFields.DATEPICKER]: DatePickerControl,
    [TypesFields.DIVIDER]: DividerControl,
    [TypesFields.SELECT_MI]: SelectControl,
    [TypesFields.RANGE]: RangeControl,
    [TypesFields.CHECK_GROUP]: CheckGroupControl,
};

export interface FormFieldProps {
  form: FormikProps<Record<string, keyof FieldValues>>;
  field: FieldProps;
  submitCount?: number;
  isRow?: boolean;
}

export const FormField = ({
    field,
    form,
    isRow = false,
}: FormFieldProps) => {
    const {
        name,
        prefix,
        postfix,
        type,
        className,
        placeholder,
        title,
        titleLeft,
        option,
        sizeLimitation,
        IsIssuingOrders,
        positionTop,
        checkOptions,
        disableUntilСurrentDate,
        inputRestriction,
    } = field;

    const {
        touched,
        errors,
        values,
        handleChange,
        setFieldValue,
    } = form;

    // убрать когда добавим все контролы
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Control = controls[type];
    const hasErrorMessage = errors?.[name];
    const errorMessage = hasErrorMessage ? errors?.[name] : '';

    const InputTitle = title ? (
        <StyledInputTitle>
            {title}
        </StyledInputTitle>
    ) : null;

    const InputLeftTitle = titleLeft ? (
        <StyledInputLeftTitle>
            {titleLeft}
        </StyledInputLeftTitle>
    ) : null;

    return (
        <StyledField isRow={isRow} titleLeft={!!titleLeft}>
            {InputTitle}
            {InputLeftTitle}
            <Control
                key={name}
                name={name}
                value={values[name] || ''}
                prefix={prefix}
                postfix={postfix}
                onChange={handleChange}
                error={String(errorMessage)}
                touched={touched}
                setValue={setFieldValue}
                className={className}
                placeholder={placeholder}
                option={option}
                sizeLimitation={sizeLimitation}
                IsIssuingOrders={IsIssuingOrders}
                positionTop={positionTop}
                checkOptions={checkOptions}
                disableUntilСurrentDate={disableUntilСurrentDate}
                inputRestriction={inputRestriction}
            />
        </StyledField>
    );
};
