import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { OrderProduct } from '../../types/Models/OrderProduct';

const selectRoot = ({ orderProducts }: RootState) => orderProducts;

export const selectOrderProduct = createSelector(
    selectRoot,
    orderProducts => {
        let orderProductObject: { [x: string]: OrderProduct; } = {};

        Object.entries(orderProducts).forEach(([key, value]) => {
            orderProductObject[key] = value;
        });

        return orderProductObject;
    },
);

export const selectOrderProductArray = createSelector(
    selectRoot,
    orderProducts => Object.values(orderProducts).map(orderProduct => orderProduct),
);

export const selectOrderProductForBE = createSelector(
    selectRoot,
    orderProducts => {
        let orderProductObjectFroBE: { [x: string]: number; } = {};

        Object.entries(orderProducts).forEach(([key, value]) => {
            orderProductObjectFroBE[key] = value.count;
        });

        return orderProductObjectFroBE;
    },
);
