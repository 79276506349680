import { useCallback } from 'react';
import { Month } from '../constants/Month';
import i18n from '../i18n';

const t = i18n.getFixedT(null, 'common', 'monthName');

const monthNames = () => ({
    [Month.JANUARY]: t('JANUARY'),
    [Month.FEBRUARY]: t('FEBRUARY'),
    [Month.MARCH]: t('MARCH'),
    [Month.APRIL]: t('APRIL'),
    [Month.MAY]: t('MAY'),
    [Month.JULY]: t('JULY'),
    [Month.JUNE]: t('JUNE'),
    [Month.AUGUST]: t('AUGUST'),
    [Month.SEPTEMBER]: t('SEPTEMBER'),
    [Month.OCTOBER]: t('OCTOBER'),
    [Month.NOVEMBER]: t('NOVEMBER'),
    [Month.DECEMBER]: t('DECEMBER'),
});

interface Date {
  month: Month,
  year: number,
}

interface UseMonthProps {
  activeDate: Date,
  onChange?: ({ month, year }: Date) => void
}

export const useMonth = ({ activeDate, onChange }: UseMonthProps) => {
    const toPrevMonth = useCallback(() => {
        const prevMount = activeDate.month - 1;
        const prevYear = activeDate.year - 1;

        if (onChange) {
            if (prevMount < Month.JANUARY) {
                onChange({
                    month: Month.DECEMBER,
                    year: prevYear,
                });

                return;
            }

            onChange({
                month: prevMount,
                year: activeDate.year,
            });
        }
    }, [activeDate, onChange]);

    const toNextMonth = useCallback(() => {
        const nextMount = activeDate.month + 1;
        const nextYear = activeDate.year + 1;

        if (onChange) {
            if (nextMount > Month.DECEMBER) {
                onChange({
                    month: Month.JANUARY,
                    year: nextYear,
                });

                return;
            }

            onChange({
                month: nextMount,
                year: activeDate.year,
            });
        }
    }, [activeDate, onChange]);

    return {
        monthName: monthNames()[activeDate.month],
        toNextMonth,
        toPrevMonth,
    };
};
