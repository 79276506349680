import { request } from '../../helpers/api/request';
import { OrderCardContract, OrderCardContractParams } from '../../types/Endpoints/OrderCardContract';

export const getUserOrders = async (params: OrderCardContractParams) => {
    const { data } = await request<OrderCardContract>({
        url: `users/${params.id}/orders`,
        params: {
            date_pickup_sort: params.date_pickup_sort,
        },
    });

    return data;
};
