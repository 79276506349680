import { request } from '../../helpers/api/request';
import { CategoryFiltersContract } from '../../types/Endpoints/CategoryFiltersContract';

export const getFilters = async (id: number, isModal?: boolean) => {
    const { data } = await request<CategoryFiltersContract>({
        url: isModal ? `categories/${id}/filters?by_shop=1` : `categories/${id}/filters`,
    });

    return data;
};
