import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as userApi from '../../../api/user';
import { RefreshTokenContract } from '../../../types/Endpoints/RefreshTokenContract';
import { setSessionToStorage } from '../../../helpers/auth/session';

export const REFRESH_TOKEN = 'user/refreshToken';

const onSuccess = (result: any) => {
    setSessionToStorage(result);
    document.location.reload();
};

export const refreshToken = createAsyncThunk(
    REFRESH_TOKEN,
    createRequest({
        type: REFRESH_TOKEN,
        onSuccess,
        loader: (refreshData: RefreshTokenContract) => userApi.refreshToken(refreshData),
    }),
);
