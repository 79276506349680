import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as userApi from '../../../api/users';
import { CreateUsersData } from '../../../types/Endpoints/CreateUser';
import { UsersCardContractParams } from '../../../types/Endpoints/UsersCardContract';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { Thunk } from '../../../helpers/redux/Thunk';

export const UPDATE_USER = 'user/updateUser';

const useOnSuccess = (result: any): Thunk => async dispatch => {
    if (result.status) {
        window.location.href = `/client-card/${result.id}`;
    }
    if (!result.status) {
        await dispatch(addNotification({
            type: TypesSnackbars.SUCCESS,
            message: {
                isMistake: true,
                description: 'Проверьте введенные данные',
            },
        }));
    }
};

const onFail = (): Thunk => async dispatch => {
    await dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            isMistake: true,
            description: 'Проверьте введенные данные',
        },
    }));
};

export const updateUser = createAsyncThunk(
    UPDATE_USER,
    createRequest({
        type: UPDATE_USER,
        loader: ({ params, userData } : { params: UsersCardContractParams, userData: CreateUsersData}) => userApi.updateUser(params, userData),
        onSuccess: useOnSuccess,
        onFail,
        showFailNotification: false,
    }),
);
