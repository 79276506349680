import { TFunction } from 'i18next';
import { ClientInformationProps } from '../../../types/Client/ClientInformationProps';
import { UsersModal } from '../../../types/Models/UsersModal';
import { formatOrderDay } from '../../../helpers/date';
import { TypesIcon } from '../../../types/TypesIcon';

export const getClientFields = (t: TFunction, user: UsersModal | undefined): ClientInformationProps[] => ([
    {
        key: 'id',
        title: t('sentences.iD'),
        value: user?.id || '',
    },
    {
        key: 'name',
        title: t('sentences.name'),
        value: user?.name || '',
    },
    {
        key: 'telephone',
        title: t('sentences.telephone'),
        value: user?.phone.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3 $4 $5') || '',
    },
    {
        key: 'sex',
        title: t('sentences.sex'),
        value: user?.gender ? t(`sentences.${user?.gender}`) : '',
        imageBeforeText: user?.gender === 'women' ? TypesIcon.FEMALE : TypesIcon.FEMALE,
    },
    {
        key: 'birthdayDate',
        title: t('sentences.birthdayDate'),
        // eslint-disable-next-line no-nested-ternary
        value: (user?.status === 'mobile') ? (user?.birthday ? formatOrderDay(user?.birthday) : '') : '',
    },
    {
        key: 'city',
        title: t('sentences.city'),
        value: user?.city || '',
    },
    {
        key: 'numberOfCard',
        title: t('sentences.numberOfCard'),
        value: user?.loyalty_card || '',
    },
    {
        key: 'catalogView',
        title: t('sentences.catalogView'),
        value: user?.extended_catalog_view || '',
    },
    {
        key: 'status',
        title: t('sentences.status'),
        value: (user?.status === 'mobile') ? 'Из приложения' : 'По звонку',
    },
]);
