import { createReducer } from '@reduxjs/toolkit';
import { getShop } from '../../actions/shop';
import { ShopListModel } from '../../types/Models/ShopListModel';
import { addEmptyArrayShopList } from '../../actions/shop/setEmtyArray';

interface ShopList {
    shopList?: ShopListModel | undefined;
}

export const shops = createReducer<ShopList>({}, builder => {
    builder.addCase(getShop.fulfilled, (state, { payload }) => ({
        ...state,
        shopList: payload,
    }));
    // eslint-disable-next-line no-empty-pattern
    builder.addCase(addEmptyArrayShopList, (state, { }) => ({
        shopList: undefined,
    }));
});
