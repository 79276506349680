import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';

const hoverBgByType: Record<string, string> = {
    [TypesButton.FILL]: colors.blue2,
    [TypesButton.OUTLINE]: colors.blue_10,
    [TypesButton.OUTLINE_BORDER]: colors.blue_10,
    [TypesButton.OUTLINE_BORDER_GREY]: `2px solid ${colors.gray2};`,

};

export const applyHoverBgColor = ({ typeButton, disabled }: { typeButton: TypesButton, disabled?: boolean }) => (
    !disabled
        ? `
          &:hover {
            background: ${hoverBgByType[typeButton]}};
          }
        `
        : '');
