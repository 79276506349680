import styled from 'styled-components';
import { useState } from 'react';
import { useFormik } from 'formik';
import { PageTitle } from '../../components/PageTitle';
import { units } from '../../helpers/styles/units';
import { CustomizedMenus } from './TableFilters/CustomizedMenus';
import { TableCatalog } from './TableCatalog';
import { CustomizedMenuSchema } from './TableFilters/CustomizedMenus/CustomizedMenuSchema';

interface Formik {
    selectPrice: string[];
    selectAvailability: string[];
}

type FormValues = Partial<Formik>;

const StyledPageTitle = styled(PageTitle)`
  margin-top: ${units(14)};
`;

const StyledWrapperCustomized = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`;

export const Catalog = ({ isModal }: {isModal?: boolean}) => {
    const [searchOrder, setSearchOrder] = useState<string>('');

    const handleSubmitMenu = (values: FormValues) => {
        console.log(values);
    };

    const formMenu = useFormik<FormValues>({
        onSubmit: handleSubmitMenu,
        initialValues: {
            selectPrice: ['', '', '', ''],
            selectAvailability: ['', '', '', ''],
        },
        validationSchema: CustomizedMenuSchema(),
    });

    return (
        <>
            <StyledPageTitle>
                Каталог товаров
            </StyledPageTitle>
            <StyledWrapperCustomized>
                <CustomizedMenus
                    isModal={isModal}
                    formMenu={formMenu}
                    searchOrder={searchOrder}
                    setSearchOrder={setSearchOrder}
                />
            </StyledWrapperCustomized>
            <TableCatalog
                formMenu={formMenu}
                searchOrder={searchOrder}
            />
        </>

    );
};
