import { TFunction } from 'i18next';
import { ControlProps } from '../../../types/Form/ControlProps';
import { TypesFields } from '../../../types/TypesFields';
import { FiltersMenuProps } from '../../../types/Filters/FiltersMenuProps';

export const getFieldsIssuingOrders = (
    t: TFunction,
    optionShop: FiltersMenuProps[],
    optionTime: FiltersMenuProps[],
): ControlProps[] => ([
    {
        key: 'selectOptionShop',
        type: TypesFields.SELECT_MI,
        name: 'selectOptionShop',
        className: 'selectOptionShop',
        option: optionShop,
        sizeLimitation: false,
        titleLeft: 'Магазин',
        IsIssuingOrders: true,
    },
    {
        type: TypesFields.DATEPICKER,
        name: 'DatePicker',
        titleLeft: 'Дата самовывоза',
        disableUntilСurrentDate: true,
    },
    {
        key: 'selectOptionTime',
        type: TypesFields.SELECT_MI,
        name: 'selectOptionTime',
        className: 'selectOptionTime',
        option: optionTime,
        sizeLimitation: false,
        titleLeft: 'Время самовывоза',
        IsIssuingOrders: true,
    },
]);
