import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as loyaltyInformationApi from '../../../api/loyaltyInformation';
import { UsersCardContractParams } from '../../../types/Endpoints/UsersCardContract';

export const GET_LOYALTY_INFORMATION = 'user/getLoyaltyInformation';

export const getLoyaltyInformation = createAsyncThunk(
    GET_LOYALTY_INFORMATION,
    createRequest({
        type: GET_LOYALTY_INFORMATION,
        loader: (params: UsersCardContractParams) => loyaltyInformationApi.getLoyaltyBonus(params),
        showFailNotification: false,
    }),
);
