import { TFunction } from 'i18next';
import { ClientInformationProps } from '../../../types/Client/ClientInformationProps';
import { ProductModel } from '../../../types/Models/ProductTableModel';

export const getProductIntroductionFields = (t: TFunction, product: ProductModel | undefined): ClientInformationProps[] => ([
    {
        key: 'shop',
        title: t('sentences.shop'),
        value: product?.shop_address || '',
    },
    {
        key: 'Availability',
        title: t('sentences.Availability'),
        value: `${product?.quantity} шт.`,
    },
    {
        key: 'Reservation',
        title: t('sentences.Reservation'),
        value: `${product?.reserved_quantity} шт.`,
    },
    {
        key: 'Remainder',
        title: t('sentences.Remainder'),
        value: `${product?.available_quantity} шт.`,
    },
]);
