import React,
{
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import { DatePickerControlProps } from '../../types/Form/DatePickerControlProps';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { Input } from '../Input';
import { Icon } from '../Icon';
import { formatOrderDay } from '../../helpers/date';
import { useOutSideClick } from '../../hooks/useOutSideClick';
import { TypesIcon } from '../../types/TypesIcon';
import { DatePicker } from '../DatePicker';

interface DatePickerRangeProps extends Omit<DatePickerControlProps, 'onChange' | 'title'> {
    onChange?: (field: string, value: string, shouldValidate?: boolean) => void;
    value?: string;
    title?: string;
    className?: string;
}

interface IDatePickerWrapper {
    positionTop?: boolean;
}

const StyledWrapper = styled.div`
  box-sizing: border-box;

  display: flex;
  align-items: center;

  input {
    cursor: pointer;
  }
`;

const StyledField = styled.div`
  width: 100%
`;

const StyledDatePickerWrapper = styled.div<IDatePickerWrapper>`
  position: absolute;
  z-index: 100;

  width: 90%;
  bottom: ${({ positionTop }) => (positionTop ? '65px' : 'initial')};
  margin-top: ${units(5)};
`;

const StyledErrorMessage = styled.span`
  position: absolute;
  top: ${units(13)};

  color: ${colors.red};
  font-weight: 600;
  ${typography(0)};
`;

const StyledInput = styled(Input)`
  background-color: transparent;
`;

const StylePostfixIcon = styled(Icon)`
  cursor: pointer;
`;

export const DatePickerRange = ({
    name,
    value,
    postfix,
    error,
    onChange,
    className,
    positionTop,
    disableUntilСurrentDate,
    ...props
}: DatePickerRangeProps) => {
    const [activeDay, setActiveDay] = useState(false);
    const [disabledDates, setDisabledDates] = useState<string | undefined>('');

    const formattedValue = value ? formatOrderDay(value || disabledDates as string) : '';

    const refDatePickerFrom = useRef<HTMLDivElement>(null);

    const handleOutSideClickFrom = useCallback((event: Event) => {
        if (!refDatePickerFrom.current?.contains(event.target as Node)) {
            setActiveDay(false);
        }
    }, []);

    useOutSideClick(refDatePickerFrom, handleOutSideClickFrom);

    useEffect(() => {
        setDisabledDates(value);
    }, [value]);

    const handleSuccess = useCallback((newValue: string) => {
        if (onChange) {
            onChange(name, newValue, false);
        }

        setDisabledDates(newValue);
        setActiveDay(false);
    }, [name, onChange, value]);

    const handleClick = () => {
        setActiveDay(true);
    };

    const handleClose = () => {
        if (onChange) {
            onChange(name, '', false);
        }

        setActiveDay(false);
    };

    return (
        <StyledWrapper className={className}>
            <StyledField ref={refDatePickerFrom}>
                <StyledInput
                    name={name}
                    value={formattedValue}
                    postfix={postfix
                        || (
                            <StylePostfixIcon
                                type={TypesIcon.CALENDAR}
                                fill={colors.gray}
                            />
                        )}
                    onOpen={handleClick}
                    {...props}
                />
                {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
                {activeDay
                    && (
                        <StyledDatePickerWrapper
                            positionTop={positionTop}
                        >
                            <DatePicker
                                name={name}
                                onClose={handleClose}
                                onSuccess={handleSuccess}
                                activeDate={value}
                                disabledDates={{ date: disabledDates, name }}
                                disableUntilСurrentDate={disableUntilСurrentDate}
                            />
                        </StyledDatePickerWrapper>
                    )}
            </StyledField>
        </StyledWrapper>
    );
};
