import { request } from '../../helpers/api/request';
import { OrdersContract, OrdersContractParams } from '../../types/Endpoints/OrdersContract';

export const getOrders = async (params: OrdersContractParams) => {
    const { data } = await request<OrdersContract>({
        url: 'orders/filter',
        params,
    });

    return data;
};
