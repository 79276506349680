import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as ordersApi from '../../../api/orders';
import { OrderCardContractParams } from '../../../types/Endpoints/OrderCardContract';
import { Thunk } from '../../../helpers/redux/Thunk';

export const DELETE_ORDER = 'order/deleteOrder';

const onSuccess = (): Thunk => async dispatch => {
    window.location.href = '/orders';
};

export const deleteOrder = createAsyncThunk(
    DELETE_ORDER,
    createRequest({
        type: DELETE_ORDER,
        loader: (params: OrderCardContractParams) => ordersApi.deleteOrder(params),
        onSuccess,
        showFailNotification: false,
    }),
);
