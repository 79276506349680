import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled as MaterialStyled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { units } from '../../../helpers/styles/units';
import { TypesButton } from '../../../constants/ButtonTypes';
import { Button } from '../../../components/Button';
import { TableWrapper } from '../../../components/TableWrapper';
import { getTableHead } from './getTableHead';
import { colors } from '../../../constants/Colors';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { typography } from '../../../helpers/styles/typography';
import { Modal } from '../../../components/ModalLeg';
import { ModalProductTable } from './ModalProductTable/ModalProductTable';
import { OrderProduct } from '../../../types/Models/OrderProduct';
import { changeOrderProduct, removeOrderProduct } from '../../../actions/orderProduct';
import { ProductModel } from '../../../types/Models/ProductTableModel';
import { useTypeSelector } from '../../../store';
import { addModal } from '../../../actions/modal';
import { ModalTypes } from '../../../types/ModalTypes/ModalTypes';
import { selectLoyaltyInformation } from '../../../selectors/loyaltyInformation';
import { AvailableCircle } from '../../../components/AvailableCircle';
import { config } from '../../../config';
import PlaceholderForImage from '../../../assets/placeholderForImage.png';
import { OrderCardModel } from '../../../types/Models/OrderCardModel';
import { TypesOrder } from '../../../types/TypesOrder';
import { getPriceProductsForShop } from '../../../actions/orders/thunks/getPriceProductsForShop';
import { selectOrderPrices } from '../../../selectors/orderCard';
import { productPriceToString } from '../../../helpers/generators/productPriceGenerator';

interface IOrderContentsTable {
    orderProductArray: OrderProduct[]
    order?: OrderCardModel
    idShop?: string
}

const StyledOrderComposition = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${units(24)};
  margin-bottom: ${units(14)};
`;

const StyledNumberOfOrderItems = styled.div`
  font-weight: 500;
  font-size: ${units(10)};
  line-height: ${units(14)};
`;

const StyledButtonDrawer = styled(Button)`
  width: 100%;
  max-width: ${units(95)};
  display: flex;
  flex-direction: row;
  transition: all 0.5s;

  span{
    margin-right: ${units(10)};
  }
  
  svg {
    margin-right: ${units(4)};
  }
`;

const StyledOrderCostCalculation = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFieldCostCalculation = styled.div`
  display: flex;
  justify-content: end;
  margin-right: ${units(12)};

  .field_name {
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-right: ${units(3)};
    line-height: 16px;
    color: ${colors.gray};
    
    &_total {
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .value_name {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    min-width: ${units(39)};
    justify-content: end;
    align-items: center;

    &_total {
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  
  .field_name_total {
    margin-right: ${units(3)};
  }
`;

const StyledTableCell = MaterialStyled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        position: 'relative',
        fontFamily: '\'TTNormsPro\', sans-serif',
        fontSize: '14px',
        lineHeight: '20px',
        color: colors.dark,
        letterSpacing: '0.4px',
        paddingRight: '2px',
        paddingLeft: '2px',
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '12px',
            height: '-webkit-fill-available',
            bottom: '0px',
            left: '-12px',
            background: 'transparent',
        },
    },
}));

const StyledImageGoods = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: ${units(28)};
  margin-right: ${units(6)};
  
  img {
    height: 100%;
    max-height: ${units(28)};
    cursor: pointer;
  }
`;

const StyledNameGoods = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  border: 2px solid transparent;

  &:hover {
    color: ${colors.blue};
    border-bottom: 2px solid ${colors.blue}
  }

  &:active {
    color: ${colors.blue};
    border-bottom: 2px solid ${colors.blue}
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: ${units(3)} ${units(6)};
  margin: ${units(4)} 0;
  gap: ${units(2)};
  max-width: ${units(47)};
  max-height: ${units(16)};

  font-weight: ${FontWeight.MEDIUM};
  color: ${colors.dark};

  background: ${colors.white};
  border-radius: ${units(6)};
  border: 0;

  cursor: pointer;

  ${typography(5)}
  :hover {
    color: ${colors.blue};
    background: ${colors.whiteBlue};
  }

  &:hover svg {
    fill: ${colors.blue} !important;
  }
`;

const WrapperBottomTable = styled.div`
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;

  & > svg {
    position: relative;
    bottom: -5px;
  }
`;

const StyledButtonDeleteGoods = styled.div<{disabled?: boolean}>`
  cursor:${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  svg{
    fill: ${colors.gray10};
  }

  :hover{
    svg{
      fill: ${({ disabled }) => (disabled ? colors.gray10 : colors.gray20)};
    }
  }
`;

const StyledWrapperTextField = styled.div`
  .MuiOutlinedInput-root{
    border-radius: ${units(7)};
    height: ${units(18)};
    width: ${units(36)};
    color:  rgba(38, 49, 79, 1);
  }
`;

const StyledMessageNoProductsWrapper = styled.div`
    height: ${units(40)};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledMessage = styled.div`
  width: ${units(160)};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

const StyledNotShopMessage = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${colors.red};
`;

export const OrderContentsTable = ({ orderProductArray, order, idShop }: IOrderContentsTable) => {
    const { t } = useTranslation('table');
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const loyaltyInformation = useTypeSelector(selectLoyaltyInformation);
    const orderPrices = useTypeSelector(selectOrderPrices);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const positionCostCalculate = (product: OrderProduct, count?: number) => {
        if (!product.orderProducts.price) {
            return '-';
        }

        if (!!idShop && !!orderPrices) {
            const currentProduct = orderPrices.find(item => +item.id === +product.id);
            return currentProduct
                ? `${(currentProduct.price * (count || 1)).toFixed(2)} руб.`
                : '-';
        }

        // eslint-disable-next-line
        return typeof product.orderProducts.price === 'number'
            ? `${product.orderProducts.price} руб.`
            : product.orderProducts.price.min_price === product.orderProducts.price.max_price
                ? `${(product.orderProducts.price.max_price * (count || 1)).toFixed(2)} руб.`
                : `${(product.orderProducts.price.min_price * (count || 1)).toFixed(2)} - ${(product.orderProducts.price.max_price * (count || 1)).toFixed(2)} руб.`;
    };

    const onChangeProductCount = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        id: number,
        product: ProductModel,
    ) => {
        if (order?.status !== TypesOrder.READY && order?.status !== TypesOrder.RECEIVED && order?.status !== TypesOrder.CANCELLED) {
            dispatch(changeOrderProduct({ id, count: +e.target.value, orderProducts: product }));
        }
    };

    const onSuccess = (id: number) => {
        dispatch(removeOrderProduct(id));
    };

    const handleOpenModal = React.useCallback((id: number) => {
        if (order?.status !== TypesOrder.READY && order?.status !== TypesOrder.RECEIVED && order?.status !== TypesOrder.CANCELLED) {
            dispatch(addModal({
                id: 1,
                type: ModalTypes.EDITING_PERSONAL_DATA,
                title: 'Удаление товара',
                onSuccess: () => onSuccess(id),
                description: 'Вы уверены, что хотите удалить товар из корзины?',
            }));
        }
    }, [dispatch, order?.status]);

    const AllPrice = orderProductArray.reduce(
        (accumulator, currentValue) => {
            if (orderPrices && currentValue.orderProducts.price) {
                const productWithShop = orderPrices.find(item => +item.id === +currentValue.id);
                return accumulator + (currentValue.count * (productWithShop ? productWithShop.price : 0));
            }
            return 0;
        },
        0,
    );

    const handleRemoving = (count:number, productId:number) => {
        if (count === 0) {
            handleOpenModal(productId);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line
        const productsList = orderProductArray.map(product => {
            return { product_id: Number(product.id) };
        });

        if (idShop && orderProductArray.length) {
            // eslint-disable-next-line
            dispatch(getPriceProductsForShop({ shopId: Number(idShop), productsList }));
        }
    }, [idShop, orderProductArray]);

    return (
        <>
            <StyledOrderComposition>
                <StyledNumberOfOrderItems>
                    Состав заказа ({orderProductArray.length} позиции)
                </StyledNumberOfOrderItems>
                <StyledButtonDrawer
                    typeButton={TypesButton.FILL}
                    onClick={handleClickOpen}
                    disabled={order?.status === TypesOrder.READY || order?.status === TypesOrder.RECEIVED || order?.status === TypesOrder.CANCELLED}
                >
                    <Icon
                        type={TypesIcon.ADD}
                        width="20px"
                        height="20px"
                        fill={colors.white}
                    />
                    <span>
                        {t('buttons.addProduct')}
                    </span>
                </StyledButtonDrawer>
            </StyledOrderComposition>
            <TableWrapper tableHeadRow={getTableHead(t)}>
                {orderProductArray.map(product => (
                    <TableRow
                        key={product.id}
                        sx={{
                            '&:hover': {
                                boxShadow: '0px 0px 12px rgba(130, 141, 171, 0.16)',
                                borderRadius: '30px',
                            },
                            '& :first-child': {
                                textAlign: 'center',
                            },
                            '& > td': {
                                border: 0,
                            },
                        }}
                    >
                        <StyledTableCell align="left">
                            {product.id}
                        </StyledTableCell>
                        <StyledTableCell
                            sx={{ display: 'flex', alignItems: 'center' }}
                            align="left"
                            onClick={() => history.push(`/products-card/${product.id}`)}
                        >
                            <StyledImageGoods>
                                <img
                                    alt="изображение товара"
                                    src={product?.orderProducts?.images_links[0] ? `${config.commonUrl}/${product?.orderProducts?.images_links[0]}` : PlaceholderForImage}
                                />
                            </StyledImageGoods>
                            <StyledNameGoods>
                                {`${product.orderProducts.name} (${product.orderProducts.volume ? `${product.orderProducts.volume}л` : `${product.orderProducts.weight}кг`})`}
                            </StyledNameGoods>
                        </StyledTableCell>
                        <StyledTableCell
                            align="left"
                            sx={{
                                color: !orderPrices?.find(item => +item.id === +product.id) ? 'red !important' : 'inherit',
                            }}
                        >
                            <AvailableCircle available={!!orderPrices?.find(item => +item.id === +product.id)} />
                            {orderPrices?.find(item => +item.id === +product.id) ? 'В наличии' : 'Нет в наличии'}
                        </StyledTableCell>

                        <StyledTableCell sx={{ fontWeight: 500 }} align="left">
                            {positionCostCalculate(product)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <StyledWrapperTextField>
                                <TextField
                                    onChange={event => onChangeProductCount(
                                        event,
                                        product.id,
                                        product.orderProducts,
                                    )}
                                    disabled={order?.status === TypesOrder.READY || order?.status === TypesOrder.RECEIVED || order?.status === TypesOrder.CANCELLED}
                                    onBlur={() => handleRemoving(product.count, product.id)}
                                    value={product.count}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        },
                                    }}
                                    type="number"
                                />
                            </StyledWrapperTextField>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            {product.orderProducts.available_quantity}
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 500 }} align="left">
                            {positionCostCalculate(product, product.count)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <StyledButtonDeleteGoods
                                disabled={order?.status === TypesOrder.READY || order?.status === TypesOrder.RECEIVED || order?.status === TypesOrder.CANCELLED}
                                onClick={() => handleOpenModal(product.id)}
                            >
                                <Icon
                                    type={TypesIcon.DELETE_BASKET}
                                    width="20px"
                                    height="20px"
                                />
                            </StyledButtonDeleteGoods>
                        </StyledTableCell>
                    </TableRow>
                ))}
                { orderProductArray.length < 1
                    ? (
                        <TableRow>
                            <TableCell
                                sx={{
                                    borderTop: `1px solid ${colors.gray3}`,
                                    position: 'static',
                                    padding: '48px 0',
                                }}
                                colSpan={8}
                                align="center"
                            >
                                <StyledMessageNoProductsWrapper>
                                    <StyledMessage>
                                        В заказе еще нет товаров, добавьте их с помощью кнопки “Добавить товар”.
                                    </StyledMessage>
                                </StyledMessageNoProductsWrapper>
                            </TableCell>
                        </TableRow>
                    ) : null}
                <TableRow>
                    <TableCell
                        sx={{
                            borderTop: `1px solid ${colors.gray3}`,
                            position: 'static',
                            padding: '48px 0',
                            borderBottom: '0',
                        }}
                        colSpan={8}
                        align="center"
                    >
                        {idShop
                            ? (
                                <StyledOrderCostCalculation>
                                    <StyledFieldCostCalculation>
                                        <div className="field_name">Стоимость заказа</div>
                                        <div className="value_name">{AllPrice.toFixed(2) || 0} руб.</div>
                                    </StyledFieldCostCalculation>
                                    <StyledFieldCostCalculation>
                                        <div className="field_name">Скидка, на сопутствующие товары</div>
                                        <div className="value_name">{loyaltyInformation?.discount} %</div>
                                    </StyledFieldCostCalculation>
                                    <StyledFieldCostCalculation>
                                        <div className="field_name">Доступно баллов к списанию</div>
                                        <div className="value_name">{loyaltyInformation?.bonus} баллов</div>
                                    </StyledFieldCostCalculation>
                                    <StyledFieldCostCalculation>
                                        <div className="field_name_total">Итого:</div>
                                        <div className="value_name_total">{AllPrice.toFixed(2) || 0} руб.</div>
                                    </StyledFieldCostCalculation>
                                </StyledOrderCostCalculation>
                            )
                            : (<StyledNotShopMessage>Выберите магазин, чтобы узнать сумму заказа</StyledNotShopMessage>)}
                    </TableCell>
                </TableRow>
            </TableWrapper>
            {open && (
                <Modal setOpenModel={setOpen}>
                    <ModalProductTable idShop={idShop} />
                </Modal>
            )}
        </>
    );
};
