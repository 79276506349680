import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled as MaterialStyled } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/Colors';
import { HeaderTableProps } from '../../types/Table/HeaderTableProps';
import { units } from '../../helpers/styles/units';
import { Icon } from '../Icon';

interface ITableWrapper{
    tableHeadRow: HeaderTableProps[],
    onClickSort?: () => void,
}

const StyledTableCell = MaterialStyled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 500,
        fontFamily: '\'TTNormsPro\', sans-serif',
        fontSize: '12px',
        lineHeight: '16px',
        color: colors.dark,
        letterSpacing: '0.4px',
    },
}));

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableWrapper = ({
    children,
    tableHeadRow,
    onClickSort = () => console.log(''),
} : PropsWithChildren<ITableWrapper>) => (
    <TableContainer component={Paper} sx={{ borderRadius: '16px' }}>
        <Table
            sx={{
                minWidth: 650,
                overflow: 'hidden',
                borderCollapse: 'separate',
                '-webkit-border-vertical-spacing': '12px',
                '-webkit-border-horizontal-spacing': '12px',
                '& thead > tr > th': {
                    paddingTop: '8px',
                },
            }}
            aria-label="simple table"
        >
            <TableHead>
                <TableRow sx={{ position: 'relative' }}>
                    {tableHeadRow.map((row: HeaderTableProps) => (
                        <StyledTableCell
                            sx={{ width: units(row.width) }}
                            key={row.key}
                            align="left"
                        >
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <div
                                key={row.key}
                                onClick={() => onClickSort()}
                                style={{ display: 'flex', cursor: row.icon ? 'pointer' : '' }}
                            >
                                {row.name}
                                { row.icon
                                    ? (
                                        <StyledIcon>
                                            <Icon
                                                type={row.icon}
                                                width="15px"
                                                height="15px"
                                                fill={colors.dark}
                                            />
                                        </StyledIcon>
                                    ) : null}
                            </div>
                        </StyledTableCell>
                    )) }
                </TableRow>
            </TableHead>
            <TableBody>
                {children}
            </TableBody>
        </Table>
    </TableContainer>
);
