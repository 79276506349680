import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import { TimespansContractParams } from '../../../types/Endpoints/TimespansContract';
import * as timespansApi from '../../../api/timespans';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addEmptyTimespans } from '../setEmtyArray';

export const GET_TIMESPANS = 'timespans/getTimespans';

const onFail = (): Thunk => async dispatch => {
    dispatch(addEmptyTimespans());
};

export const getTimespans = createAsyncThunk(
    GET_TIMESPANS,
    createRequest({
        type: GET_TIMESPANS,
        loader: (params: TimespansContractParams) => timespansApi.getTimespans(params),
        onFail,
        showFailNotification: false,
    }),
);
