import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { routes } from '../../constants/RouterPath';
import { colors } from '../../constants/Colors';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { units } from '../../helpers/styles/units';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../store';
import { selectUsersByPhone } from '../../selectors/currentUser';

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${units(10)} 0;
`;

const StyledNavLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${units(6)};

  color: ${colors.dark};
  border-left: 2px solid transparent;
  
  .active {
    color: ${colors.blue};
    border-left: 2px solid ${colors.blue};
    svg {
      fill: ${colors.blue};
    }
  }
  
  font-weight: ${FontWeight.MEDIUM};
  
  text-decoration: none;

  &.active {
    padding-left: 0 ${units(5)} 0 ${units(6)};
    
    border-left: 2px solid ${colors.blue};

    color: ${colors.blue};
  }
  
  &.active svg {
    fill: ${colors.blue} !important;
  }
`;

const StyledNavLinkText = styled.div`
  display: flex;
  align-items: center;
  padding: ${units(6)} ${units(9)};
  width: 100%;
  white-space: nowrap;

  border-radius: ${units(4)};
  
  ${typography(5)};

  & svg {
    margin-right: ${units(6)};
  }

  &:hover svg {
    fill: ${colors.blue} !important;
  }

  :hover {
    color: ${colors.blue};

    background: ${colors.whiteBlue};
  }
`;

const StyledNavLinkNewOrder = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${units(6)};
  
  & > div{
    font-weight: ${FontWeight.MEDIUM};
    color: ${colors.white};
    background: ${colors.blue};
    
    svg{
      fill: ${colors.white} !important;
    }
    
    &:hover svg {
      fill: ${colors.white} !important;
    }

    :hover {
      color: ${colors.white};
      background: ${colors.blue2};
    }
    
  }

  text-decoration: none;

  &.active {
    padding-left: ${units(5)};
    border-left: 2px solid ${colors.blue};
    color: ${colors.blue};
  }

  &.active svg {
    fill: ${colors.blue} !important;
  }
`;

const StyledNumber = styled.p`
  margin-left: ${units(6)};
  padding: 1px 5px;
  
  border-radius: ${units(3)};
  background: ${colors.blue};

  font-size: 10px;
  line-height: 13px;
  color: ${colors.white};
`;

export const Navigation = () => {
    const { t } = useTranslation('sidebar');
    const orderProductArray = useTypeSelector(selectUsersByPhone);
    const history = useHistory();

    return (
        <StyledNav>
            <StyledNavLinkNewOrder
                to={routes.newOrder}
            >
                <StyledNavLinkText>
                    <Icon
                        type={TypesIcon.ADD_CIRCLE_WHITE}
                        width="20px"
                        height="20px"
                    />
                    {t('sentences.linkNewOrder')}
                </StyledNavLinkText>
            </StyledNavLinkNewOrder>
            <StyledNavLink
                to={routes.orders}
                className={history.location.pathname === routes.orders ? 'active' : ''}
            >
                <StyledNavLinkText>
                    <Icon
                        type={TypesIcon.RECEIPT_DARK}
                        width="20px"
                        height="20px"
                    />
                    {t('sentences.linkListOrders')}
                    <StyledNumber>
                        {orderProductArray?.orders_quantity}
                    </StyledNumber>
                </StyledNavLinkText>
            </StyledNavLink>
            <StyledNavLink
                to={routes.catalog}
                className={history.location.pathname === routes.catalog ? 'active' : ''}
            >
                <StyledNavLinkText>
                    <Icon
                        type={TypesIcon.MALL_WHITE}
                        width="20px"
                        height="20px"
                    />
                    {t('sentences.linkCatalog')}
                </StyledNavLinkText>
            </StyledNavLink>
        </StyledNav>
    );
};
