import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../store';
import { selectUsersByPhone } from '../../selectors/currentUser';

const StyledRoot = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledUserName = styled.div`
  padding: ${units(4)} ${units(5)};
  margin-right: ${units(4)};
  width: ${units(15)};
  width: ${units(15)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.gray};
  border-radius: 50%;

  font-size: 16px;
  line-height: 16px;
  font-weight: ${FontWeight.MEDIUM};
  color: ${colors.white};
`;

const StyledName = styled.p`
  font-weight: ${FontWeight.MEDIUM};
  
  ${typography(5)};
`;

const StyledPosition = styled.p`
  color: ${colors.gray};
  
  ${typography(0)};
`;

export const UserItem = ({ className }: {className?: string}) => {
    const orderProductArray = useTypeSelector(selectUsersByPhone);

    const displayUserRole = (role?: string) => {
        if (role === 'cavist') return `Кавист,${orderProductArray?.shop?.address}`;
        if (role === 'admin') return 'Администратор';
        return 'Менеджер';
    };

    return (
        <StyledRoot className={className}>
            <StyledUserName>
                {orderProductArray?.fio ? orderProductArray?.fio[0] : 'A'}
            </StyledUserName>
            <div>
                <StyledName>
                    {orderProductArray?.fio}
                </StyledName>
                <StyledPosition>
                    {displayUserRole(orderProductArray?.role)}
                </StyledPosition>
            </div>
        </StyledRoot>
    );
};
