import { TFunction } from 'i18next';
import { HeaderTableProps } from '../../../types/Table/HeaderTableProps';
import { TypesIcon } from '../../../types/TypesIcon';

export const getTableHead = (t: TFunction) : HeaderTableProps[] => ([
    {
        key: 'orderNumber',
        name: t('sentences.orderNumber'),
        width: 89,
    },
    {
        key: 'dateOfIssue',
        name: 'Дата выдачи',
        width: 113,
        icon: TypesIcon.UNFOLD_MORE,
    },
    {
        key: 'telephone',
        name: t('sentences.telephone'),
        width: 114,
    },
    {
        key: 'totalAmount',
        name: t('sentences.totalAmount'),
        width: 101,
    },
    {
        key: 'status',
        name: t('sentences.status'),
        width: 65,
    },
]);
