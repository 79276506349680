export enum TypesIcon {
    SUPERVISOR_DARK = 'supervisorDark',
    SUPERVISOR_WHITE = 'supervisorWhite',
    LOGOUT = 'logout',
    ADD = 'add',
    ARROW_UP = 'arrowUp',
    ARROW_DOWN = 'arrowDown',
    SEARCH = 'search',
    DONE = 'done',
    OPEN_IN_NEW = 'openInNew',
    CALENDAR = 'calendar',
    FEMALE = 'female',
    MALE = 'male',
    ADD_CIRCLE_DARK = 'addCircleDark',
    ADD_CIRCLE_WHITE = 'addCircleWhite',
    ARROW_DROP_UP = 'arrowDropUp',
    ARROW_DROP_DOWN = 'arrowDropDown',
    TUNE = 'tune',
    LOCATION = 'location',
    RECEIPT_DARK = 'receiptDark',
    RECEIPT_WHITE = 'receiptWhite',
    MALL_DARK = 'mallDark',
    MALL_WHITE = 'mallWhite',
    ERROR_CIRCLE_DARK = 'errorCircleDark',
    ERROR_CIRCLE_WHITE = 'errorCircleWhite',
    LOGO_TEXT = 'logoText',
    ERROR_CIRCLE = 'errorCircle',
    CLOSE = 'close',
    DELETE_BASKET_DARK = 'deleteBasketDark',
    DELETE_BASKET = 'deleteBasket',
    ARROW_BACK = 'arrowBack',
    EDIT = 'edit',
    EDIT_DARK = 'editDark',
    ARROW_LEFT = 'arrowLeft',
    ARROW_RIGHT = 'arrowRight',
    UNFOLD_MORE = 'unfoldMore',
    CANCEL_CIRCLE_DARK = 'cancelCircleDark',
    CANCEL_CIRCLE_GREY = 'cancelCircleGrey'
}
