import { request } from '../../helpers/api/request';
import { UsersSearchByPhoneContract, UsersSearchByPhoneParams } from '../../types/Endpoints/UsersSearchByPhone';

export const getUserByPhone = async (params: UsersSearchByPhoneParams) => {
    const { data } = await request<UsersSearchByPhoneContract>({
        url: 'users/filter',
        params,
    });

    return data;
};
