import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { routerMiddleware } from 'connected-react-router';
import { history } from './history';
import { isNotProduction } from '../helpers/environment';
import { createRootReducer } from '../reducers';

const isProduction = !isNotProduction();

export const store = configureStore({
    reducer: createRootReducer(history),
    devTools: !isProduction,
    middleware: getDefaultMiddleware => ([...getDefaultMiddleware({
        serializableCheck: false,
    }), routerMiddleware(history)]),
});

// типизация стора
export type RootState = ReturnType<typeof store.getState>;

// хачим диспатч чтобы можно было вызывать экшены не только через хуки
export type AppDispatch = typeof store.dispatch;

// типизация селектора
export const useTypeSelector: TypedUseSelectorHook<RootState> = useSelector;
