import React from 'react';
import styled from 'styled-components';
import { units } from '../../../../helpers/styles/units';
import { Textarea } from '../../../Textarea';
import { TextareaControlProps } from '../../../../types/Form/TextareaControlProps';

const StyledField = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${units(12)};
`;

export const TextareaControl = ({
    name,
    value,
    onChange,
    ...props
}: TextareaControlProps) => (
    <StyledField>
        <Textarea
            name={name}
            value={value || ''}
            onChange={onChange}
            {...props}
        />
    </StyledField>
);
