import { TFunction } from 'i18next';
import { colors } from '../../../../constants/Colors';
import { FiltersMenuProps } from '../../../../types/Filters/FiltersMenuProps';

export const getFilterMenu = (t: TFunction) : FiltersMenuProps[] => ([
    {
        key: 'sent',
        name: t('sentences.sent'),
        color: colors.orange,
        background: colors.orange_10,
    },
    {
        key: 'collection',
        name: t('sentences.collection'),
        color: colors.yellow,
        background: colors.yellow_10,
    },
    {
        key: 'ready',
        name: t('sentences.ready'),
        color: colors.green,
        background: colors.green_10,
    },
    {
        key: 'received',
        name: t('sentences.received'),
        color: colors.blue,
        background: colors.blue_10,
    },
    {
        key: 'cancelled',
        name: t('sentences.cancelled'),
        color: colors.red,
        background: colors.red_10,
    },
]);
