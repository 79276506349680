import React, { useCallback } from 'react';
import styled from 'styled-components';
import { typography } from '../../helpers/styles/typography';
import { colors } from '../../constants/Colors';

interface CheckElementProps {
  name: string;
  title: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (field: string, value?: boolean, shouldValidate?: boolean) => void;
  className?: string;
}

const StyledCheck = styled.label<Pick<CheckElementProps, 'disabled' | 'checked'>>`
  display: flex;
  align-items: center;
  
  background-color: ${({ checked }) => (checked ? colors.blue_30 : colors.white)};
  max-width: fit-content;
  border-radius: 50px; 
  border: 1px solid ${({ checked }) => (checked ? colors.blue : colors.gray3)};
  
  color: ${({ checked }) => (checked ? colors.blue : colors.dark)};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  
  span {
    margin: 1px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  ${typography(5)};
`;

export const CheckElement = ({
    name,
    title,
    disabled,
    checked = false,
    onChange,
    className,
}: CheckElementProps) => {
    const handleClick = useCallback(() => {
        if (onChange) {
            onChange(name, !checked, false);
        }
    }, [name, onChange, checked]);

    return (
        <StyledCheck
            disabled={disabled}
            className={className}
            onClick={handleClick}
            checked={checked}
        >
            <span>
                {title}
            </span>
        </StyledCheck>
    );
};
