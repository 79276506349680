import { TFunction } from 'i18next';
import { ControlProps } from '../../types/Form/ControlProps';
import { TypesFields } from '../../types/TypesFields';

export const getFieldsClientComment = (t: TFunction): ControlProps[] => ([
    {
        key: 'textarea',
        type: TypesFields.TEXTAREA,
        name: 'textarea',
        className: 'commentTextarea',
    },
]);
