import { request } from '../../helpers/api/request';
import { ProductContract, ProductContractParams } from '../../types/Endpoints/ProductContract';

export const getProduct = async (params: ProductContractParams) => {
    const { data } = await request<ProductContract>({
        url: 'products/filter',
        params,
    });

    return data;
};
