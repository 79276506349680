import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const selectRoot = ({ orderCard }: RootState) => orderCard;

export const selectOrderCard = createSelector(
    selectRoot,
    ({ orderCard }) => orderCard,
);

export const selectOrderPrices = createSelector(
    selectRoot,
    ({ orderPrices }) => orderPrices,
);
