import * as React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { DrawerWrapper } from '../../../../components/DrawerWrapper';
import { DrawerSchema } from './DrawerSchema';
import { Form } from '../../../../components/Form';
import { getFieldsDrawer } from './fieldsDrawer';
import { units } from '../../../../helpers/styles/units';
import { addDrawerOrder, removeDrawerOrder } from '../../../../actions/drawerOrder';
import { useTypeSelector } from '../../../../store';
import { selectDrawerOrder } from '../../../../selectors/drawerOrder';

interface IDrawerFilters{
    anchorEl: null | HTMLElement,
    handleClose(): void,
    isForm?: boolean,
    setIsSelectedFilter: (state: boolean) => void;
}

interface Formik {
    idCard: string;
    dateIssue: string;
}

type FormValues = Partial<Formik>;

const FormWrapper = styled.div`
  padding: ${units(10)} ${units(10)} 0;
`;

export const DrawerFilters: React.FC<IDrawerFilters> = ({
    anchorEl,
    handleClose,
    isForm,
    setIsSelectedFilter,
}) => {
    const { t } = useTranslation('filters');
    const dispatch = useDispatch();
    const selectFilterOrder = useTypeSelector(selectDrawerOrder);

    const handleSubmit = (values: FormValues) => {
        console.log(values);
    };

    const form = useFormik<FormValues>({
        onSubmit: handleSubmit,
        initialValues: {
            idCard: selectFilterOrder?.idCart || '',
            dateIssue: selectFilterOrder?.dateOfIssue || '',
        },
        enableReinitialize: true,
        validationSchema: DrawerSchema(),
    });

    const disabledButton = !selectFilterOrder?.idCart && !selectFilterOrder?.dateOfIssue;

    const onClickFindOrder = () => {
        dispatch(addDrawerOrder({
            idCart: form.values.idCard,
            dateOfIssue: form.values.dateIssue,
        }));
    };

    const onClickСancel = () => {
        dispatch(removeDrawerOrder());
    };

    useEffect(() => {
        if (selectFilterOrder?.idCart || selectFilterOrder?.dateOfIssue) {
            setIsSelectedFilter(false);
        } else setIsSelectedFilter(true);
    }, [selectFilterOrder]);

    return (
        <DrawerWrapper
            title={t('sentences.filter')}
            isForm={isForm}
            anchorEl={anchorEl}
            handleClose={handleClose}
            onClickFind={onClickFindOrder}
            onClickСancel={onClickСancel}
            disabledButton={disabledButton}
        >
            <FormWrapper>
                <Form<FormValues>
                    fields={getFieldsDrawer(t)}
                    form={form}
                    contentHeight
                />
            </FormWrapper>
        </DrawerWrapper>
    );
};
