import React from 'react';
import { memoize } from 'lodash';
import { Redirect, RedirectProps, Route } from 'react-router';
import { Search } from 'history';
import { queryParams } from '../../constants/Query';

const applyParam = (oldParams: URLSearchParams, newParams: URLSearchParams, key: string) => {
    const value = oldParams.get(key);

    if (value) {
        newParams.set(key, value);
    }
};

// Оставляет в query-параметрах только нужные
// Возможно, в будущем список оставляемых параметров нужно будет не хороводить,
// а передавать через пропсы
const getSearch = (search: Search) => {
    const searchParams = new URLSearchParams(search);
    const newSearchParams = new URLSearchParams();
    applyParam(searchParams, newSearchParams, queryParams.CATEGORY);
    applyParam(searchParams, newSearchParams, queryParams.VENDOR);
    applyParam(searchParams, newSearchParams, queryParams.PAGE);
    applyParam(searchParams, newSearchParams, queryParams.SORT);
    applyParam(searchParams, newSearchParams, queryParams.FILTER);
    applyParam(searchParams, newSearchParams, queryParams.QUICK_VIEW);

    return newSearchParams.toString();
};

// Мемоизируем результат фукнции. Ключ - первый аргумент функции
const getSearchMemoized = memoize(getSearch);

interface RedirectWithQueryProps extends RedirectProps {
    to: string;
}

export const RedirectWithQuery = ({ to, ...otherProps }: RedirectWithQueryProps) => (
    <Route
        render={props => (
            <Redirect
                {...otherProps}
                to={{
                    pathname: to,
                    search: getSearchMemoized(props.location.search),
                }}
                push
            />
        )}
    />
);
