import { TFunction } from 'i18next';
import { TypesIcon } from '../../../types/TypesIcon';
import { ClientInformationProps } from '../../../types/Client/ClientInformationProps';
import { UsersModal } from '../../../types/Models/UsersModal';

export const getClientFields = (t: TFunction, user: UsersModal | undefined): ClientInformationProps[] => ([
    {
        key: 'name',
        title: t('sentences.name'),
        value: user?.name || '',
        userId: user?.id,
        imageButton: TypesIcon.OPEN_IN_NEW,
    },
    {
        key: 'telephone',
        title: t('sentences.telephone'),
        value: user?.phone.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3 $4 $5') || '',
    },
    {
        key: 'numberOfCard',
        title: t('sentences.numberOfCard'),
        value: user?.loyalty_card || '',
    },
    {
        key: 'status',
        title: t('sentences.status'),
        value: (user?.status === 'mobile') ? ' Из приложения' : 'По звонку',
    },
]);
