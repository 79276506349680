import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../components/PageTitle';
import { units } from '../../helpers/styles/units';
import { InformationWrapperBlock } from '../../components/InformationWrapperBlock';
import { ConfirmeBlock } from './ConfirmeBlock';
import { ClientSchema } from './ClientSchema';
import { Form } from '../../components/Form';
import { getFieldsClientDescription } from './fieldsClientDescription';
import { ClientSchemaComment } from './ClientSchemaComment';
import { getFieldsClientComment } from './fieldsClientComment';
import { useTypeSelector } from '../../store';
import { selectUserCard } from '../../selectors/userCard';
import { getUser, removeUser } from '../../actions/users';
import { selectStoringPhoneNumber } from '../../selectors/storingPhoneNumber';
import { removeStoringPhoneNumber } from '../../actions/storingPhoneNumber';
import { colors } from '../../constants/Colors';

interface Formik {
    inputName: string;
    inputTelephone: string;
}

interface FormikComment {
    textarea: string;
}

type FormValueComment = Partial<FormikComment>;

type FormValues = Partial<Formik>;

const StyledPageTitle = styled(PageTitle)`
  margin-top: ${units(14)};
`;

const StyledWrapperNewOrder = styled.div`
  display: flex;
  margin-top: ${units(10)};
  width: max-content;
  min-width: ${units(275)};
  margin-right: ${units(10)};
`;

const StyledContentPage = styled.div`
  padding: 0 ${units(20)} ${units(24)};
  height: calc(100% - 87px);
`;

const StyledWrapperContent = styled.div`
  display: flex;
  width: 100%;
`;

const StyledTitleComment = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: ${units(6)};
  margin-left: ${units(12)};
`;

const StyledClientComment = styled.div`
  width: 100%;
  margin-top: 20px;
  max-height: ${units(110)};
  background: ${colors.white};
  border-radius: 16px;
`;

const StyledWrapperForm = styled.div`
  margin: ${units(6)} ${units(12)} 0 ${units(12)};
`;

export const NewClient = () => {
    const { t } = useTranslation('orders');
    const { id } = useParams<{ id: string}>();
    const dispatch = useDispatch();
    const user = useTypeSelector(selectUserCard);
    const storingPhoneNumber = useTypeSelector(selectStoringPhoneNumber);
    const createClient = !id;

    useEffect(() => {
        if (!createClient) dispatch(getUser({ id: +id }));

        return () => {
            dispatch(removeUser());
            dispatch(removeStoringPhoneNumber());
        };
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleSubmit = (values: FormValues) => {
        console.log(values);
    };

    const handleSubmitComment = (valuesComment: FormValueComment) => {
        console.log(valuesComment);
    };

    const form = useFormik<FormValues>({
        onSubmit: handleSubmit,
        initialValues: {
            inputName: user?.name || '',
            inputTelephone: storingPhoneNumber || (user?.phone || ''),
        },
        enableReinitialize: true,
        validationSchema: ClientSchema(),
    });

    const formComment = useFormik<FormValueComment>({
        onSubmit: handleSubmitComment,
        initialValues: {
            textarea: user?.comment || '',
        },
        validationSchema: ClientSchemaComment(),
        enableReinitialize: true,
    });

    return (
        <>
            <StyledContentPage>
                <StyledPageTitle>
                    {createClient ? 'Новый клиент' : 'Редактирование клиента'}
                </StyledPageTitle>
                <StyledWrapperContent>
                    <StyledWrapperNewOrder>
                        <InformationWrapperBlock maxWidth={275} headerTitle="Клиент">
                            <Form<FormValues>
                                fields={getFieldsClientDescription(t)}
                                form={form}
                            />
                        </InformationWrapperBlock>
                    </StyledWrapperNewOrder>
                    <StyledClientComment>
                        <StyledTitleComment>
                            Комментарий клиента
                        </StyledTitleComment>
                        <StyledWrapperForm>
                            <Form<FormValueComment>
                                fields={getFieldsClientComment(t)}
                                form={formComment}
                            />
                        </StyledWrapperForm>
                    </StyledClientComment>
                </StyledWrapperContent>
            </StyledContentPage>
            <ConfirmeBlock
                createClient={createClient}
                clientInformation={{ ...form.values }}
                commentInformation={{ ...formComment.values }}
            />
        </>
    );
};
