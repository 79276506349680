import { ControlProps } from '../../../../types/Form/ControlProps';
import { TypesFields } from '../../../../types/TypesFields';
import { FiltersMenuProps } from '../../../../types/Filters/FiltersMenuProps';

export const getFieldsStatusSelect = (option: FiltersMenuProps[]): ControlProps[] => ([
    {
        key: 'statusSelect',
        type: TypesFields.SELECT_MI,
        name: 'statusSelect',
        className: 'statusSelect',
        option,
        placeholder: 'Статус',
    },
]);
