import React, { useMemo } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Rows } from '../../../hooks/useDatepicker';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { FontWeight } from '../../../constants/Styles/fontWeight';

export type DisabledDates = {
    date?: string,
    name?: string,
}

export interface DatePickerColumn {
    col: Rows;
    formattedToday: string;
    selectedDay: string;
    isHoliday?: boolean;
    onClick: (value: string) => void;
    disabledDates?: DisabledDates;
    disableUntilСurrentDate?: boolean;
    onSuccess?: (value: string) => void;
}

const StyledColumn = styled.div<{ isHoliday?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${units(6)};

  width: ${units(17)};
  height: ${units(17)};

  font-weight: ${FontWeight.MEDIUM};
  letter-spacing: 0.5px;
  text-align: center;
  cursor: pointer;

  color: ${props => (props.isHoliday ? colors.red : colors.dark)};

  :last-child {
    margin-right: 0;
  }

  &.selectedDay {
    background-color: ${colors.blue};
    color: ${colors.white};
    border-radius: 50%;

    :hover {
      background-color: ${colors.blue};
      color: ${colors.white};
      border-radius: 50%;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &.in-prev-month,
  &.in-next-month {
    overflow: hidden;
    text-indent: -999999px;
    pointer-events: none;
    background: transparent;
  }

  :hover {
    background-color: ${colors.whiteBlue};
    color: ${colors.dark};
    border-radius: 50%;
  }

  ${typography(0)}
`;

export const DatePickerColumn = ({
    col,
    selectedDay,
    onClick,
    isHoliday,
    disabledDates,
    disableUntilСurrentDate,
    onSuccess,
}: DatePickerColumn) => {
    const handleClick = () => {
        onClick(col.date);
        if (onSuccess) {
            onSuccess(col.date);
        }
    };
    const currentDay = Date.parse(col.date) === Date.parse(selectedDay) ? 'selectedDay' : '';

    const disabledDate = useMemo(() => ({ date = '', name }: DisabledDates) => {
        let disabledColumn;

        if (name === 'order' && Date.parse(date) < Date.parse(col.date)) {
            disabledColumn = 'disabled';
        }

        return disabledColumn;
    }, [col.date]);

    const disabledUntilDate = useMemo(() => () => {
        let disabledColumn;
        if (Date.parse(col.date) <= Date.parse(dayjs().subtract(1, 'day').toISOString())
            && !(Date.parse(col.date) === Date.parse(selectedDay))) {
            disabledColumn = 'disabled';
        }

        return disabledColumn;
    }, [col.date]);

    return (
        <StyledColumn
            key={col.date}
            className={`${col.classes} ${currentDay} ${disabledDate(disabledDates as DisabledDates)} 
            ${disableUntilСurrentDate ? disabledUntilDate() : ''} `}
            onClick={handleClick}
            isHoliday={isHoliday}
        >
            {col.value}
        </StyledColumn>
    );
};
