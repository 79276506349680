import React from 'react';
import styled from 'styled-components';
import { Dayjs } from 'dayjs';
import { units } from '../../../helpers/styles/units';
import { typography } from '../../../helpers/styles/typography';
import { Button } from '../../Button';
import { TypesButton } from '../../../constants/ButtonTypes';
import { Icon } from '../../Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { colors } from '../../../constants/Colors';

export interface DatePickerHeader {
  selectedDate: Dayjs;
  monthName: string;
  getNextMonth: () => void;
  getPrevMonth: () => void;
}

const StyledHeaderCalendar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${units(7)};
  font-style: normal;
  font-weight: 600;

  ${typography(5)}
`;

const StyledArrowButton = styled(Button)`
  width: auto;
  height: auto;
  padding: 0;
`;

const StyledHeaderText = styled.div`
  margin: 0;

  ::first-letter {
    text-transform: uppercase;
  }
`;

export const DatePickerHeader = ({
    selectedDate,
    monthName,
    getNextMonth,
    getPrevMonth,
}: DatePickerHeader) => (
    <StyledHeaderCalendar>
        <StyledArrowButton
            onClick={getPrevMonth}
            typeButton={TypesButton.OUTLINE}
        >
            <Icon
                type={TypesIcon.ARROW_LEFT}
                fill={colors.gray}
            />
        </StyledArrowButton>
        <StyledHeaderText>{`${monthName} ${selectedDate.year()}`}</StyledHeaderText>
        <StyledArrowButton
            onClick={getNextMonth}
            typeButton={TypesButton.OUTLINE}
        >
            <Icon
                type={TypesIcon.ARROW_RIGHT}
                fill={colors.gray}
            />
        </StyledArrowButton>
    </StyledHeaderCalendar>
);
