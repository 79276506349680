import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as React from 'react';
import { FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { units } from '../../../helpers/styles/units';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { colors } from '../../../constants/Colors';
import { getFieldsOrderSelect } from './FieldsOrderSelect';
import { Form } from '../../../components/Form';
import { getFilterMenu } from '../../Orders/TableFilters/CustomizedMenusStatus/getFilterMenu';
import { useTypeSelector } from '../../../store';
import { selectOrderCard } from '../../../selectors/orderCard';
import { addModal } from '../../../actions/modal';
import { ModalTypes } from '../../../types/ModalTypes/ModalTypes';
import { getFullDate } from '../../../helpers/date/formatDate';
import { deleteOrder } from '../../../actions/orders';
import { selectUsersByPhone } from '../../../selectors/currentUser';
import { orderProducts } from '../../../reducers/newOrderProduct';
import { TypesOrder } from '../../../types/TypesOrder';
import { changeOrderProduct } from '../../../actions/orderProduct';

interface Formik {
    selectOrder: string[];
}

type FormValues = Partial<Formik>;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledOrder = styled.div`
   display: flex;
`;

const OrderNumber = styled.h2`
  font-weight: 500;
  font-size: ${units(12)};
  line-height: ${units(16)};
`;

const DateOfReceipt = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: ${units(6)};
    line-height: ${units(8)};
    margin-left: ${units(12)};
`;

const ButtonDeleteOrder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${units(16)};
    height: ${units(16)};
    background: ${colors.white};
    border: 1px solid ${colors.gray3};
    border-radius: ${units(2)};;
    cursor: pointer;
    margin-left: ${units(6)};
  
  svg{
    fill: ${colors.gray10};
  }

  :hover{
    svg{
      fill: ${colors.gray20};
    }
  }
`;

export const HeaderOrderPage = ({
    isNewOrder,
    formStatusOrder,
}: { isNewOrder: boolean, formStatusOrder: FormikProps<FormValues> }) => {
    const { t } = useTranslation('orders');
    const { t: TFilters } = useTranslation('filters');
    const orderCard = useTypeSelector(selectOrderCard);
    const orderProductArray = useTypeSelector(selectUsersByPhone);
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const onSuccess = () => {
        dispatch(deleteOrder({
            id: +id,
        }));
    };

    const handleOpenModal = React.useCallback(() => {
        dispatch(addModal({
            id: 1,
            type: ModalTypes.EDITING_PERSONAL_DATA,
            title: 'Удаление заказа',
            onSuccess,
            description: 'Вы действительно хотите удалить заказ?',
        }));
    }, [dispatch]);

    return (
        <StyledWrapper>
            <StyledOrder>
                {!isNewOrder ? (
                    <>
                        <OrderNumber> Заказ №{orderCard?.order_id}</OrderNumber>
                        <DateOfReceipt>
                            <span>Поступил</span>
                            <span> {orderCard?.date_created ? getFullDate(orderCard.date_created || '') : ''}</span>
                        </DateOfReceipt>
                        {
                            orderProductArray?.role !== 'cavist'
                                ? (
                                    <ButtonDeleteOrder onClick={() => handleOpenModal()}>
                                        <Icon
                                            type={TypesIcon.DELETE_BASKET}
                                            width="20px"
                                            height="20px"
                                        />
                                    </ButtonDeleteOrder>
                                ) : null
                        }
                    </>
                ) : <OrderNumber> Новый Заказ</OrderNumber>}
            </StyledOrder>
            <StyledOrder>
                <Form<FormValues>
                    fields={getFieldsOrderSelect(getFilterMenu(TFilters))}
                    form={formStatusOrder}
                    className="select_form"
                />
            </StyledOrder>
        </StyledWrapper>
    );
};
