import React, {
    ComponentType,
    useCallback,
    useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ModalTypes } from '../../types/ModalTypes/ModalTypes';
import { DefaultModal } from './DefaultModal';
import { selectModals } from '../../selectors/modals';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import { Modal as ModalProps } from '../../types/ModalTypes/Modal';
import { useOutSideClick } from '../../hooks/useOutSideClick';
import { removeModal } from '../../actions/modal';
import { RemoveDialog } from './RemoveDialog';

const StyledModal = styled.div`
  padding-left: 0;
  box-sizing: border-box;
  margin-top: 0;
  
  min-width: max-content;
  min-height: max-content;
`;

const components: Record<string, ComponentType<Omit<ModalProps, 'type'>>> = {
    [ModalTypes.EDITING_PERSONAL_DATA]: RemoveDialog,
};

export const Modal = ({
    id,
    data,
    message,
    type,
    icon,
    onClose,
    onSuccess,
    onSuccessMessage,
    withCloseIcon,
    withCloseAction,
    title,
    description,
}: ModalProps) => {
    const ModalComponent = components[type as string] || DefaultModal;
    const modals = useSelector(selectModals);
    const ref = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    useLockBodyScroll();

    const handleOutSideClick = useCallback((event: Event) => {
        if (!ref.current?.contains(event.target as Node)) {
            if (onClose) {
                onClose();
            }
            modals.forEach(({ id: modalId }) => dispatch(removeModal(modalId)));
        }
    }, [dispatch, modals, onClose]);

    useOutSideClick(ref, handleOutSideClick);

    return (
        <StyledModal ref={ref}>
            <ModalComponent
                id={id}
                data={data as never}
                message={message}
                title={title}
                description={description}
                icon={icon}
                onClose={onClose}
                onSuccess={onSuccess}
                onSuccessMessage={onSuccessMessage}
                withCloseIcon={withCloseIcon}
                withCloseAction={withCloseAction}
            />
        </StyledModal>
    );
};
