import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PageTitle } from '../../components/PageTitle';
import { units } from '../../helpers/styles/units';
import { ProductDescription } from './ProductDescription';
import { DrawerProduct } from './DrawerProduct';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { getBreadcrumbs } from './getBreadcrumbs';
import { useTypeSelector } from '../../store';
import { selectProductCard } from '../../selectors/productCard';
import { getProductCard } from '../../actions/products';
import { IShopList } from '../../types/Models/ShopListModel';
import { selectUsersByPhone } from '../../selectors/currentUser';
import { selectAllShop } from '../../selectors/allShops';
import { getAllShop } from '../../actions/shop';

const StyledPageTitle = styled(PageTitle)`
  margin-top: ${units(14)};
  margin-right: ${units(20)};
  font-weight: 700;
  font-size: ${units(12)};
  line-height: ${units(16)};
`;

export const Product = () => {
    const { t } = useTranslation('products');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const product = useTypeSelector(selectProductCard);
    const usersByPhone = useTypeSelector(selectUsersByPhone);
    const allShop = useTypeSelector(selectAllShop);
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string}>();
    const [cavistShop, setCavistShop] = useState<IShopList | undefined>(allShop?.find(item => item?.address === usersByPhone?.shop?.address));

    useEffect(() => {
        setCavistShop(allShop?.find(item => item?.address === usersByPhone?.shop?.address));
    }, [allShop, usersByPhone]);

    useEffect(() => {
        dispatch(getProductCard({ id: +id, shop_id: cavistShop?.id }));
    }, [cavistShop]);

    useEffect(() => {
        dispatch(getAllShop());
    }, []);

    const handleClick = (event: React.ChangeEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Breadcrumbs breadcrumbs={getBreadcrumbs(t)} />
            <StyledPageTitle>
                {product?.name ?? ''}
            </StyledPageTitle>
            <ProductDescription product={product} handleClick={handleClick} />
            <DrawerProduct productId={+id} anchorEl={anchorEl} handleClose={handleClose} />
        </>
    );
};
