import styled from 'styled-components';
import { Range } from '../../../Range';
import { RangeControlProps } from '../../../../types/Form/RangeControlProps';
import { units } from '../../../../helpers/styles/units';

const StyledRange = styled(Range)`
   margin-bottom: ${units(12)};
`;

export const RangeControl = ({
    name,
    value,
    title,
    setValue,
}: RangeControlProps) => (
    <StyledRange
        name={name}
        value={value}
        title={title}
        onChange={setValue}
    />
);
