import { request } from '../../helpers/api/request';
import { ProductContractCard, ProductContractCardParams } from '../../types/Endpoints/ProductContractCard';

export const getProductCard = async (params: ProductContractCardParams) => {
    const { data } = await request<ProductContractCard>({
        url: `products/${params.id}`,
        params,
    });

    return data;
};
