import { request } from '../../helpers/api/request';
import { CategoriesContract } from '../../types/Endpoints/CategoriesContract';

export const getCategory = async () => {
    const { data } = await request<CategoriesContract>({
        url: 'categories',
    });

    return data;
};
