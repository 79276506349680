import { request } from '../../helpers/api/request';
import { UsersCardContract, UsersCardContractParams } from '../../types/Endpoints/UsersCardContract';

export const getUserCard = async (params: UsersCardContractParams) => {
    const { data } = await request<UsersCardContract>({
        url: `users/${params.id}`,
    });

    return data;
};
