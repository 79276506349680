import { createGlobalStyle } from 'styled-components';
import { colors } from '../../constants/Colors';

export const fontName = 'TTNormsPro';

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    min-height: 100vh;
    height: fit-content;
    display: flex;
    flex-direction: column;

    font-family: ${fontName};

    color: ${colors.dark};
    background-color: ${colors.background};

    .MuiModal-backdrop {
      background-color: transparent;
    }

    .MuiPaper-elevation {
      min-width: inherit !important;
      box-shadow: 0 12px 50px rgba(130, 141, 171, 0.1);
      &::-webkit-scrollbar {
        width: 6px; /* ширина всей полосы прокрутки */
      }

      &::-webkit-scrollbar-track {
        background: ${colors.gray3}; /* цвет зоны отслеживания */
      }

      &::-webkit-scrollbar-thumb {
        background: ${colors.gray2};
        border-radius: 100px; /* округлось бегунка */
      }
    }

    .MuiMenu-paper {
      box-shadow: 0 0 40px #F6F5FA;
      max-height: 226px;
      border: 1px solid #DFE4F2;
      border-radius: 8px;
      padding: 12px;
      gap: 4px;
    }
  }

  body::-webkit-scrollbar {
    width: 6px; /* ширина всей полосы прокрутки */
  }

  body::-webkit-scrollbar-track {
    opacity: 0; /* цвет зоны отслеживания */
  }

  body::-webkit-scrollbar-thumb {
    background: ${colors.gray2};
    border-radius: 10px; /* округлось бегунка */
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:-webkit-autofill ~ label {
    transform: translate(0px, 0px) scale(1);
  }

  /* Убираем внешние и внутренние отступы */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    font-family: ${fontName};
  }
`;
