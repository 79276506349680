import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';
import { ButtonProps } from './index';

const hoverByType: Record<string, string> = {
    [TypesButton.FILL]: colors.white,
    [TypesButton.OUTLINE]: colors.blue3,
    [TypesButton.OUTLINE]: colors.blue3,
    [TypesButton.OUTLINE_BORDER_GREY]: colors.gray2,
};

export const applyActiveColor = ({ typeButton, disabled }: Pick<ButtonProps, 'typeButton' | 'disabled'>) => (
    !disabled
        ? `
          &:hover {
            color: ${hoverByType[typeButton]}};
          }
        `
        : '');
