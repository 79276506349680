import { colors } from '../../constants/Colors';
import { FiltersMenuProps } from '../../types/Filters/FiltersMenuProps';

export const getFieldsSelectPrice = () : FiltersMenuProps[] => ([
    {
        key: 'asc',
        name: 'Сначала низкая',
        color: colors.blue,
        background: colors.blue_30,
    },
    {
        key: 'desc',
        name: 'Сначала высокая',
        color: colors.blue,
        background: colors.blue_30,
    },
]);
