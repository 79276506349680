import { TFunction } from 'i18next';
import { ControlProps } from '../../../types/Form/ControlProps';
import { TypesFields } from '../../../types/TypesFields';

export const getFieldsLogin = (t: TFunction): ControlProps[] => ([
    {
        key: 'login',
        type: TypesFields.INPUT,
        name: 'login',
        placeholder: t('placeholders.login'),
        className: 'loginInput',
    },
    {
        key: 'password',
        type: TypesFields.PASSWORD,
        name: 'password',
        placeholder: t('placeholders.password'),
        className: 'loginInput',
    },
]);
