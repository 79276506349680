import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';
import { Icon } from '../../Icon';
import { removeModal } from '../../../actions/modal';
import { TypesIcon } from '../../../types/TypesIcon';
import { DefaultModalContent } from './DefaultModalContent';
import { DefaultModalAction } from './DefaultModalAction';
import { Modal } from '../../../types/ModalTypes/Modal';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: ${units(129)};
  box-sizing: border-box;

  background: ${colors.white};

  border: 1px solid ${colors.gray} ;
  border-radius: ${units(4)};
  
  padding: ${units(8)} ${units(10)};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;

  opacity: 0.5;
`;

export const DefaultModal = ({
    id,
    message,
    onClose,
    onSuccess,
    onSuccessMessage,
    icon,
    withCloseIcon = true,
    withCloseAction = true,
}: Modal) => {
    const { title, description } = message || {};
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }

        dispatch(removeModal(id));
    }, [dispatch, id, onClose]);

    const handleSuccess = useCallback(() => {
        if (onSuccess) {
            onSuccess();
        }

        dispatch(removeModal(id));
    }, [dispatch, id, onSuccess]);

    return (
        <StyledRoot>
            {withCloseIcon && (
                <StyledIcon
                    onClick={handleClose}
                    type={TypesIcon.CLOSE}
                    color={colors.gray}
                />
            )}
            <DefaultModalContent Icon={icon} description={description} title={title} />
            <DefaultModalAction
                onClose={handleClose}
                onSuccess={handleSuccess}
                onSuccessMessage={onSuccessMessage}
                withCloseAction={withCloseAction}
            />
        </StyledRoot>
    );
};
