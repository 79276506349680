import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { selectSnackbars } from '../../selectors/snackbars';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { Snackbar } from '../Snackbar';
import { colors } from '../../constants/Colors';
import { applySnackbarAnimation } from '../Snackbar/applyAnimationByType';
import { removeAllNotification } from '../../actions/notifications';

interface ApplySnackbarAnimationProps {
    isUnclosed?: boolean,
    animationTime?: number | string
}

const StyledSnackbarsContainer = styled.div`
  position: fixed;
  bottom: ${units(4)};
  right: ${units(4)};
  z-index: 1000;

  display: flex;
  flex-direction: column;

  @media (max-width: ${BreakPoints.SM}) {
    bottom: 0;
    right: 0;
    margin: ${units(2)};
  }
`;

const StyledMessage = styled.div<ApplySnackbarAnimationProps>`
   position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${units(160)};
  padding: ${units(3)} ${units(9)} ${units(3)} ${units(6)};
  margin-bottom: ${units(3)};
  
  background: ${colors.dark};
  border-radius: ${units(4)};
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    flex-shrink: 0;
  }
  
  @media (max-width: ${BreakPoints.SM}) {
    width: 100%;
  }
  
  ${applySnackbarAnimation};
  padding-left: ${units(6)};
  flex-shrink: 1;
`;

const StyledMessageDescription = styled.p`
  margin: ${units(4)};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
`;

export const Snackbars = () => {
    const snackbars = useSelector(selectSnackbars);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(removeAllNotification());
    };

    return (
        <StyledSnackbarsContainer>
            {snackbars.length > 1 ? (
                <StyledMessage onClick={() => handleClose()}>
                    <StyledMessageDescription>
                        Скрыть все
                    </StyledMessageDescription>
                </StyledMessage>
            ) : null}

            {snackbars.map(({
                type,
                id, message,
                autoHideDuration,
                isUnclosed,
            }) => {
                const [enumKey] = Object.entries(TypesSnackbars).find(([, value]) => value === type)!;
                const currentType = TypesSnackbars[enumKey as keyof typeof TypesSnackbars];

                return (
                    <Snackbar
                        snackbarType={currentType}
                        snackbarId={id}
                        key={id}
                        snackbarTitle={message.title}
                        snackbarOrderId={message.id}
                        snackbarDescription={message.description}
                        snackbarMistake={message.isMistake}
                        autoHideDuration={autoHideDuration}
                        isUnclosed={isUnclosed}
                    />
                );
            })}
        </StyledSnackbarsContainer>
    );
};
