import { request } from '../../helpers/api/request';
import { currentUserContract } from '../../types/Endpoints/UserContract';

export const getCurrentUser = async () => {
    const { data } = await request<currentUserContract>({
        url: 'user/profile',
    });

    return data;
};
