import { createReducer } from '@reduxjs/toolkit';
import { getUserOrders } from '../../actions/orders';
import { OrderTableModel } from '../../types/Models/OrderModel';
import { addEmptyArray } from '../../actions/orders/setEmtyArray';

interface ILastUserOrders {
    lastUserOrders?: OrderTableModel | undefined;
}

export const lastUserOrders = createReducer<ILastUserOrders>({}, builder => {
    builder.addCase(getUserOrders.fulfilled, (state, { payload }) => ({
        ...state,
        lastUserOrders: payload,
    }));
    builder.addCase(addEmptyArray, (state, { payload }) => ({
        ...state,
        lastUserOrders: payload,
    }));
});
