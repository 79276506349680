import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';

const borderType: Record<string, string> = {
    [TypesButton.FILL]: '0',
    [TypesButton.OUTLINE]: '0',
    [TypesButton.OUTLINE_BORDER]: `2px solid ${colors.blue};`,
    [TypesButton.OUTLINE_BORDER_GREY]: `2px solid ${colors.gray3};`,
};

export const applyBorder = ({ typeButton }: {typeButton: TypesButton}) => `border: ${borderType[typeButton]}`;
