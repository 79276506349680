import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';

interface DividerProps {
  label?: string;
  className?: string;
  isVertical?: boolean;
  color?: string;
}

const StyledRoot = styled.div<Pick<DividerProps, 'isVertical'>>`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  ${({ isVertical }) => (isVertical ? 'height: inherit' : 'width: 100%')};
`;

const StyledDivider = styled.div<Pick<DividerProps, 'isVertical' | 'color'>>`
  display: flex;
  justify-content: center;
  
  box-sizing: border-box;
  
  width: 100%;
  height: 100%;
  
  border-radius: ${units(2)};

  ${({ isVertical, color }) => (isVertical ? `border-left: 1px solid ${color}` : `border-bottom: 1px solid ${color}`)};
`;

export const Divider = ({
    className,
    isVertical = false,
    color = colors.gray3,
}: DividerProps) => (
    <StyledRoot isVertical={isVertical} className={className}>
        <StyledDivider isVertical={isVertical} color={color} />
    </StyledRoot>
);
