import { useTranslation } from 'react-i18next';
import * as React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import { useEffect, useState, useLayoutEffect } from 'react';
import { TypesButton } from '../../../constants/ButtonTypes';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { colors } from '../../../constants/Colors';
import { createOrders, updateOrders } from '../../../actions/orders';
import { useTypeSelector } from '../../../store';
import { selectOrderProductArray, selectOrderProductForBE } from '../../../selectors/orderProduct';
import { selectUserCard } from '../../../selectors/userCard';
import { selectOrderCard, selectOrderPrices } from '../../../selectors/orderCard';
import { selectSuccessOrder } from '../../../selectors/successOrder';
import { units } from '../../../helpers/styles/units';
import { addModal } from '../../../actions/modal';
import { ModalTypes } from '../../../types/ModalTypes/ModalTypes';
import { selectUsersByPhone } from '../../../selectors/currentUser';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 110%;
  background: #FFFFFF;
  height: 60px;
  z-index: 998;
  overflow: hidden;
  box-shadow: 0px 0px 12px 0px rgba(130, 141, 171, 0.16);
`;

const StyledButtonDrawer = styled(Button)`
  width: 100%;
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  align-items: center;
  max-width: 135px;
  max-height: 36px;
  margin: 0 auto;

  svg {
    margin-right: ${units(2)};
  }
`;

const StyledButtonWrapper = styled.div`
  margin-left: ${units(20)};
`;

export const SaveBlock = ({
    isNewOrder,
    idShop,
    selectTime,
    date,
    status,
    commentUser,
    orderTime,
}: {
    isNewOrder: boolean,
    idShop: string,
    status: string,
    selectTime: string,
    date: string | undefined,
    orderTime: string[] | undefined,
    commentUser: string
}) => {
    const { t } = useTranslation('orders');
    const user = useTypeSelector(selectUserCard);
    const usersByPhone = useTypeSelector(selectUsersByPhone);
    const orderCard = useTypeSelector(selectOrderCard);
    const orderSuccess = useTypeSelector(selectSuccessOrder);
    const userOrder = isNewOrder ? user : orderCard?.user;
    const dispatch = useDispatch();
    const history = useHistory();
    const orderProductArray = useTypeSelector(selectOrderProductForBE);
    const orderProduct = useTypeSelector(selectOrderProductArray);
    const [promptMessage, setPromptMessage] = useState<boolean>(false);
    const [disableButton, setDisableButton] = useState<boolean>(true);
    const [promptMessageOnClick, setPromptMessageOnClick] = useState<boolean>(false);
    const orderPrices = useTypeSelector(selectOrderPrices);
    const orderProductArrayForPriceCalc = useTypeSelector(selectOrderProductArray);

    const onClickCreateOrders = () => {
        setDisableButton(false);
        setPromptMessageOnClick(() => true);
        setTimeout(() => setDisableButton(() => true), 2000);
        if (isNewOrder) {
            setTimeout(() => {
                dispatch(createOrders({
                    parameters: {
                        user_id: userOrder?.id || 0,
                        shop_id: +idShop !== 0 ? +idShop : undefined,
                        hours_pickup: selectTime,
                        date_pickup: date || '',
                        comment: commentUser,
                        status,
                    },
                    products: orderProductArray,
                }));
            }, 0);
        } else {
            setTimeout(() => {
                dispatch(updateOrders({
                    params: {
                        id: orderCard?.order_id || 0,
                    },
                    userData: {
                        parameters: {
                            shop_id: +idShop !== 0 ? +idShop : undefined,
                            date_pickup: date,
                            hours_pickup: selectTime,
                            comment: commentUser,
                            status,
                        },
                        products: orderProductArray,
                    },
                }));
            }, 0);
        }
    };

    const handleOpenModal = React.useCallback(() => {
        dispatch(addModal({
            id: 1,
            type: ModalTypes.EDITING_PERSONAL_DATA,
            title: 'Заказ готов к выдаче ',
            onSuccess: () => onClickCreateOrders(),
            description: 'После подтверждения, клиенту будет выслано смс о готовности заказа',
        }));
    }, [dispatch, status]);

    const resultObjectComparison = orderProduct.filter(o1 => orderCard?.products.some(o2 => ((+o1.id === +o2.product_id)
        && (+o1.count === Number(o2.added_to_order)))));

    useEffect(() => {
        if (orderSuccess.successOrder?.active && status !== 'collection') {
            history.push('/orders');
        }
    }, [orderSuccess]);

    useLayoutEffect(() => {
        if (orderCard?.date_pickup === date
            && orderCard?.hours_pickup === selectTime
            && (orderCard?.comment || '') === (commentUser || '')
            && orderCard?.status === status
            && orderCard?.shop.id === +idShop
            && resultObjectComparison.length === orderCard?.products.length) {
            setPromptMessage(false);
        } else setPromptMessage(true);
        if (promptMessageOnClick) {
            setPromptMessage(false);
        }
    }, [resultObjectComparison, orderCard, date, selectTime, commentUser, status, idShop, promptMessageOnClick]);

    let disabled = false;
    if (orderTime && String(new Date()).slice(0, 15) === String(new Date(String(date))).slice(0, 15)) {
        disabled = new Date().getHours() > +orderTime[0].slice(0, 2);
    } else {
        disabled = new Date(String(date)) < new Date();
    }
    const isDisabledForCavist = disabled && usersByPhone?.role === 'cavist';
    const disabledButton = orderProduct.length > 0 && disableButton && orderPrices && orderProductArrayForPriceCalc.length === orderPrices.length;

    return (
        <StyledWrapper>
            <StyledButtonWrapper>
                <StyledButtonDrawer
                    typeButton={TypesButton.FILL}
                    disabled={isDisabledForCavist || !disabledButton}
                    onClick={() => {
                        if (status === 'ready') {
                            handleOpenModal();
                            return;
                        }
                        onClickCreateOrders();
                    }}
                >
                    <Icon
                        type={TypesIcon.DONE}
                        fill={colors.white}
                        width="20px"
                        height="20px"
                    />
                    {t('buttons.save')}
                </StyledButtonDrawer>
            </StyledButtonWrapper>
            <Prompt when={promptMessage} message="ошибка" />
        </StyledWrapper>
    );
};
