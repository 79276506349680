import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as orderApi from '../../../api/orders';
import { UsersCardContractParams } from '../../../types/Endpoints/UsersCardContract';
import { UpdateOrder } from '../../../types/Endpoints/CreateOrder';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { addNewSuccessOrder } from '../../successOrder';
import { getCurrentUser } from '../../сurrentUser';

export const UPDATE_ORDER = 'user/updateOrder';

const useOnSuccess = (result: any): Thunk => async dispatch => {
    if (result.status) {
        await dispatch(addNotification({
            type: TypesSnackbars.SUCCESS,
            message: {
                id: result?.id,
                title: 'Обновился заказ',
                description: 'Заказ был обновлен',
            },
        }));
        await dispatch(addNewSuccessOrder({
            id: result?.id,
            active: result?.status,
        }));
        await dispatch(getCurrentUser());
    }

    if (!result.status) {
        await dispatch(addNotification({
            type: TypesSnackbars.SUCCESS,
            message: {
                title: 'Заказ',
                description: 'Некоторых товаров нет в наличии',
            },
        }));
    }
};

const onFail = (): Thunk => async dispatch => {
    await dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            isMistake: true,
            description: 'Проверьте введенные данные',
        },
    }));
};

export const updateOrders = createAsyncThunk(
    UPDATE_ORDER,
    createRequest({
        type: UPDATE_ORDER,
        loader: ({ params, userData } : { params: UsersCardContractParams, userData: UpdateOrder}) => orderApi.updateOrders(params, userData),
        onSuccess: useOnSuccess,
        onFail,
        showFailNotification: false,
    }),
);
