import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as React from 'react';
import { FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { units } from '../../../helpers/styles/units';
import { InformationWrapperBlock } from '../../../components/InformationWrapperBlock';
import { InformationBlockDescription } from '../../../components/InformationBlockDescription';
import { getClientFields } from './getFieldsClient';
import { Form } from '../../../components/Form';
import { getFieldsClientComment } from './fieldsClientComment';
import { useTypeSelector } from '../../../store';
import { selectUserCard } from '../../../selectors/userCard';
import { selectOrderCard } from '../../../selectors/orderCard';
import { getLoyaltyInformation } from '../../../actions/loyaltyInformation';

interface Formik {
    textarea: string;
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${units(10)};
`;

const StyledClientComment = styled.div`
  width: 100%;
  max-height: ${units(100)};
  margin-left: ${units(20)};
  background: #FFFFFF;
  border-radius: 16px;
`;

const StyledTitleComment = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: ${units(6)};
  margin-left: ${units(12)};
`;

const StyledWrapperForm = styled.div`
  margin: ${units(6)} ${units(12)} 0 ${units(12)};
`;

type FormValues = Partial<Formik>;

export const CustomerInformation = ({ isNewOrder, formComment } : { isNewOrder: boolean, formComment: FormikProps<FormValues>}) => {
    const { t } = useTranslation('client');
    const user = useTypeSelector(selectUserCard);
    const dispatch = useDispatch();
    const orderCard = useTypeSelector(selectOrderCard);
    const userOrder = isNewOrder ? user : orderCard?.user;

    useEffect(() => {
        if (userOrder?.id) {
            dispatch(getLoyaltyInformation({
                id: userOrder?.id,
            }));
        }
    }, [userOrder]);

    return (
        <StyledWrapper>
            <InformationWrapperBlock maxWidth={215} headerTitle="Клиент">
                <InformationBlockDescription widthNameFields={63} clientFields={getClientFields(t, userOrder)} />
            </InformationWrapperBlock>
            <StyledClientComment>
                <StyledTitleComment>
                    Комментарий клиента
                </StyledTitleComment>
                <StyledWrapperForm>
                    <Form<FormValues>
                        fields={getFieldsClientComment(t)}
                        form={formComment}
                    />
                </StyledWrapperForm>
            </StyledClientComment>
        </StyledWrapper>
    );
};
