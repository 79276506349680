import { colors } from '../../../constants/Colors';
import { FiltersMenuProps } from '../../../types/Filters/FiltersMenuProps';
import { IShopList } from '../../../types/Models/ShopListModel';

export const getFieldsSelectShop = (allShops: IShopList[] | []) : FiltersMenuProps[] => ([
    ...allShops.map(shop => (
        {
            key: `${shop.id}`,
            name: shop.address,
            color: colors.blue,
            background: colors.blue_30,
        }
    )),
]);
