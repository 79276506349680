import { TFunction } from 'i18next';
import { ControlProps } from '../../../../types/Form/ControlProps';
import { TypesFields } from '../../../../types/TypesFields';

export const getFieldsDrawer = (t: TFunction): ControlProps[] => ([
    {
        key: 'idCard',
        type: TypesFields.INPUT,
        name: 'idCard',
        title: t('input.idCard'),
        className: 'idCardInput',
    },
    {
        key: 'divider',
        type: TypesFields.DIVIDER,
        name: 'divider',
    },
    {
        type: TypesFields.DATEPICKER,
        name: 'dateIssue',
        title: t('input.dateOfIssue'),
    },
]);
