import { request } from '../../helpers/api/request';
import { UsersCardContractParams } from '../../types/Endpoints/UsersCardContract';

export const getLoyaltyBonus = async (params: UsersCardContractParams) => {
    const { data } = await request({
        url: `users/${params.id}/card`,
    });

    return data;
};
