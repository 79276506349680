import { TFunction } from 'i18next';
import { ControlProps } from '../../types/Form/ControlProps';
import { TypesFields } from '../../types/TypesFields';
import {
    CHARACTERS_IN_INPUT_PHONE,
    CHARACTERS_IN_INPUT_SYMBOLS_USER,
} from '../../helpers/validation';

export const getFieldsClientDescription = (t: TFunction): ControlProps[] => ([
    {
        key: 'inputName',
        type: TypesFields.INPUT,
        name: 'inputName',
        className: 'inputName',
        titleLeft: 'Имя*',
        inputRestriction: CHARACTERS_IN_INPUT_SYMBOLS_USER,
    },
    {
        key: 'inputTelephone',
        type: TypesFields.INPUT,
        name: 'inputTelephone',
        className: 'inputTelephone',
        titleLeft: 'Tелефон*',
        inputRestriction: CHARACTERS_IN_INPUT_PHONE,
    },
]);
