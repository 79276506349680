import { pick } from 'lodash';
import { History } from 'history';
import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { loaders } from './loaders';
import { orders } from './orders';
import { modals } from './modal';
import { product } from './product';
import { productCard } from './productCard';
import { shops } from './shop';
import { snackbars } from './snackbars';
import { category } from './category';
import { orderCard } from './orderCard';
import { userCard } from './userCard';
import { userByPhone } from './usersByPhone';
import { orderProducts } from './newOrderProduct';
import { userOrders } from './userOrders';
import { allShops } from './allShops';
import { successOrder } from './successOrder';
import { drawerOrder } from './drawerOrder';
import { lastUserOrders } from './lastUserOrders';
import { drawerProducts } from './drawerProducts';
import { currentUser } from './currentUser';
import { timespans } from './timespans';
import { listNewOrders } from './listNewOrders';
import { storingPhoneNumber } from './storingPhoneNumber';
import { loyaltyInformation } from './loyaltyInformation';

const getClearState = (state: any, notClearSystemData?: boolean) => pick(state, notClearSystemData ? ['router', 'systems'] : ['router']);

export const createRootReducer = (history: History) => {
    const reducers = combineReducers({
        router: connectRouter(history),
        loaders,
        orders,
        modals,
        product,
        productCard,
        shops,
        snackbars,
        category,
        orderCard,
        userCard,
        userByPhone,
        orderProducts,
        userOrders,
        allShops,
        successOrder,
        drawerOrder,
        lastUserOrders,
        drawerProducts,
        currentUser,
        timespans,
        listNewOrders,
        storingPhoneNumber,
        loyaltyInformation,
    });

    return (state: any, action: AnyAction) => {
        const realState = action.type === 'user/logout' ? getClearState(state, action.payload) : state;

        return reducers(realState, action);
    };
};
