import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';
import { ButtonProps } from './index';

const hoverByType: Record<string, string> = {
    [TypesButton.FILL]: colors.white,
    [TypesButton.OUTLINE]: colors.blue,
    [TypesButton.OUTLINE_BORDER]: colors.blue,
    [TypesButton.OUTLINE_BORDER_GREY]: `2px solid ${colors.gray3};`,
};

export const applyHoverColor = ({ typeButton, disabled }: Pick<ButtonProps, 'typeButton' | 'disabled'>) => (
    !disabled
        ? `
          &:hover {
            color: ${hoverByType[typeButton]}};
          }
        `
        : '');
