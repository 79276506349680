import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { DrawerWrapper } from '../../../../components/DrawerWrapper';
import { DrawerModalSchema } from './DrawerModalSchema';
import { Form } from '../../../../components/Form';
import { getFieldsDrawer } from './fieldsModalDrawer';
import { units } from '../../../../helpers/styles/units';
import { useTypeSelector } from '../../../../store';
import { selectCategory, selectCategoryFilters } from '../../../../selectors/category';
import { getCategory } from '../../../../actions/category';
import { getCheckboxValues, getFieldsSelectCategory } from './getFilters';
import { addDrawerProducts, removeDrawerProducts } from '../../../../actions/drawerProducts';
import { selectDrawerProduct } from '../../../../selectors/drawerProducts';
import { getCategoryFilters } from '../../../../actions/category/thunks/getCategoryFilters';
import { getProduct } from '../../../../actions/products';

interface IDrawerFilters{
    anchorEl: null | HTMLElement,
    handleClose(): void,
    isForm?: boolean,
    setIsSelectedFilter?: (state: boolean) => void;
    isModal?: boolean
}

interface Formik {
    selectOptionCategory: string[];
    category_id: object;
    price_range: {
        from: string
        to: string
    }
    country: object;
    volume: object;
    alcohol_content_range: {
        from: string
        to: string
    }
    wine_sugar_type:object,
    gift_box: string[],
    champagne_type:object,
    whiskey_type:object,
    cognac_type:object

}

type FormValues = Partial<Formik>;

const FormWrapper = styled.div`
  padding: ${units(10)} ${units(10)} 0;
`;

export const DrawerModalFilters: React.FC<IDrawerFilters> = ({
    setIsSelectedFilter = (state: boolean) => console.log(state),
    anchorEl,
    handleClose,
    isForm,
    isModal,
}) => {
    const { t } = useTranslation('filters');
    const categories = useTypeSelector(selectCategory);
    const selectFilterDrawer = useTypeSelector(selectDrawerProduct);
    const filters = useTypeSelector(selectCategoryFilters);
    const selectOptionCategory = selectFilterDrawer?.category ? selectFilterDrawer?.category[0] : '';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategory());
    }, []);

    const handleSubmit = (values: FormValues) => {
        console.log(values);
    };

    const form = useFormik<FormValues>({
        onSubmit: handleSubmit,
        initialValues: {
            selectOptionCategory: selectFilterDrawer?.category || ['', '', '', ''],
            category_id: selectFilterDrawer?.category_id || {},
            volume: selectFilterDrawer?.volume || {},
            price_range: {
                from: selectFilterDrawer?.price_range?.from || '0',
                to: selectFilterDrawer?.price_range?.to || '0',
            },
            country: selectFilterDrawer?.country || {},
            alcohol_content_range: {
                from: selectFilterDrawer?.alcohol_content_range?.from || '0',
                to: selectFilterDrawer?.alcohol_content_range?.to || '0',
            },
            wine_sugar_type: selectFilterDrawer?.wine_sugar_type || {},
            gift_box: selectFilterDrawer?.gift_box || ['', '', '', ''],
            champagne_type: selectFilterDrawer?.champagne_type || {},
            whiskey_type: selectFilterDrawer?.whiskey_type || {},
            cognac_type: selectFilterDrawer?.cognac_type || {},

        },
        enableReinitialize: true,
        validationSchema: DrawerModalSchema(),
    });

    useEffect(() => {
        if (form.values.selectOptionCategory) {
            dispatch(getCategoryFilters({ id: +form.values.selectOptionCategory[3], isModal }));
            form.setFieldValue('category_id', {});
            form.setFieldValue('volume', {});
            form.setFieldValue('champagne_type', {});
            form.setFieldValue('whiskey_type', {});
            form.setFieldValue('cognac_type', {});
            form.setFieldValue('country', {});
            form.setFieldValue('alcohol_content_range', {
                from: '0',
                to: '0',
            });
            form.setFieldValue('wine_sugar_type', {});
            form.setFieldValue('gift_box', ['', '', '', '']);
            form.setFieldValue('price_range', {
                from: '0',
                to: '0',
            });
        }
    }, [form.values.selectOptionCategory]);

    const onClickFindOrder = () => {
        dispatch(removeDrawerProducts());
        dispatch(addDrawerProducts({
            category: form.values.selectOptionCategory,
            category_id: form.values.category_id || {},
            price_range: {
                from: form.values.price_range?.from || '',
                to: form.values.price_range?.to || '',
                queryString: form?.values?.price_range?.to ? `${form.values.price_range?.from || '0'}-${form.values.price_range?.to || '0'}` : undefined,
            },
            country: form.values.country || {},
            volume: form.values.volume || {},
            alcohol_content_range: {
                from: form.values.alcohol_content_range?.from || '',
                to: form.values.alcohol_content_range?.to || '',
                queryString: form?.values?.alcohol_content_range?.to ? `${form.values.alcohol_content_range?.from || '0'}-${form.values.alcohol_content_range?.to || '0'}` : undefined,
            },
            gift_box: form.values?.gift_box || ['', '', '', ''],
            wine_sugar_type: form.values?.wine_sugar_type || {},
            champagne_type: form.values.champagne_type || {},
            cognac_type: form.values.cognac_type || {},
            whiskey_type: form.values.whiskey_type || {},
        }));
    };

    const onClickСancel = () => {
        dispatch(removeDrawerProducts());
    };

    const disabledButton = !selectOptionCategory && !selectFilterDrawer?.price_range;

    useEffect(() => {
        if (selectFilterDrawer?.price_range) {
            setIsSelectedFilter(false);
        } else setIsSelectedFilter(true);
    }, [selectFilterDrawer]);
    return (
        <DrawerWrapper
            onClickFind={onClickFindOrder}
            onClickСancel={onClickСancel}
            title={t('sentences.filter')}
            isForm={isForm}
            anchorEl={anchorEl}
            handleClose={handleClose}
            disabledButton={disabledButton}
        >
            <FormWrapper>
                <Form<FormValues>
                    fields={getFieldsDrawer(
                        t,
                        getFieldsSelectCategory(categories || []),
                        filters,
                    )}
                    form={form}
                    contentHeight
                />
            </FormWrapper>
        </DrawerWrapper>
    );
};
