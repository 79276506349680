export const routes = {
    login: '/login',
    commonLogin: '/',
    orders: '/orders',
    order: '/order/:id',
    application: '/application',
    call: '/call',
    profile: '/profile',
    catalog: '/catalog',
    productCard: '/products-card',
    productCardId: '/products-card/:id',
    clientCard: '/client-card/:id',
    technicalPage: '/technical',
    newOrder: '/new-order',
    newOrderId: '/new-order/:id',
    newClient: '/new-client',
    newClientId: '/update-client/:id',
};
