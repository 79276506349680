import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { getBreadcrumbs } from './getBreadcrumbs';
import { HeaderClientPage } from './HeaderClientPage';
import { ClientInformation } from './ClientInformation';
import { TableLastOrdersOfCustomer } from './TableLastOrdersOfCustomer';
import { getUser, removeUser } from '../../actions/users';
import { useTypeSelector } from '../../store';
import { selectUserCard } from '../../selectors/userCard';

export const Client = () => {
    const { t } = useTranslation('client');
    const dispatch = useDispatch();
    const user = useTypeSelector(selectUserCard);
    const { id } = useParams<{ id: string}>();

    useEffect(() => {
        dispatch(getUser({ id: +id }));

        return () => {
            dispatch(removeUser());
        };
    }, []);

    return (
        <>
            <Breadcrumbs breadcrumbs={getBreadcrumbs(t)} />
            <HeaderClientPage user={user} />
            <ClientInformation user={user} />
            <TableLastOrdersOfCustomer />
        </>
    );
};
