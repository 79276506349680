import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import {
    DAYS_IN_WEEK,
    DAYS_SHORT,
    FORMAT_DATE,
    FORMAT_MONTH_DATE,
    HOLIDAYS,
} from '../constants/Datepicker';

export type Rows = {
  classes?: string;
  date: string;
  value: string | number;
}

interface UseDatePickerHOC {
  daysShort?: string[],
  monthNames?: string[],
  daysInWeek?: number[],
  currentDay?: string,
}

export const useDatePicker = ({
    daysShort = DAYS_SHORT(),
    daysInWeek = DAYS_IN_WEEK,
    currentDay = undefined,
}: UseDatePickerHOC) => {
    const today = dayjs(currentDay || undefined);
    const formattedToday = today.format(FORMAT_DATE);
    const [selectedDate, setSelectedDate] = useState(today);
    const [selectedDay, setSelectedDay] = useState(formattedToday);

    const daysInMonth = selectedDate.daysInMonth();
    const firstDayInMonth = selectedDate.startOf('month').day();
    const startingPoint = daysInWeek.indexOf(firstDayInMonth);

    let prevMonthStartingPoint = selectedDate.daysInMonth() - daysInWeek.indexOf(firstDayInMonth);
    let currentMonthDayCounter = 1;
    let nextMonthCounter = 1;

    let rows = 1;
    const cols = 7;
    const calendarRows: { [key: string]: Rows[] } = {};

    const weekendDays = (day: string | number) => {
        const parsedDay = dayjs(day).day();
        const parsedFormatDayMonth = dayjs(day).format('DD.MM');
        let weekendDay;

        if (parsedDay === 0 || parsedDay === 6) {
            weekendDay = `${parsedDay} weekendDays`;
        }

        if (HOLIDAYS.includes(parsedFormatDayMonth)) {
            weekendDay = `${parsedDay} weekendDays`;
        }

        return weekendDay;
    };

    const currentMonthRow = (countRow: number, countCol: number) => {
        const year = selectedDate.year();
        const month = selectedDate.format('MM');
        const day = currentMonthDayCounter < 10 ? `0${currentMonthDayCounter}` : currentMonthDayCounter;
        const date = `${year}-${month}-${day}`;

        if (day < dayjs(date).daysInMonth() && countCol === 7) {
            rows += 1;
        }

        calendarRows[countRow] = [...calendarRows[countRow], {
            classes: `${weekendDays(date)}`,
            date,
            value: day,
        }];
        currentMonthDayCounter += 1;
    };

    const prevMonthRow = (countRow: number, countCol: number) => {
        const year = selectedDate.month() === 0 ? selectedDate.year() - 1 : selectedDate.year();
        const month = selectedDate.month() === 0 ? 11 : selectedDate.month();
        const day = prevMonthStartingPoint < 10 ? `0${prevMonthStartingPoint}` : prevMonthStartingPoint;

        if (startingPoint === 0 && countCol < 7) {
            calendarRows[countRow] = [...calendarRows[countRow], {
                classes: `${weekendDays(day)} in-prev-month`,
                date: `${year}-${month}-${Number(day) - 6}`,
                value: prevMonthStartingPoint,
            }];
            prevMonthStartingPoint += 1;
        } else if (countCol < startingPoint) {
            calendarRows[countRow] = [...calendarRows[countRow], {
                classes: `${weekendDays(day)} in-prev-month`,
                date: `${year}-${month}-${day}`,
                value: prevMonthStartingPoint,
            }];
            prevMonthStartingPoint += 1;
        } else {
            currentMonthRow(countRow, countCol);
        }
    };

    const nextMonthRow = (countRow: number) => {
        const year = selectedDate.month() === 11 ? selectedDate.year() + 1 : selectedDate.year();
        const month = selectedDate.month() === 11 ? 0 : selectedDate.month() + 1;
        const day = nextMonthCounter < 10 ? `0${nextMonthCounter}` : nextMonthCounter;
        const date = `${year}-${month}-${day}`;

        calendarRows[countRow] = [...calendarRows[countRow], {
            classes: `${weekendDays(day)} in-next-month`,
            date,
            value: day,
        }];
        nextMonthCounter += 1;
    };

    for (let countRow = 1; countRow < rows + 1; countRow += 1) {
        for (let countCol = 1; countCol < cols + 1; countCol += 1) {
            if (!calendarRows[countRow]) {
                calendarRows[countRow] = [];
            }
            if (countRow === 1) {
                prevMonthRow(countRow, countCol);
            } else if (countRow > 1 && currentMonthDayCounter < daysInMonth + 1) {
                currentMonthRow(countRow, countCol);
            } else {
                nextMonthRow(countRow);
            }
        }
    }

    const getPrevMonth = useCallback(() => setSelectedDate(prevValue => dayjs(`${prevValue.subtract(1, 'month')}`, FORMAT_MONTH_DATE)), []);

    const getNextMonth = useCallback(() => setSelectedDate(prevValue => dayjs(`${prevValue.add(1, 'month')}`, FORMAT_MONTH_DATE)), []);

    return {
        daysShort,
        formattedToday,
        calendarRows,
        selectedDate,
        selectedDay,
        setSelectedDay,
        getPrevMonth,
        getNextMonth,
    };
};
