import React from 'react';
import styled from 'styled-components';
import { Rows } from '../../../hooks/useDatepicker';
import { DatePickerColumn, DisabledDates } from '../DatePickerColumn';
import { units } from '../../../helpers/styles/units';

export interface DatePickerRow {
  cols: Rows[];
  selectedDay: string;
  onClick: (value: string) => void;
  formattedToday: string;
  disabledDates?: DisabledDates;
  disableUntilСurrentDate?: boolean;
  onSuccess?: (value: string) => void;
}

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  
  margin-bottom: ${units(2)};
`;

export const DatePickerRow = ({
    cols,
    selectedDay,
    onClick,
    formattedToday,
    disabledDates,
    disableUntilСurrentDate,
    onSuccess,
}: DatePickerRow) => (
    <StyledRow key={cols[0].date}>
        {cols.map(col => (
            <DatePickerColumn
                col={col}
                key={col.value}
                selectedDay={selectedDay}
                formattedToday={formattedToday}
                onClick={onClick}
                disabledDates={disabledDates}
                disableUntilСurrentDate={disableUntilСurrentDate}
                onSuccess={onSuccess}
            />
        ))}
    </StyledRow>
);
