import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TranslationsNamespaces } from '../constants/TranslationsNamespaces';
import { namespaces } from './namespaces';
import { resources } from './resources';
import { Languages } from '../constants/Languages';

export const defaultNS = TranslationsNamespaces.LOGIN;
const isDevelopment = process.env.NODE_ENV === 'development';

i18n
    .use(initReactI18next)
    .init({
        lng: Languages.RU,
        ns: namespaces,
        defaultNS,
        resources,
        supportedLngs: [Languages.RU],
        fallbackLng: Languages.RU,
        debug: isDevelopment,
        load: 'languageOnly',
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
