import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as shopApi from '../../../api/shop';
import { ShopContractParams } from '../../../types/Endpoints/ShopContract';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addEmptyArrayShopList } from '../setEmtyArray';

export const GET_SHOP = 'shop/getShop';

const onFail = (): Thunk => async dispatch => {
    dispatch(addEmptyArrayShopList());
};

export const getShop = createAsyncThunk(
    GET_SHOP,
    createRequest({
        type: GET_SHOP,
        loader: (params: ShopContractParams) => shopApi.getShop(params),
        onFail,
        showFailNotification: false,
    }),
);
