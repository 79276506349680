import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import * as React from 'react';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';

interface IModelProps {
    setOpenModel: (state: boolean) => void;
}

const RootStyle = styled.div`
`;

const StyledBackgroundBlock = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const StyledCenteredMainBlock = styled.div`
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 1040;
  transform: translate(-50%, -50%);
`;

const StyledWrapperModal = styled.div`
  width: 1138px;
  overflow-y: scroll;
  min-height: 600px;
  height: 660px;
  background: ${colors.white_10};
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  &::-webkit-scrollbar {
    width: 16px;               /* ширина всей полосы прокрутки */
  }

  &::-webkit-scrollbar-track {
    opacity: 0;        /* цвет зоны отслеживания */
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray2};
    border: 6px solid ${colors.white_10};
    border-radius: 10px;     /* округлось бегунка */
  }
`;

const StyledModalTitle = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  margin-top: 26px;
`;

const StyledModalBody = styled.div`
  padding: 10px;
  margin: 0 40px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
`;

const StyledModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 ${units(15)} 0 ${units(27)};
`;

const StyledIcon = styled.div`
  margin-top: ${units(14)};
  margin-right: ${units(10)};
  cursor: pointer;
  display: flex;
  align-items: center;
  
  &:hover svg {
    fill: ${colors.blue} !important;
  }
`;

export const Modal = ({ children, setOpenModel } : PropsWithChildren<IModelProps>) => (
    <RootStyle>
        <StyledBackgroundBlock onClick={() => setOpenModel(false)} />
        <StyledCenteredMainBlock>
            <StyledWrapperModal>
                <StyledModalHeader>
                    <StyledModalTitle>
                        Добавление товаров
                    </StyledModalTitle>
                    <StyledIcon onClick={() => setOpenModel(false)}>
                        <Icon
                            type={TypesIcon.CLOSE}
                            width="24px"
                            height="24px"
                            fill={colors.gray}
                        />
                    </StyledIcon>
                </StyledModalHeader>
                <StyledModalBody>
                    {children}
                </StyledModalBody>
            </StyledWrapperModal>
        </StyledCenteredMainBlock>
    </RootStyle>
);
