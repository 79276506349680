import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as ordersApi from '../../../api/orders';
import { OrderCardContractParams } from '../../../types/Endpoints/OrderCardContract';

export const GET_USER_ORDERS = 'orders/GET_USER_ORDERS';

export const getUserOrders = createAsyncThunk(
    GET_USER_ORDERS,
    createRequest({
        type: GET_USER_ORDERS,
        loader: (params: OrderCardContractParams) => ordersApi.getUserOrders(params),
        showFailNotification: false,
    }),
);
