import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Form } from '../../../../components/Form';
import { FieldsCustomizedMenus } from './FieldsCustomizedMenus';
import { getFieldsSelectAvailability } from '../../../../helpers/getSelectFields/getFieldsSelectAvailability';
import { getFieldsSelectPrice } from '../../../../helpers/getSelectFields/getFieldsSelectPrice';
import { Icon } from '../../../../components/Icon';
import { TypesIcon } from '../../../../types/TypesIcon';
import { DrawerModalFilters } from '../DrawerFilters';
import { units } from '../../../../helpers/styles/units';
import { FontWeight } from '../../../../constants/Styles/fontWeight';
import { colors } from '../../../../constants/Colors';
import { typography } from '../../../../helpers/styles/typography';
import { SearchInput } from '../../../../components/SearchInput';
import { comparingArrays } from '../../../../helpers/arrayСomparison';

interface Formik {
    selectPrice: string[];
    selectAvailability: string[];
}

type FormValues = Partial<Formik>;

interface ICustomizedMenus{
    searchOrder: string;
    setSearchOrder: (state: string) => void;
    formMenu: FormikProps<FormValues>;
    isModal?:boolean
}

interface IStyledButton{
    isSelectedFilter?: boolean
}

const StyledOrder = styled.div`
   display: flex;
`;

const StyledHeaderModel = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  
    .select_form{
      width: max-content;
    }
`;

const WrapperButtons = styled.div`
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  
  .MuiDrawer-paper{
    border-radius:  0 ${units(8)} ${units(8)} 0;
  }
`;

const StyledButton = styled.button<IStyledButton>`
  display: flex;
  align-items: center;
  padding: ${units(3)} ${units(6)};
  margin: ${units(4)} 0;
  gap: ${units(2)};
  max-height: ${units(16)};

  font-weight: ${FontWeight.MEDIUM};
  color: ${({ isSelectedFilter }) => (!isSelectedFilter ? colors.blue : colors.dark)};

  svg {
    fill:  ${({ isSelectedFilter }) => (!isSelectedFilter ? colors.blue : colors.dark)} !important;
  }


  background: ${colors.white};
  border-radius: ${units(6)};
  border: ${units(0.5)} solid ${colors.gray3};

  cursor: pointer;

  ${typography(5)}
  :hover {
    color: ${colors.blue};
    background: ${colors.whiteBlue};
  }

  &:hover svg {
    fill: ${colors.blue} !important;
  }
`;

const WrapperInput = styled.div`
  & > .MuiPaper-root {
    box-shadow: none;
    border-radius: ${units(6)};
    max-width: 20.3125rem;
    max-height: 2.75rem;
    font: revert;
  }

  & .MuiInputBase-input {
    color: ${colors.gray};
    font-size: 14px;
    line-height: 20px;
  }

  & .MuiInputBase-root {
    margin-left: 0;
  }
`;

const StyledResetButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: ${colors.gray};
  white-space: nowrap;
  margin-right: ${units(12)};
  margin-top: ${units(-6)};
  cursor: pointer;
  
  &:hover{
    color: ${colors.dark};
  }
`;

export const CustomizedMenus = ({
    searchOrder, setSearchOrder, formMenu, isModal,
}: ICustomizedMenus) => {
    const { t: TFilters } = useTranslation('filters');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isSelectedFilter, setIsSelectedFilter] = useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickResetButton = () => {
        formMenu.resetForm();
    };

    const isResetButton = comparingArrays(formMenu.initialValues.selectPrice, formMenu.values.selectPrice)
        && comparingArrays(formMenu.initialValues.selectAvailability, formMenu.values.selectAvailability);

    return (
        <StyledHeaderModel>
            <WrapperInput>
                <SearchInput
                    placeholder="Поиск товаров"
                    searchOrder={searchOrder}
                    setSearchOrder={setSearchOrder}
                    allSymbols
                />
            </WrapperInput>
            <StyledOrder>
                {!isResetButton
                    ? (
                        <StyledResetButton onClick={() => onClickResetButton()}>
                            Сбросить все
                        </StyledResetButton>
                    ) : null}
                <Form<FormValues>
                    fields={FieldsCustomizedMenus(getFieldsSelectPrice())}
                    form={formMenu}
                    className="select_form"
                    isRow
                />
                <WrapperButtons>
                    <StyledButton
                        isSelectedFilter={isSelectedFilter}
                        onClick={handleClick}
                    >
                        <Icon
                            type={TypesIcon.TUNE}
                            width="20px"
                            height="20px"
                        />
                        {TFilters('sentences.filter')}
                    </StyledButton>
                    <DrawerModalFilters
                        setIsSelectedFilter={setIsSelectedFilter}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        isModal={isModal}
                    />
                </WrapperButtons>
            </StyledOrder>
        </StyledHeaderModel>
    );
};
