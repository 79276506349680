import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { matchPath } from 'react-router';
import axios from 'axios';
import { Thunk } from '../redux/Thunk';
import { ServerRequestError } from './errors';
import { selectLoadersByName } from '../../selectors/loaders';
import { LoaderState } from '../../types/Loaders/LoaderState';
import { addLoader, updateLoader } from '../../actions/loaders';
import { StatusCode } from '../../types/Errors/StatusCode';
import { routes } from '../../constants/RouterPath';
import { selectLocation } from '../../selectors/router';
import { setBreakingPath } from '../../actions/systems';
import { getSessionFromStorage, removeSessionFromStorage, setSessionToStorage } from '../auth/session';
import { getApiPath } from './request';
// import { addNotification } from '../../actions/notifications';
// import { TypesSnackbars } from '../../types/TypesSnackbars';

interface CreateRequest<Args, Returned> {
  loader: AsyncThunkPayloadCreator<Returned, Args>;
  type: string;
  onSuccess?: (result: any) => Thunk | null | void;
  onFail?: (err?: ServerRequestError) => Thunk;
  showFailNotification?: boolean;
  shouldUpdateLoader?: boolean;
}

// eslint-disable-next-line import/no-mutable-exports
export let createRequest = <Args, Returned>({
    loader,
    onSuccess,
    onFail,
    type,
    showFailNotification = true,
    shouldUpdateLoader = true,
}: CreateRequest<Args, Returned>) => async (arg: Args, thunkAPI: any) => {
        try {
            const { state: loaderState } = selectLoadersByName(type)(thunkAPI.getState()) || {};
            const isChangeLoaderState = loaderState === LoaderState.FULFILLED && shouldUpdateLoader;

            if (!loaderState || isChangeLoaderState) {
                thunkAPI.dispatch(addLoader(type));
            }

            const result = await loader(arg, thunkAPI);

            if (onSuccess) {
                thunkAPI.dispatch(onSuccess(result));
            }

            return Promise.resolve(thunkAPI.fulfillWithValue(result)).then(thunkAPI.dispatch(updateLoader(type, LoaderState.FULFILLED)));
        } catch (err) {
            const { pathname } = selectLocation(thunkAPI.getState());

            const typedError = <ServerRequestError>err;
            const isAuthorizeError = typedError.statusCode === StatusCode.UNAUTHORIZED;
            const isLoginPage = matchPath(pathname, routes.login);
            const { refresh_token: refresh } = getSessionFromStorage() || {};

            if (isAuthorizeError) {
                try {
                    const result = await loader(arg, thunkAPI);
                    thunkAPI.dispatch(updateLoader(type, LoaderState.FULFILLED));
                    return thunkAPI.fulfillWithValue(result);
                } catch (e) {
                    // thunkAPI.dispatch(logout(true));

                    if (pathname !== routes.login && pathname !== routes.login) {
                        thunkAPI.dispatch(setBreakingPath(pathname));
                    }

                    if (onFail) {
                        thunkAPI.dispatch(onFail(typedError));
                    }

                    if (pathname !== routes.login && pathname !== routes.commonLogin) {
                        if (!refresh) {
                            window.location.href = '/';
                        } else {
                            axios.post(`${getApiPath()}/auth/refresh-token`, {
                                refresh_token: refresh,
                            })
                                .then(response => {
                                    setSessionToStorage(response.data);
                                    document.location.reload();
                                })
                                .catch(error => {
                                    console.log(error);
                                    removeSessionFromStorage();
                                    window.location.href = '/';
                                });
                        }
                    }

                    return thunkAPI.rejectWithValue(e);
                }
            }

            if (onFail) {
                thunkAPI.dispatch(onFail(err as ServerRequestError));
            }

            // на странице логина не показываем дефолтные нотификации
            // if (showFailNotification && !isLoginPage) {
            //     thunkAPI.dispatch(addNotification({
            //         type: TypesSnackbars.ERROR,
            //         message: {
            //             title: 'Ой! Что-то пошло не так...',
            //             description: `${typedError.statusCode} ${typedError.message}`,
            //         },
            //     }));
            // }

            return Promise.reject(thunkAPI.rejectWithValue(err)).then(thunkAPI.dispatch(updateLoader(type, LoaderState.REJECT)));
        }
    };
