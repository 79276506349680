import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as React from 'react';
import { units } from '../../../helpers/styles/units';
import { InformationWrapperBlock } from '../../../components/InformationWrapperBlock';
import { InformationBlockDescription } from '../../../components/InformationBlockDescription';
import { getInformationFields } from './getInformationFields';
import { getProductIntroductionFields } from './getProductIntroductionFields';
import { TypesButton } from '../../../constants/ButtonTypes';
import { Button } from '../../../components/Button';
import { ProductModel } from '../../../types/Models/ProductTableModel';
import { config } from '../../../config';
import PlaceholderForImage from '../../../assets/placeholderForImage.png';

interface IProductDescription {
    handleClick: (event: React.ChangeEvent <HTMLButtonElement>) => void
    product?: ProductModel
}

const StyledWrapperDescriptionBlock = styled.div`
  display: flex;
  margin-top: ${units(17)};
  
  & > div:first-of-type{
      .field_name{
        min-width: ${units(68)};
      }
  }
`;

const StyledWrapperProductIntroduction = styled.div`
    background: #FFFFFF;
    width: ${units(170)};
    margin-left: ${units(22.5)};
    border-radius: ${units(8)};
    height: max-content;
    padding-bottom: ${units(15)};
    padding-right: ${units(10)};
    padding-left: ${units(10)};
`;

const StyledImageProduct = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${units(16)};
  width: 100%;
  
  img{
    height: 100%;
    max-height: ${units(109)};
  }
`;

const InformationBlockWrapper = styled.div`
  margin-top: ${units(16)};
  margin-left: ${units(12)};
  & > div > div:first-of-type{
    .value_name{
      white-space: nowrap;
    }
  }
`;

const StyledButton = styled(Button)`
  width: ${units(145)};
  margin-top: ${units(14)};
`;

const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 ${units(12)}
`;

export const ProductDescription = ({ handleClick, product } : IProductDescription) => {
    const { t } = useTranslation('products');

    return (
        <StyledWrapperDescriptionBlock>
            <InformationWrapperBlock maxWidth={350} headerTitle="Данные о товаре">
                <InformationBlockDescription
                    isMarginFieldName
                    widthNameFields={68}
                    clientFields={getInformationFields(t, product)}
                />
            </InformationWrapperBlock>
            <StyledWrapperProductIntroduction>
                <StyledImageProduct>
                    <img
                        alt={product?.name}
                        src={product?.images_links[0] ? `${config.commonUrl}/${product.images_links[0]}` : PlaceholderForImage}
                    />
                </StyledImageProduct>
                <InformationBlockWrapper>
                    <InformationBlockDescription
                        widthNameFields={43}
                        clientFields={getProductIntroductionFields(t, product)}
                    />
                </InformationBlockWrapper>
                <WrapperButton>
                    <StyledButton
                        typeButton={TypesButton.OUTLINE_BORDER}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        onClick={event => handleClick(event)}
                    >
                        {t('buttons.availableInShops')}
                    </StyledButton>
                </WrapperButton>
            </StyledWrapperProductIntroduction>
        </StyledWrapperDescriptionBlock>
    );
};
