import styled from 'styled-components';
import * as React from 'react';
import {
    ChangeEvent,
    FormEvent,
    ReactNode,
} from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Select, SelectChangeEvent } from '@mui/material';
import { units } from '../../helpers/styles/units';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { FiltersMenuProps } from '../../types/Filters/FiltersMenuProps';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';

export interface selectProps {
    id?: string,
    name?: string,
    className?: string,
    option?: FiltersMenuProps[];
    sizeLimitation?: boolean;
    IsIssuingOrders?: boolean;
    placeholder?: string;
    onChange?: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
    onClick?: (event: FormEvent<SelectChangeEvent<string | number>>) => void;
    onBlur?: (e: string | ChangeEvent) => void;
    onOpen?: () => void;
    value?: string[] | string
    onPostfixClick?:React.MouseEventHandler<HTMLDivElement>;
    maxLength?: number;
}

interface IMenuItemsProps {
    color?: string;
    background?: string;
    isSelect?: boolean;
    sizeLimitation?: boolean;
    IsIssuingOrders?: boolean;
}

const StyledWrapper = styled.div<IMenuItemsProps>`
  margin-bottom: ${units(6)};
  .MuiInputBase-root {
    padding: ${units(3)} ${units(6)};
    margin: ${units(4)} 0;
    gap: ${units(2)};
    max-width:  ${({ sizeLimitation }) => (sizeLimitation ? '171px' : 'inherit')};
    max-height: 32px;
    border-color: ${({ IsIssuingOrders, background }) => (!IsIssuingOrders ? `${background} !important` : '#DFE4F2 !important')}; 
    color: ${({ color }) => (`${color} !important`)};
    background-color: ${({ background, IsIssuingOrders }) => (!IsIssuingOrders ? `${background} !important` : 'initial')};

    font-weight: ${FontWeight.MEDIUM};
    color: ${colors.dark};

    background: ${colors.white};
    border-radius: ${({ IsIssuingOrders }) => (!IsIssuingOrders ? units(6) : units(4))};;
    border: 0;

    cursor: pointer !important;
    
    &:hover{
      color: ${({ color }) => (color ? `${color} !important` : 'inherit !important')};
      background-color: ${({ background }) => (background ? `${background} !important` : 'inherit !important')};
    }
    
    svg{
      color: inherit;
    }

    .MuiSelect-select{
      padding-right: 19px !important;
      padding-left: 0;
      div{
        width: max-content;
        svg{
          display: none;
        }
        
        &:hover {
          color: inherit;
          background-color: inherit;
        };

        &:active {
          color: inherit;
          background-color: inherit;
        };
      }
      svg{
        display: none;
      }
    }
    
    .MuiOutlinedInput-notchedOutline {
      border-color: inherit !important;
      border-width: 1px !important;
    }

    ${typography(5)}
    :hover {
      color: ${colors.blue};
      background: ${colors.whiteBlue};
    }
    
  }
`;

const WrapperItem = styled.div <IMenuItemsProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 36px;
    border-radius: 4px;
    padding: ${units(8)} ${units(3)};
    margin-bottom: 4px;
    color: ${({ isSelect, color }) => (isSelect ? color : 'inherit')};
    background-color: ${({
        isSelect,
        background,
        IsIssuingOrders,
    }) => (isSelect && !IsIssuingOrders ? background : 'inherit')};
    svg{
      display: ${({ isSelect }) => (isSelect ? 'inherit' : 'none')};;
    }
  
    &:hover {
        color: ${({ color }) => (color)};
        background-color: ${({ background }) => (background)};
    };

    &:active {
        color: ${({ color }) => (color)};
        background-color: ${({ background }) => (background)};
    };
`;

export const SelectControl = ({
    id,
    name,
    onChange,
    value = [''],
    option = [],
    sizeLimitation = true,
    IsIssuingOrders = false,
    placeholder,
}: selectProps) => {
    const MenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        sx: {
            marginTop: '8px',
            minWidth: '167px',
            opacity: option?.length < 1 ? '0' : 'inherit',
            ul: {
                padding: '0',
            },
            li: {
                backgroundColor: 'transparent !important',
                'div:last-child': {
                    marginBottom: '0',
                },
            },
            'li:hover': {
                color: 'inherit',
                backgroundColor: 'transparent !important',
            },
            'li:active': {
                color: 'inherit',
                backgroundColor: 'transparent !important',
            },
            '.Mui-selected': {
                backgroundColor: 'transparent !important',
            },
            span: {
                display: 'none',
            },
        },
    };

    return (
        <StyledWrapper
            color={value[1]}
            background={value[2]?.length > 1 ? value[2] : 'white'}
            sizeLimitation={sizeLimitation}
            IsIssuingOrders={IsIssuingOrders}
        >
            <Select<string[] | string>
                id={id}
                name={name}
                value={value[0] || placeholder}
                displayEmpty
                fullWidth
                onChange={onChange}
                renderValue={selected => {
                    if (String(selected).length === 0) {
                        return <span>{placeholder}</span>;
                    }
                    if ((selected && Array.isArray(selected)) || value[0]) {
                        return <span>{value[0]}</span>;
                    }
                    return <span>{selected}</span>;
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                MenuProps={MenuProps}
            >
                {option
                    ? option.map((statusOfCard: FiltersMenuProps) => (
                        <MenuItem
                            value={[statusOfCard.name, statusOfCard.color, statusOfCard.background, statusOfCard.key]}
                            sx={{
                                padding: 0,
                            }}
                            key={statusOfCard.key}
                        >
                            <WrapperItem
                                isSelect={statusOfCard.name === value[0]}
                                color={statusOfCard.color}
                                background={statusOfCard.background}
                                IsIssuingOrders={IsIssuingOrders}
                            >
                                {statusOfCard.name}
                                <Icon
                                    type={TypesIcon.DONE}
                                    width="20px"
                                    fill={statusOfCard.color}
                                    height="20px"
                                />
                            </WrapperItem>
                        </MenuItem>
                    )) : null}
            </Select>
        </StyledWrapper>
    );
};
