import { request } from '../../helpers/api/request';
import { CreateUsersData } from '../../types/Endpoints/CreateUser';
import { UsersCardContractParams } from '../../types/Endpoints/UsersCardContract';

export const updateUser = async (params: UsersCardContractParams, userData: CreateUsersData) => {
    const { data } = await request({
        url: `users/${params.id}/update`,
        method: 'PATCH',
        data: userData,
    });

    return data;
};
