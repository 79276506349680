import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { PageTitle } from '../../components/PageTitle';
import { units } from '../../helpers/styles/units';
import { TableFilters } from './TableFilters';
import { OrderTable } from './OrderTable';
import { useTypeSelector } from '../../store';
import { selectOrders } from '../../selectors/orders';
import { getAdditionalOrders, getOrders } from '../../actions/orders';
import { removeSuccessOrder } from '../../actions/successOrder';
import { StatusSelectSchema } from './TableFilters/CustomizedMenusStatus/StatusSelectSchema';
import { selectDrawerOrder } from '../../selectors/drawerOrder';

const StyledPageTitle = styled(PageTitle)`
  margin-top: ${units(14)};
`;

interface FormikSelectOrders {
    statusSelect: string[];
}

type FormValuesSelectOrders = Partial<FormikSelectOrders>;

export const Orders = () => {
    const { t } = useTranslation('orders');
    const orders = useTypeSelector(selectOrders);
    const selectFilterOrder = useTypeSelector(selectDrawerOrder);
    const dispatch = useDispatch();
    const [sortByDate, setSortByDate] = useState<string>('');
    const [limitTable, setLimitTable] = useState<number>(1);
    const [searchOrder, setSearchOrder] = useState<string>('');

    const handleSubmit = (values: FormValuesSelectOrders) => {
        console.log(values);
    };

    const formSelectOrders = useFormik<FormValuesSelectOrders>({
        onSubmit: handleSubmit,
        initialValues: {
            statusSelect: ['', '', ''],
        },
        enableReinitialize: true,
        validationSchema: StatusSelectSchema(),
    });

    const selectStatusOrder = formSelectOrders?.values.statusSelect ? formSelectOrders?.values.statusSelect[3] : '';

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchOrder) {
                setLimitTable(1);
                dispatch(getOrders({
                    page: 1,
                    limit: 15,
                    phone: searchOrder.length > 4 ? searchOrder : undefined,
                    order_id: searchOrder.length < 5 ? +searchOrder : undefined,
                    date_pickup_sort: sortByDate || undefined,
                    loyalty_card_id: selectFilterOrder?.idCart ? +selectFilterOrder.idCart : undefined,
                    parameters: {
                        status: selectStatusOrder,
                        date_pickup: selectFilterOrder?.dateOfIssue || undefined,
                    },
                }));
            }
        }, 1000);

        if (!searchOrder) {
            setLimitTable(1);
            dispatch(getOrders({
                page: 1,
                limit: 15,
                date_pickup_sort: sortByDate || undefined,
                loyalty_card_id: selectFilterOrder?.idCart ? +selectFilterOrder.idCart : undefined,
                parameters: {
                    status: selectStatusOrder,
                    date_pickup: selectFilterOrder?.dateOfIssue || undefined,
                },
            }));
        }

        return () => clearTimeout(timer);
    }, [searchOrder, selectStatusOrder, selectFilterOrder, sortByDate]);

    const onClickAddMoreOrders = () => {
        setLimitTable(prevState => prevState + 1);
        if (searchOrder) {
            dispatch(getAdditionalOrders({
                page: limitTable + 1,
                limit: 15,
                phone: searchOrder.length > 4 ? searchOrder : undefined,
                order_id: searchOrder.length < 5 ? +searchOrder : undefined,
                date_pickup_sort: sortByDate || undefined,
                loyalty_card_id: selectFilterOrder?.idCart ? +selectFilterOrder.idCart : undefined,
                parameters: {
                    status: selectStatusOrder,
                    date_pickup: selectFilterOrder?.dateOfIssue || undefined,
                },
            }));
        }
        if (!searchOrder) {
            dispatch(getAdditionalOrders({
                page: limitTable + 1,
                limit: 15,
                date_pickup_sort: sortByDate || undefined,
                loyalty_card_id: selectFilterOrder?.idCart ? +selectFilterOrder.idCart : undefined,
                parameters: {
                    status: selectStatusOrder,
                    date_pickup: selectFilterOrder?.dateOfIssue || undefined,
                },
            }));
        }
    };

    useEffect(() => {
        dispatch(removeSuccessOrder());
    }, []);

    return (
        <>
            <StyledPageTitle>
                {t('sentences.title')}
            </StyledPageTitle>
            <TableFilters
                formSelectOrders={formSelectOrders}
                searchOrder={searchOrder}
                setSearchOrder={setSearchOrder}
            />
            <OrderTable
                sortByDate={sortByDate}
                setSortByDate={setSortByDate}
                setLimitTable={setLimitTable}
                limitTable={limitTable}
                onClickAddMoreOrders={onClickAddMoreOrders}
            />
        </>
    );
};
