import React from 'react';
import styled from 'styled-components';
import { units } from '../../../../helpers/styles/units';
import { Divider } from '../../../Divider';
import { DividerControlProps } from '../../../../types/Form/DividerControlProps';

const StyledDivider = styled(Divider)`
  margin-bottom: ${units(8)};
`;

export const DividerControl = ({
    label,
    isVertical,
    color,
}: DividerControlProps) => (
    <StyledDivider
        color={color}
        label={label}
        isVertical={isVertical}
    />
);
