import { css } from 'styled-components';
import { units } from '../../../../helpers/styles/units';

export const applyRowStyle = ({ isRow }: { isRow: boolean, titleLeft?: boolean}) => {
    if (isRow) {
        return css`
          display: flex;
          flex-wrap: wrap;
          flex-basis: 50%;
        
          :first-child {
            margin-right: ${units(8)};
          }
        `;
    }

    return '';
};
