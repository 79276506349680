import { createReducer } from '@reduxjs/toolkit';
import { getNewOrders } from '../../actions/listNewOrders';

interface IListNewOrders {
    listNewOrders?: string[] | undefined;
}

export const listNewOrders = createReducer<IListNewOrders>({}, builder => {
    builder.addCase(getNewOrders.fulfilled, (state, { payload }) => ({
        ...state,
        listNewOrders: payload,
    }));
});
