import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PageTitle } from '../../components/PageTitle';
import { units } from '../../helpers/styles/units';
import { InformationWrapperBlock } from '../../components/InformationWrapperBlock';
import { SearchInputWithMask } from '../../components/SearchInputWithMask';
import { colors } from '../../constants/Colors';
import { TypesButton } from '../../constants/ButtonTypes';
import { Button } from '../../components/Button';
import { getUserByPhone, removeUserByPhone } from '../../actions/users';
import { useTypeSelector } from '../../store';
import { selectUsersByPhone } from '../../selectors/usersByPhone';
import { addStoringPhoneNumber } from '../../actions/storingPhoneNumber';

const StyledPageTitle = styled(PageTitle)`
  margin-top: ${units(22)};
`;

const WrapperInput = styled.div`
  & > .MuiPaper-root {
    box-shadow: none;
    border-radius: ${units(6)};
    max-width: 325px;
    max-height: 2.75rem;
    font: revert;
    border: 1px solid rgba(223, 228, 242, 1);

  }

  & .MuiInputBase-input {
    color: #26314FFF;
    font-size: 14px;
    line-height: 20px;
  }

  & .MuiInputBase-root {
    margin-left: 0;
  }
`;

const StyledWrapperNewOrder = styled.div`
  display: flex;
  margin-top: ${units(16)};
`;

const StyledInformationInformationFromSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
`;

const StyledWrapperInputSearch = styled.div`
  display: flex;
  position: relative;
`;

const StyledButtonDrawer = styled(Button)`
  display: flex;
  flex-flow: nowrap; 
  justify-content: end;
  align-items: center;
  max-height: 36px;
`;

const WrapperContentInputBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const WrapperWindowSelectionValues = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    top: 50px;
    width: 325px;
    background: #FFFFFF;
    border: 1px solid #DFE4F2;
    box-shadow: 0px 0px 40px #F6F5FA;
    border-radius: 8px;
    overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;               /* ширина всей полосы прокрутки */
  }

  &::-webkit-scrollbar-track {
    opacity: 0;        /* цвет зоны отслеживания */
  }

  &::-webkit-scrollbar-thumb {
    margin-top: 10px;
    background: ${colors.gray2};
    border-radius: 10px;     /* округлось бегунка */
  }

    & > div {
      max-height: 240px;
      margin: 0 auto;
      
      & > div:first-of-type {
        margin-top: 12px;
      }
      & > div:last-child {
        margin-bottom: 12px;
      }
    }
`;

const WrapperContentPhoneClient = styled.div`
  display: flex;
  justify-content: space-between;
  width: 299px;
  height: 40px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  color: #828DAB;
  
  &:hover{
    background: #F2F5FF;
    color: #2A62FF;
  }
  &:active{
    background: #F2F5FF;
    color: #2A62FF;
  }
`;

const StyledPhone = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-left: 12px;
`;

const StyledName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-right: 12px;
`;

export const NewOrder = () => {
    const { t } = useTranslation('orders');
    const history = useHistory();
    const [searchPhone, setSearchPhone] = useState<string>('');
    const dispatch = useDispatch();
    const users = useTypeSelector(selectUsersByPhone) || [];

    useEffect(() => {
        dispatch(removeUserByPhone());
    }, []);

    useEffect(() => {
        const validPhoneNumber = `${searchPhone.replace(/[^0-9]/g, '')}`;
        const timer = setTimeout(() => {
            if (searchPhone && validPhoneNumber.length > 6) {
                dispatch(getUserByPhone({
                    parameters: { phone: validPhoneNumber },
                }));
            }
        }, 200);

        return () => {
            dispatch(removeUserByPhone());
            clearTimeout(timer);
        };
    }, [searchPhone]);

    const onClickSearchClient = () => {
        if (users?.length > 0) {
            history.push(`/client-card/${users[0].id}`);
        } else {
            dispatch(addStoringPhoneNumber({
                phone: `${searchPhone.replace(/[^0-9]/g, '')}`,
            }));
            history.push('/new-client');
        }
    };

    return (
        <>
            <StyledPageTitle>
                Новый заказ
            </StyledPageTitle>
            <StyledWrapperNewOrder>
                <InformationWrapperBlock
                    color={colors.green}
                    headerTitle="Поиск клиента"
                >
                    <WrapperContentInputBlock>
                        <StyledWrapperInputSearch>
                            <WrapperInput>
                                {/* eslint-disable-next-line react/button-has-type */}
                                <SearchInputWithMask
                                    placeholder="Введите телефон"
                                    searchOrder={searchPhone}
                                    setSearchOrder={setSearchPhone}
                                />
                            </WrapperInput>
                            { users?.length > 0
                                ? (
                                    <WrapperWindowSelectionValues>
                                        <div>
                                            {users.map(user => (
                                                <WrapperContentPhoneClient
                                                    onClick={() => setSearchPhone(user.phone)}
                                                    key={user.id}
                                                >
                                                    <StyledPhone>
                                                        {user.phone.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3 $4 $5') }
                                                    </StyledPhone>
                                                    <StyledName>
                                                        {user.name}
                                                    </StyledName>
                                                </WrapperContentPhoneClient>
                                            )) }
                                        </div>
                                    </WrapperWindowSelectionValues>
                                ) : null}
                            <StyledInformationInformationFromSearch>
                                {/* eslint-disable-next-line no-nested-ternary */}
                                { searchPhone?.length === 0 ? '' : (users?.length > 0 ? 'Клиент найден' : 'Клиент не найден')}
                            </StyledInformationInformationFromSearch>
                        </StyledWrapperInputSearch>
                        <StyledButtonDrawer
                            typeButton={TypesButton.FILL}
                            onClick={() => onClickSearchClient()}
                        >
                            {users?.length > 0 ? 'Открыть карточку' : 'Добавить клиента'}
                        </StyledButtonDrawer>
                    </WrapperContentInputBlock>
                </InformationWrapperBlock>
            </StyledWrapperNewOrder>
        </>
    );
};
