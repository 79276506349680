import dayjs from 'dayjs';
import arraySupport from 'dayjs/plugin/arraySupport';
import utc from 'dayjs/plugin/utc';
import { Month } from '../../constants/Month';

dayjs.extend(arraySupport);
dayjs.extend(utc);

const year = dayjs().year();
const month = dayjs().month();
const day = dayjs().date();

const getNextMonth = () => {
    if (month === Month.DECEMBER) {
        return new Date(year + 1, Month.JANUARY, 1);
    }

    return new Date(year, month + 1, 30);
};

const getNextDay = () => new Date(year, month, day + 1);

const getDateSinceStartMonth = () => dayjs([year, month]).local().format('YYYY-MM-DD');

const getCurrentDate = () => dayjs([year, month, day]).local().format('YYYY-MM-DD');

export {
    getNextMonth,
    getNextDay,
    getDateSinceStartMonth,
    getCurrentDate,
};
