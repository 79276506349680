import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { getFilterMenu } from './getFilterMenu';
import { Form } from '../../../../components/Form';
import { getFieldsStatusSelect } from './getFieldsStatusSelect';

interface Formik {
    statusSelect: string[];
}

type FormValues = Partial<Formik>;

const StyledOrder = styled.div`
   display: flex;
`;

export const CustomizedMenus = ({ formSelectOrders } :{ formSelectOrders: FormikProps<FormValues>}) => {
    const { t } = useTranslation('filters');

    return (
        <div>
            <StyledOrder>
                <Form<FormValues>
                    fields={getFieldsStatusSelect(getFilterMenu(t))}
                    form={formSelectOrders}
                    className="select_form"
                />
            </StyledOrder>
        </div>
    );
};
