import styled from 'styled-components';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';

export const HtmlTooltip = styled(({ className, children, ...props }: PropsWithChildren<TooltipProps>) => (
    <Tooltip
        placement="right"
        arrow
        classes={{ popper: className }}
        {...props}
    >
        {children}
    </Tooltip>
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: colors.white,

        ':before': {
            border: `1px solid ${colors.gray} !important`,
        },
    },
    [`& .${tooltipClasses.tooltip}`]: {
        padding: units(4),
        backgroundColor: colors.white,
        borderRadius: units(2),
        border: `1px solid ${colors.gray}`,
        minWidth: units(120),
    },
    [`& .${tooltipClasses.tooltipPlacementRight}`]: {
        marginLeft: `${units(7)} !important`,
        transition: 'none !important',
    },
}));
