import styled from 'styled-components';
import { colors } from '../../constants/Colors';

interface IStyledAvailableCircle{
    available?: boolean;
}

const RootStyle = styled.span<IStyledAvailableCircle>`
  width: 5px;
  height: 5px;
  display: inline-block;
  background: red;
  background: ${({ available }) => (available ? colors.dark : colors.red)};
  border-radius: 100%;
  bottom: -1px;
  margin-bottom: 2px;
  margin-right: 8px;
`;

export const AvailableCircle = ({ available } : IStyledAvailableCircle) => (
    <RootStyle available={available} />
);
