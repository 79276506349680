import styled from 'styled-components';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { ClientInformationProps } from '../../types/Client/ClientInformationProps';
import { Icon } from '../Icon';

interface IClientDescription{
    clientFields: ClientInformationProps[],
    widthNameFields: number,
    isMarginFieldName?: boolean
}

interface IInformationAboutClient{
    widthNameFields: number
    isMarginFieldName: boolean
}

const RootStyle = styled.div`
`;

const InformationAboutClient = styled.div<IInformationAboutClient>`
  display: flex;
  margin-bottom: ${units(8)} ;
  
  .field_name{
    display: flex;
    align-items: center;
    width:  ${({ widthNameFields }) => units(widthNameFields)};
    margin-right:  ${({ isMarginFieldName }) => (isMarginFieldName ? units(12) : 0)};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.gray};
  }
  
  .value_name{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: ${units(7)};
    background: ${colors.whiteBlue};
    border-radius: ${units(2)};
    padding: ${units(3)} ${units(4)};
    max-width: ${units(243.5)};
  }
`;

const ButtonClientDescription = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${units(14)};
    height: ${units(14)};
    background: ${colors.white};
    border: 1px solid ${colors.gray3};
    border-radius: ${units(2)};;
    cursor: pointer;
    margin-left: ${units(6)};
    margin-top: ${units(0.75)};
    
    svg{
      fill: ${colors.gray10};
    }

    :hover{
      svg{
        fill: ${colors.gray20};
      }
    }
`;

const StyledImageBeforeText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${units(3.5)};
`;

export const InformationBlockDescription = ({
    clientFields,
    widthNameFields, isMarginFieldName = false,
}: IClientDescription) => {
    const history = useHistory();
    return (
        <RootStyle>
            {clientFields.map((clientField:ClientInformationProps) => {
                if (clientField.value) {
                    return (
                        <InformationAboutClient
                            isMarginFieldName={isMarginFieldName}
                            widthNameFields={widthNameFields}
                            key={clientField.key}
                        >
                            <div className="field_name">
                                {clientField.title}
                            </div>
                            <div className="value_name">
                                {clientField.imageBeforeText
                                    ? (
                                        <StyledImageBeforeText>
                                            <Icon
                                                type={clientField.imageBeforeText}
                                                width="20px"
                                                height="20px"
                                                fill={colors.gray}
                                            />
                                        </StyledImageBeforeText>
                                    ) : null}
                                {clientField.value}
                            </div>
                            {clientField.imageButton
                                ? (
                                    <ButtonClientDescription onClick={() => history.push(`/client-card/${clientField.userId}`)}>
                                        <Icon
                                            type={clientField.imageButton}
                                            width="20px"
                                            height="20px"
                                        />
                                    </ButtonClientDescription>
                                ) : null}
                        </InformationAboutClient>
                    );
                }

                return null;
            })}
        </RootStyle>
    );
};
