import { TFunction } from 'i18next';
import { HeaderTableProps } from '../../../../types/Table/HeaderTableProps';
import { TypesIcon } from '../../../../types/TypesIcon';

export const getModalTableHead = (t: TFunction) : HeaderTableProps[] => ([
    {
        key: 'id',
        name: t('sentences.productId'),
        width: 50,
    },
    {
        key: 'goods',
        name: t('sentences.goods'),
        width: 192,
        icon: TypesIcon.UNFOLD_MORE,
    },
    {
        key: 'volume',
        name: t('sentences.volume'),
        width: 66,
    },
    {
        key: 'price',
        name: t('sentences.price'),
        width: 68,
    },
    {
        key: 'availability',
        name: t('sentences.availability'),
        width: 78,
    },
    {
        key: 'productQuantity',
        name: t('sentences.productQuantity'),
        width: 88,
    },
    {
        key: 'shops',
        name: t('sentences.shops'),
        width: 60,
    },
]);
