import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';

const colorByType: Record<string, string> = {
    [TypesButton.FILL]: colors.white,
    [TypesButton.OUTLINE]: colors.blue,
    [TypesButton.OUTLINE_BORDER]: colors.blue,
    [TypesButton.OUTLINE_BORDER_GREY]: colors.gray3,
};

export const applyColor = ({ typeButton }: { typeButton: TypesButton }) => `color: ${colorByType[typeButton]}`;
