import { ControlProps } from '../../../types/Form/ControlProps';
import { TypesFields } from '../../../types/TypesFields';
import { FiltersMenuProps } from '../../../types/Filters/FiltersMenuProps';

export const getFieldsOrderSelect = (option: FiltersMenuProps[]): ControlProps[] => ([
    {
        key: 'selectOrder',
        type: TypesFields.SELECT_MI,
        name: 'selectOrder',
        className: 'selectOrder',
        option,
        placeholder: 'Статус',

    },
]);
