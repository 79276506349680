import React, { PropsWithChildren } from 'react';
import { FormikProps } from 'formik';
import styled from 'styled-components';
import { Fields } from './Fields';
import { ControlProps } from '../../types/Form/ControlProps';

export interface FormProps<V> {
  form: FormikProps<V>;
  fields: ControlProps[];
  className?: string;
  isRow?: boolean;
  contentHeight?: boolean;
}

const StyledForm = styled.form<Pick<FormProps<Record<string, unknown>>, 'isRow' | 'contentHeight'>>`
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
  align-items: flex-start;
  width: 100%;
  height: ${({ contentHeight }) => (contentHeight ? 'initial' : '100%')};
`;

export const Form = <V extends Record<string, unknown> = Record<string, unknown>, >({
    form,
    fields,
    className,
    isRow = false,
    contentHeight = false,
    // eslint-disable-next-line
}: PropsWithChildren<FormProps<V>>) => {
    return (
        <StyledForm
            onSubmit={form.handleSubmit}
            className={className}
            isRow={isRow}
            contentHeight={contentHeight}
        >
            <Fields<V>
                fields={fields}
                form={form}
                isRow={isRow}
            />
        </StyledForm>
    );
};
