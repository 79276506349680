import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as orderApi from '../../../api/orders';
import { CreateOrder } from '../../../types/Endpoints/CreateOrder';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNewSuccessOrder } from '../../successOrder';
import { getCurrentUser } from '../../сurrentUser';

export const CREATE_ORDER = 'user/createOrder';

const useOnSuccess = (result: any): Thunk => async dispatch => {
    if (result.status) {
        await dispatch(addNotification({
            type: TypesSnackbars.SUCCESS,
            message: {
                id: result?.id,
                title: 'Новый заказ',
                description: 'Поступил новый заказ',
            },
        }));
        await dispatch(addNewSuccessOrder({
            id: result?.id,
            active: result?.status,
        }));
        await dispatch(getCurrentUser());
    }
    if (!result.status) {
        await dispatch(addNotification({
            type: TypesSnackbars.SUCCESS,
            message: {
                title: 'Заказ',
                description: 'Некоторых товаров нет в наличии',
            },
        }));
    }
};

const onFail = (): Thunk => async dispatch => {
    await dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            isMistake: true,
            description: 'Проверьте введенные данные',
        },
    }));
};

export const createOrders = createAsyncThunk(
    CREATE_ORDER,
    createRequest({
        type: CREATE_ORDER,
        loader: (orderData: CreateOrder) => orderApi.createOrder(orderData),
        onSuccess: useOnSuccess,
        onFail,
        showFailNotification: false,
    }),
);
