export enum TypesFields {
  INPUT = 'INPUT',
  PASSWORD = 'PASSWORD',
  TEXTAREA = 'TEXTAREA',
  DATEPICKER = 'DATEPICKER',
  DIVIDER = 'DIVIDER',
  SELECT_MI = 'SELECT_MI',
  RANGE = 'RANGE',
  CHECK_GROUP = 'CHECK_GROUP'
}
