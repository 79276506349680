import { createReducer } from '@reduxjs/toolkit';
import { getProductCard } from '../../actions/products/thunks/getProductsCard';
import { ProductModel } from '../../types/Models/ProductTableModel';

interface ProductCard {
    productCard?: ProductModel | undefined;
}

export const productCard = createReducer<ProductCard>({}, builder => {
    builder.addCase(getProductCard.fulfilled, (state, { payload }) => ({
        ...state,
        productCard: payload,
    }));
});
